import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'
import BatchSampleFile from '../../../download_files/batch_sample_file.docx'
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import DatePicker from "react-datepicker";
import CustomClearIndicator from './MultiSelectTests';
import BatchInput from '../Course/BatchInput';
import Select from 'react-select';
import { getCourses } from '../CommonHelper';
import './Batch.css'

const BatchModal = forwardRef((props, ref) => {
    const [modalBox, setModalBox] = useState(false);
    //new course
    const [batchname, setNewBatchName] = useState('');
    const [batchdetails, setNewbatchDetails] = useState('');
    const [sortId, setNewBatchSortID] = useState(0);
    const [modalName, setModal] = useState("")
    const [id, setId] = useState();
    const [isBatchExisted, setIsBatchExisted] = useState(false);

    //batch-testseries mapping 
    const [courses, setCourses] = useState();


    let initialBatchData = {
        sNo: 0,
        courseId: '',
        startDate: new Date(),
        adminCloseDate: new Date(),
        price: 0,
        batchName: '',
        status: '',
        courseTitle: '',
        hide: 0,
        isCurrentBatch: 'NO'
    }
    const [batchData, setBatchData] = useState(initialBatchData);

    useImperativeHandle(ref, () => ({
        openModal(item = {}) {
            console.log(item)
            if (item.sn > 0) {
                setBatchData({ sNo: item.sn, courseId: item.courseId, courseTitle: item.courseTitle, isCurrentBatch: item.isCurrentBatch ? 'YES' : 'NO', startDate: new Date(item.startDate?.toString().replace(/\s+/g, "")), adminCloseDate: new Date(item.adminCloseDate?.toString().replace(/\s+/g, "")), price: item.price, batchName: item.batchName, status: item.status })
            }
            setModalBox(true);
            getCourses().then((res) => {
                console.log(res)
                setCourses(res)
            })
        }
    }));
    const handleSaveBatch = async () => {
        if (!batchData.courseId || !batchData.batchName || !batchData.status || !batchData.batchName) {
            toast.error("Please Fill all Required Fields")
            return;
        }

        try {
            const formattedBatchData = {
                ...batchData,
                startDate: batchData.startDate.toISOString().split('T')[0], // Format startDate as YYYY-MM-DD
                adminCloseDate: batchData.adminCloseDate.toISOString().split('T')[0] // Format endDate as YYYY-MM-DD
            };
            const res = await axios.post(BACKEND_SERVER_URL + (batchData.sNo ? `/editCourseBatch/${batchData.sNo}` : '/saveCourseBatch'), formattedBatchData);
            if (res?.data.statusCode == 200) {
                toast.success(res.data.message);
                props.setBatchLoading(!props.loadBatches);
                setModalBox(false);
            }
        } catch (error) {
            console.error("An error occurred during the request:", error.message);
            toast.error(error.response.data.message);

        }
    }
    useEffect(() => {
        if (!modalBox) {
            setBatchData(initialBatchData); // Reset form data when modal is closed
        }
    }, [modalBox]);
    const toggleCurrentBatchHandler = (checked) => {
        console.log(checked)
        if (checked == 'NO') setBatchData({ ...batchData, isCurrentBatch: 'YES' })
        else setBatchData({ ...batchData, isCurrentBatch: 'NO' })
    }
    return (
        <>
            <Modal onHide={setModalBox} show={modalBox} dialogClassName="custom-modal-width" size="lg" backdrop="static"
                keyboard={false} >
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel"> {batchData.sNo ? "Update Batch" : "Add New Batch"}</h5>
                        <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
                    </div>
                    <div className="modal-body">
                        <div className="row ">
                            <div className="col-12  ">
                                <div className="col-md-12  d-sm-flex justify-content-around">
                                    <div className="col-md-3 mb-3" >
                                        <label htmlFor="selectCourse" className="form-label text-primary ">Course<span className="required">*</span></label>
                                        <Select
                                            isDisabled={batchData.sNo}
                                            value={batchData.courseTitle ? { value: batchData.courseTitle, label: batchData.courseTitle } : null}
                                            onChange={(selectedOption) => setBatchData({ ...batchData, courseId: selectedOption?.value.courseId, courseTitle: selectedOption.value.courseTitle })}
                                            options={courses?.map((course) => ({ value: course, label: course.courseTitle }))}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    width: '100%',
                                                    backgroundColor: 'white',
                                                    borderColor: '#17a2b8',
                                                }),
                                            }}
                                        />
                                    </div>
                                    <div className=" col-md-3 mb-3">
                                        <label htmlFor="exampleFormControlInput2" className="form-label text-primary ">Batch Name<span className="required">*</span></label>
                                        <input type="text" className="form-control " id="exampleFormControlInput2" placeholder="Batch name" value={batchData.batchName} onChange={(e) => setBatchData({ ...batchData, batchName: e.target.value })} />
                                    </div>
                                    <div className="mb-3 col-md-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label text-primary">Price<span className="required">*</span></label>
                                        <input type='number' className="form-control" id="exampleFormControlInput1" placeholder="Enter Price" value={batchData.price} onChange={(e) => setBatchData({ ...batchData, price: e.target.value })} />
                                    </div>
                                </div>
                                <div className="col-md-12 d-sm-flex justify-content-around ">
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label text-primary">Status<span className="required">*</span></label>
                                        <br />
                                        <Select
                                            value={batchData.status ? { value: batchData.status, label: batchData.status } : null}
                                            onChange={(selectedOption) => setBatchData({ ...batchData, status: selectedOption?.value })}
                                            options={['ACTIVE', 'Disactive'].map((status) => ({ value: status, label: status }))}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    minWidth: '13rem',
                                                    backgroundColor: 'white',
                                                    borderColor: '#17a2b8',
                                                }),
                                            }}
                                        />
                                    </div>
                                    <div className="mb-3 col-md-3" >
                                        <label htmlFor="exampleFormControlInput3" className="form-label text-primary">Start Date<span className="required">*</span></label>
                                        <br />

                                        <DatePicker
                                            className="form-control full-width full-width-container full-width-date"
                                            selected={batchData.startDate}
                                            onChange={(date) => setBatchData({ ...batchData, startDate: date })}
                                            dateFormat="dd/MM/yyyy"

                                        />

                                    </div>
                                    <div className="mb-3 col-md-3">
                                        <label className="form-label text-primary">Admns Close Date<span className="required">*</span></label>
                                        <br />
                                        <DatePicker
                                            className="form-control full-width full-width-container .react-datepicker-wrapper"
                                            selected={batchData.adminCloseDate}
                                            onChange={(date) => setBatchData({ ...batchData, adminCloseDate: date })}
                                            dateFormat="dd/MM/yyyy"
                                        />
                                    </div>

                                </div>
                                <div className="col-md-12 d-flex justify-content-around">
                                    <div className="col-md-3 mb-3 d-md-flex justify-content-start ">
                                        <label className="form-label text-primary">Current Batch<span className="required me-1">*</span></label>
                                        <div className=" form-check form-switch    ">
                                            <input className="form-check-input cursor-pointer text-center " type="checkbox" role="switch" id="flexSwitchCheckDefault" style={{ width: "35px", height: "16px", backgroundColor: `${batchData.isCurrentBatch == 'YES' ? "#E0F5F0" : "#FFE8E8"}`, border: `1px solid ${batchData.isCurrentBatch == 'YES' ? 'green' : "red"}` }} checked={batchData.isCurrentBatch == 'YES' ? true : false} onChange={() => toggleCurrentBatchHandler(batchData.isCurrentBatch)} />
                                        </div>

                                    </div>
                                    <div className=" col-md-3 mb-3  ">

                                    </div>
                                    <div className=" col-md-3 mb-3  ">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger btn-xs" onClick={() => setModalBox(false)}>Close</button>
                        <button type="button" className="btn btn-primary btn-xs " onClick={handleSaveBatch}>{batchData.sNo ? "Update Batch" : "Create Batch"}</button>
                    </div>
                </div>
            </Modal>

        </>
    )
})
export default BatchModal;