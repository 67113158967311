import axios from "axios";
import { BACKEND_SERVER_URL } from "../../../http/http-config";

export async function fetchTestData(CourseID) {
    return (await axios.get(BACKEND_SERVER_URL + '/getTest/' + CourseID)).data.test
}
export async function fetchSubTestData(sn) {
    return (await axios.get(BACKEND_SERVER_URL + '/getSubTest/' + sn)).data.test
}

export async function formateTestData(data) {
    return ({
        sn: data.Sn,
        sNo: data.OrderNo,
        examType: data.ExaminationBody,
        examTypeId: data.ExaminationBodyId,
        title: data.Title,
        titleHeading: data.TitleHeading,
        price: data.Price,
        seatBookingPrice: data.SeatBookingPrice,
        discount: data.Discount,
        appDiscount: data.AppDiscount,
        type: data.Type,
        typeId: data.TypeId,
        quizCount: data.QuizCount,
        duration: data.Duration,
        faculty: data.Faculty,
        facultyId: data.FacultyId,
        subscriptionCount: data.SubscriberCount,
        currentBatchID: data.BatchId,
        description: data.Description,
        imageURL: data.Image,
        pdfURL: data.PDFUrl,
        syllabusPDFURL: data.SyllabusPDFUrl,
        topics: data.Topic,
        tags: data.Tags,
        demoVideoLink: data.MetaTitle,
        metaTitle: data.RootType,
        metaDescription: data.MetaDescription,
        courseId: data.CourseID,
        startDate: data.PublishDate,
        admissionStartDate: data.AdmissionStartDate,
        mcqRules: data.Rules,       
        subjectiveRules: data.RulesSubjective
    })
}
export async function formateSubTestData(data) {
    return {
        sn: data.Sn,
        title: data.Title || '',
        accessCode: data.AccessCode || '',
        description: data.Description || '',
        topic: data.Topic || '',
        questionCount: data.QuestionCount || 0,
        attempts: data.Attempt || 0,
        totalMarks: data.MarksTotal || 0,
        positiveMarks: data.MarksObtain || 0,
        negativeMarks: data.MarkingMinus || 0,
        duration: data.Duration || 0,
        publishDate: data.PublishDate || new Date(),
        endDate: data.EndDate || new Date(),
        availability: data.Availability || '',
        type: data.Type || 'Select Question Type',
        demo: data.Demo || 'NO',
        holdResult: data.HoldResult || 'NO',
        tags: data.Tag || '',
        notesPDF: data.NotesPDF || '',
        questionPDF: data.QuestionPDF || '',
        answerBookPDF: data.AnswerBookletPDF || '',
        modelAnswerPDF: data.ModelAnswerPDF || '',
        videoURL: data.VideoUrl || '',
        packageId: data.PackageID || '',
        courseId: data.CourseID || '',
        sNo: data.OrderNo || '',
        batchId: data.TBatchId || '',
        initialRank: data.RankStart || 0
    };
}


export function validateTestData(formData) {
    const validationRules = {
        examType: "Please Select Exam Type",
        examTypeId: "Please Select Exam Type",
        sNo: "Serial Number is Required",
        title: "Title is Required",
        titleHeading: "Title Heading is Required",
        price: "Price is Required",
        seatBookingPrice: "Seat Booking Price is Required",
        discount: "Discount is Required",
        appDiscount: "App Discount is Required",
        type: "Please Select Type",
        typeId: "Please Select Type",
        //topicCount: "Topic Count is Required",
        duration: "Duration is Required",
        faculty: " Please Select Faculty",
        facultyId: "Please Select Faculty",
        subscriptionCount: "Subscription Count is Required",
        currentBatchID: "Current Batch ID is Required",
        description: "Description is Required",
        imageURL: "Image URL is Required",
        pdfURL: "PDF URL is Required",
        syllabusPDFURL: "Syllabus PDF URL is Required",
        topics: "Topics are Required",
        tags: "Tags are Required",
        demoVideoLink: "Demo Video Link is Required",
        metaTitle: "Meta Title is Required",
        metaDescription: "Meta Description is Required",
        courseId: "Test ID is Required",
        startDate: "Start Date is Required",
        admissionStartDate: "Admission Start Date is Required",
        mcqRules: "MCQ Rules are Required",
        quizCount: "Quiz Count is Required",
        subjectiveRules: "Subjective Rules are Required"
    };

    for (const field in validationRules) {
        if (!formData[field]) {
            return validationRules[field];
        }
    }

    return null; // Return null if all validations pass
}


export function validateSubTestData(formData) {
    const requiredFields = ['title', 'description', 'topic', 'questionCount', 'attempts', 'totalMarks', 'positiveMarks', 'negativeMarks', 'duration', 'publishDate', 'endDate', 'availability', 'type', 'demo', 'holdResult', 'tags', 'courseId'];

    for (const field of requiredFields) {
        if (!formData[field]) {
            return `${field.substring(0, 1).toUpperCase() + field.substring(1)} is Required`;
        }
    }

    return null; // Return null if all validations pass
}
