import React from 'react';
import Select from 'react-select';
import { setDropDownStyle } from '../CommonHelper';

const DropdownInput = ({ selectedValue, handleChangeCallBack, labelName, dropdownValuesArray, inputName }) => {
  //  console.log(selectedValue)
    const handleChange = (selectedOption) => {
      //  console.log(selectedOption.value)
        handleChangeCallBack(inputName, selectedOption.value)
    }
    return (
        <>
            <div className="col-sm-4 pt-2">
                <div className="mb-3" >
                    <label htmlFor={labelName} className="form-label text-primary">{labelName}{/* <span className="required">*</span> */}</label>
                </div>
            </div>
            <div className="col-sm-8">
                <div className="mb-3 " >
                    <Select                      
                        value={{ value: selectedValue, label: selectedValue }}
                        onChange={(selectedOption) => handleChange(selectedOption)}
                        options={dropdownValuesArray?.map((item) => ({ value: item, label: item }))}
                        styles={setDropDownStyle()}
                    />
                </div>

            </div>
        </>
    );
}

export default DropdownInput;






