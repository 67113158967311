import React, { useContext, useEffect } from "react";
import { Routes, Route, Outlet, useNavigate, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import LogoutPage from './layouts/nav/Logout';
/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Nav2 from "./layouts/nav/index2";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";
// import Main from './layouts/Main';
import WalletBar from './layouts/WalletBar';
/// Dashboard
import Home from "./components/Dashboard/Home";
import Finance from "./components/Dashboard/Finance";
import DashboardDark from "./components/Dashboard/DashboardDark";

//student
import Students from "./components/Student/Students";
/* import StudentDetails from "./components/Student/StudentDetails"; */
import AddNewStudent from "./components/Student/AddNewStudent";

//Teacher
import Teachers from './components/Teacher/Teachers';
import TeachersDetail from './components/Teacher/TeachersDetail';
import AddNewTeacher from './components/Teacher/AddNewTeacher';
//Food
import Food from './components/Food/Food';
import FoodDetails from './components/Food/FoodDetails';

/// File Manager
import FileManager from './components/FileManager/FileManager';
import User from './components/FileManager/User';
import HomeCalendar from './components/FileManager/HomeCalendar';
import Activity from './components/FileManager/Activity';
import FileChat from './components/FileManager/FileChat';



/// App
import AppProfile from "./components/AppsMenu/AppProfile/AppProfile";
import PostDetails from "./components/AppsMenu/AppProfile/PostDetails";
import EditProfile from "./components/AppsMenu/AppProfile/EditProfile";
import Compose from "./components/AppsMenu/Email/Compose/Compose";
import Inbox from "./components/AppsMenu/Email/Inbox/Inbox";
import Read from "./components/AppsMenu/Email/Read/Read";
import Calendar from "./components/AppsMenu/Calendar/Calendar";

/// Product List
import ProductGrid from "./components/AppsMenu/Shop/ProductGrid/ProductGrid";
import ProductList from "./components/AppsMenu/Shop/ProductList/ProductList";
import ProductDetail from "./components/AppsMenu/Shop/ProductGrid/ProductDetail";
import ProductOrder from "./components/AppsMenu/Shop/ProductOrder";
import Checkout from "./components/AppsMenu/Shop/Checkout/Checkout";
import Invoice from "./components/AppsMenu/Shop/Invoice/Invoice";
import Customers from "./components/AppsMenu/Shop/Customers/Customers";

/// Charts
import SparklineChart from "./components/charts/Sparkline";
import ChartJs from "./components/charts/Chartjs";
import RechartJs from "./components/charts/rechart";
import ApexChart from "./components/charts/apexcharts";

/// Bootstrap
import UiAlert from "./components/bootstrap/Alert";
import UiAccordion from "./components/bootstrap/Accordion";
import UiBadge from "./components/bootstrap/Badge";
import UiButton from "./components/bootstrap/Button";
import UiModal from "./components/bootstrap/Modal";
import UiButtonGroup from "./components/bootstrap/ButtonGroup";
import UiListGroup from "./components/bootstrap/ListGroup";
import UiCards from "./components/bootstrap/Cards";
import UiCarousel from "./components/bootstrap/Carousel";
import UiDropDown from "./components/bootstrap/DropDown";
import UiPopOver from "./components/bootstrap/PopOver";
import UiProgressBar from "./components/bootstrap/ProgressBar";
import UiTab from "./components/bootstrap/Tab";
import UiPagination from "./components/bootstrap/Pagination";
import UiGrid from "./components/bootstrap/Grid";
import UiTypography from "./components/bootstrap/Typography";

/// Plugins
import Select2 from "./components/PluginsMenu/Select2/Select2";
// import MainNouiSlider from "./components/PluginsMenu/NouiSlider/MainNouiSlider";
import MainSweetAlert from "./components/PluginsMenu/SweetAlert/SweetAlert";
import Toastr from "./components/PluginsMenu/Toastr/Toastr";
// import JqvMap from "./components/PluginsMenu/JqvMap/JqvMap";
import Lightgallery from "./components/PluginsMenu/Lightgallery/Lightgallery";


/// Widget
import Widget from "./pages/Widget";

/// Table
import SortingTable from "./components/table/SortingTable/SortingTable";
import FilteringTable from "./components/table/FilteringTable/FilteringTable";
import BootstrapTable from "./components/table/BootstrapTable";

/// Form
import Element from "./components/Forms/Element/Element";
import Wizard from "./components/Forms/Wizard/Wizard";
// import CkEditor from "./components/Forms/CkEditor/CkEditor";
import Pickers from "./components/Forms/Pickers/Pickers";
import FormValidation from "./components/Forms/FormValidation/FormValidation";

/// Pages

import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import { ThemeContext } from "../context/ThemeContext";
import Course from "./components/Course/Course";
import BatchManagement from "./components/Batch-Management/BatchManagement";
import TestManagement from "./components/TestManagement/TestManagement";
import AddTest from "./components/TestManagement/AddNewTest";
import EditTest from "./components/TestManagement/EditTest";
import QuestionBank from "./components/QuestionBank/QuestionBank";
import Comprehension from "./components/Comprehension/Comprehension";
// import AddComprehension from "./components/Comprehension/AddComprehension";
import StudentsDetails from "./components/StudentDetails/StudenManagement";
import Notifications from "./components/Notifications/Notifications";
import MyAccount from "./components/MyAccount/MyAccount";
import Logout from "./components/Logout/Logout";
import AddStudent from "./components/StudentDetails/AddStudent";
import StudentManagement from "./components/StudentDetails/StudenManagement";
import EditMyProfile from "./components/MyAccount/EditMyProfile";
import { useRole } from "../context/UserRoleContext";
import StudentAccount from "./components/StudentComponents/StudentAccount/StudentAccount";
import EditStudentProfile from "./components/StudentComponents/StudentAccount/EditStudentProfile";
import StudentDashboard from "./components/StudentComponents/StudentDashboard/StudentDashbooard";
import { toast } from "react-toastify";
import Enrollment from "./components/StudentComponents/Enrollment/Enrollment";
import TestSeries from "./components/StudentComponents/TestSeries/TestSeries";
import Reports from "./components/StudentComponents/Report/Reports";
import StudentNotifications from "./components/StudentComponents/StudentNotifications/StudentNotifications";
import Login from "./pages/Login";
import StartTest from "./components/StudentComponents/StartTest/StartTest";
import TestResults from "./components/TestManagement/TestResults";
import TestReport from "./components/StudentComponents/Report/TestReport";
import CourseGrid from "./components/Course/CourseGrid";
import Subscribers from "./components/StudentDetails/Subscribers";
import Blogs from "./components/Blogs/Blogs";
import CurrentAffairs from "./components/CurrentAffairs/CurrentAffairs";
import CourseDetails from "./components/Course/CourseDetails";
import AddClasses from "./components/Classes/Classes";
import AcademicYear from "./components/Operational-Setting/AcademicYear";
import ExamBody from "./components/Operational-Setting/ExamBody";
import CourseType from "./components/Operational-Setting/CourseType";
import NewsCategory from "./components/Operational-Setting/NewsCategory";
import BlogCategory from "./components/Operational-Setting/BlogCategory";
import NewsTags from "./components/Operational-Setting/NewsTags";
import Faculty from "./components/Operational-Setting/Faculty";
import Subject from "./components/Course/Subject";
import TopicDetails from "./components/Course/TopicDetails/TopicDetails";
import OrderManagement from "./components/Order/OrderManagement"
import AdminDashboard from "./components/AdminDashboard/AdminDashboard";
import TestLists from "./components/TestManagement/SubTestSeries/TestLists";
import StaffDetails from "./components/StaffDetails/StaffDetails";
import QuizSubjects from "./components/QuizDetails/QuizSubjects";
import QuizSections from "./components/QuizDetails/QuizSections";
import QuizQuestionBank from "./components/QuizDetails/QuizQuestionBank";
import TopicQuestionBank from "./components/Course/TopicDetails/TopicQuestionBank";
import TeacherSupport from "./components/teacherSupport/TeacherSupport";
import WebsiteQuery from "./components/websitequery/WebsiteQuery";
import ModerateSolution from "./components/TestManagement/SubTestSeries/ModerateSolution";
import TestSeriesSubscribers from "./components/StudentDetails/TestSeriesSubscribers";
import DummyPage from "./components/DummyPage";
import TopicReports from "./components/Course/TopicDetails/TopicReports";
import PendingMains from "./components/TestManagement/SubTestSeries/PendingMains";
import CopyQuestions from "./components/CopyQuestions/CopyQuestions";
import TestReports from "./components/TestManagement/TestReports";
import Testimonials from "./components/Operational-Setting/Testimonials";
import Books from "./components/Books/Books";
//import Finance from "./components/Dashboard/Finance";
const Markup = () => {
  const [userRole, setUserRole] = useRole();
  const tokenDetailsString = localStorage.getItem('userDetails');
  const userRoleInLocalStorage = JSON.parse(tokenDetailsString).userRole
  // console.log(userRole, userRoleInLocalStorage)
  const navigate = useNavigate()
  /*   useEffect(() => {
      if (userRoleInLocalStorage == 2) {
        navigate('/student/dashboard')
      } else if (userRoleInLocalStorage == 1) {
        navigate('/dashboard')
      }
    }, []) */
  const routhPath = [
    { url: "dashboard", component: <AdminDashboard /> },
    //operational setting routes
    { url: "academic-year", component: < AcademicYear /> },
    { url: "exam-body", component: < ExamBody /> },
    { url: "module-type", component: < CourseType /> },
    { url: "news-category", component: < NewsCategory /> },
    { url: "blog-category", component: < BlogCategory /> },
    { url: "news-tags", component: < NewsTags /> },
    { url: "faculty", component: < Faculty /> },
    { url: "dumy", component: < DummyPage /> },
    { url: "testimonials", component: < Testimonials /> },

    { url: "course-management", component: <CourseGrid /> },
    { url: "subject-details", component: <Subject /> },
    { url: "lesson-details/", component: <TopicDetails /> },
    { url: "lesson-reports/", component: <TopicReports /> },
    // { url: "classes", component: <AddClasses /> },
    { url: "batch-management", component: <BatchManagement /> },
    { url: "test-series", component: <TestManagement /> },
    { url: "books", component: <Books /> },
    { url: "test-reports", component: <TestReports  /> },
    { url: "pending-mains", component: <PendingMains /> },
    { url: "copy-questions", component: <CopyQuestions /> },
    { url: "moderate-solution", component: <ModerateSolution /> },
    { url: "test-lists", component: <TestLists /> },
    { url: "add-test", component: <AddTest /> },
    { url: "edit-test/:id", component: <AddTest /> },
    { url: "test-results", component: <TestResults /> },
    { url: "orders/:orderType", component: <OrderManagement /> },
    // { url: "testseries-orders", component: <OrderManagement /> },
    { url: "teacher-support", component: <TeacherSupport /> },
    { url: "website-query", component: <WebsiteQuery /> },

    //quiz routes
    { url: "quiz-subjects", component: <QuizSubjects /> },
    { url: "quiz-sections", component: <QuizSections /> },



    { url: "comprehensions", component: <Comprehension /> },
    // { url: "add-comprehension", component: <AddComprehension /> },
    // { url: "edit-comprehension/:id", component: <AddComprehension /> },
    { url: "student-management", component: <StudentManagement /> },
    { url: "subscribers", component: <Subscribers /> },
    { url: "testseries-subscribers", component: <TestSeriesSubscribers /> },
    { url: "current-affairs", component: <CurrentAffairs /> },
    { url: "blogs", component: <Blogs /> },
    { url: "add-student", component: < AddStudent /> },
    { url: "edit-student/:id", component: < AddStudent /> },
    { url: "notifications", component: <Notifications /> },
    { url: "my-account", component: <MyAccount /> },
    { url: "staff-details", component: < StaffDetails /> },
    { url: "edit-profile", component: < EditMyProfile /> },
    { url: "logout", component: < LogoutPage logout="logot" /> },
  ]
  const routhPath2 = [
    //student pages
    { url: 'student/dashboard', component: <StudentDashboard /> },
    { url: 'student/enrollment', component: <Enrollment /> },
    { url: 'student/test-series', component: <TestSeries /> },
    { url: 'student/reports', component: <Reports /> },
    { url: 'student/test-report', component: <TestReport /> },
    { url: 'student/notifications', component: <StudentNotifications /> },
    { url: 'student/my-account', component: <StudentAccount /> },
    { url: 'student/edit-profile', component: <EditStudentProfile /> },
    { url: "student/logout", component: < LogoutPage logout="logot" /> },
  ];
  return (
    <>
      <Routes>
        <Route path='/page-error-400' element={<Error400 />} />
        <Route path='/page-error-403' element={<Error403 userRole={userRole} userRoleInLocalStorage={userRoleInLocalStorage} />} />
        <Route path='/page-error-404' element={<Error404 />} />
        <Route path='/page-error-500' element={<Error500 />} />
        <Route path='/page-error-503' element={<Error503 />} />
        <Route path='/page-lock-screen' element={<LockScreen />} />
        {/*  <Route element={<Layout1 />}>
          <Route path='/' exact element={<Home />} />
          <Route path='/dashboard' exact element={<Home />} />
          <Route path='/dashboard-dark' exact element={<DashboardDark />} />
        </Route> */}
        <Route element={<Layout2 />}>
          {routhPath.map((data, i) => (
            <Route key={i} exact path={`/${data.url}`} element={data.component} />
          ))}
        </Route>
        <Route element={<Layout6 userRole={userRole} userRoleInLocalStorage={userRoleInLocalStorage} />}>
          {routhPath2.map((data, i) => (
            <Route key={i} exact path={`/${data.url}`} element={data.component} />
          ))}
        </Route>
        <Route element={<Layout5 userRole={userRole} userRoleInLocalStorage={userRoleInLocalStorage} />}>
          <Route path='/file-manager' exact element={<FileManager />} />
          <Route path='/chat' exact element={<FileChat />} />
          <Route path='/email-compose' exact element={<Compose />} />
          <Route path='/email-inbox' exact element={<Inbox />} />
          <Route path='/email-read' exact element={<Read />} />
          {/* <Route path='/question-bank' exact element={<QuestionBank />} /> */}
          <Route path='/question-bank' exact element={<QuestionBank />} />
          <Route path='/quiz-questions' exact element={<QuizQuestionBank />} />
          <Route path='/topic-question-bank' exact element={<TopicQuestionBank />} />
        </Route>
        <Route element={<Layout7 userRole={userRole} userRoleInLocalStorage={userRoleInLocalStorage} />}>
          <Route path='/student/start-test' exact element={<StartTest />} />,
        </Route>
        <Route path='/login' element={<Login />} />
        <Route path='/' element={<Navigate to={userRole == 1 && userRoleInLocalStorage == 1 ? '/dashboard' : '/student/dashboard'} />} />
        <Route path='*' element={<Error404 userRole={userRole} userRoleInLocalStorage={userRoleInLocalStorage} />} />
      </Routes>
      <ScrollToTop />
    </>
  );
};

function Layout1() {
  const { sidebariconHover } = useContext(ThemeContext);
  const sideMenu = useSelector(state => state.sideMenu);
  let windowsize = window.innerWidth;
  // console.log(windowsize, 'size')
  return (

    <div id="main-wrapper" className={` show  ${sidebariconHover ? "iconhover-toggle" : ""} ${sideMenu ? "menu-toggle" : ""}`}>
      <div className={`wallet-open  ${windowsize > 1199 ? 'active' : ''}`}>
        <Nav2 />
        <div className="content-body" style={{ minHeight: window.screen.height + 20 }}>
          <div className="container-fluid">
            <Outlet />
          </div>
        </div>
        <Footer changeFooter="footer-outer" />
        <WalletBar />
      </div>
    </div>

  )
}

function Layout2(props) {
  const navigate = useNavigate();
  const sideMenu = useSelector(state => state.sideMenu);
  const { sidebariconHover } = useContext(ThemeContext);
  return (
    <div id="main-wrapper" className={`show ${sidebariconHover ? "iconhover-toggle" : ""} ${sideMenu ? "menu-toggle" : ""}`}>
      <Nav />
      <div className="content-body" style={{ minHeight: window.screen.height + 20 }}>
        <div className="container-fluid">
          <Outlet />
        </div>
      </div>
      <Footer changeFooter="out-footer style-2" />
    </div>

  )
}
function Layout6(props) {
  const navigate = useNavigate();
  const sideMenu = useSelector(state => state.sideMenu);
  const { sidebariconHover } = useContext(ThemeContext);
  return (
    <div id="main-wrapper" className={`show ${sidebariconHover ? "iconhover-toggle" : ""} ${sideMenu ? "menu-toggle" : ""}`}>
      <Nav />
      <div className="content-body" style={{ minHeight: window.screen.height + 20 }} >
        <div className="container-fluid">
          {(props.userRoleInLocalStorage == props.userRole) && (props.userRoleInLocalStorage == 2) ? <Outlet /> : navigate('/page-error-403')}
        </div>
      </div>
      <Footer changeFooter="out-footer style-2" />  {/* original-style: style-1 */}
    </div>
  )
}



function Layout5(props) {
  const navigate = useNavigate();
  const sideMenu = useSelector(state => state.sideMenu);
  const { sidebariconHover } = useContext(ThemeContext);
  return (
    <div id="main-wrapper" className={`show ${sidebariconHover ? "iconhover-toggle" : ""} ${sideMenu ? "menu-toggle" : ""}`}>
      <Nav />
      <div className="content-body message-body mh-auto">
        <div className="container-fluid mh-auto p-0">
          {(props.userRoleInLocalStorage == props.userRole) && (props.userRoleInLocalStorage == 1) ? <Outlet /> : navigate('/page-error-403')}
        </div>
      </div>
    </div>
  )
}
function Layout7(props) {
  const navigate = useNavigate();
  const sideMenu = useSelector(state => state.sideMenu);
  const { sidebariconHover } = useContext(ThemeContext);
  return (
    <div id="main-wrapper" className={`show ${sidebariconHover ? "iconhover-toggle" : ""} ${sideMenu ? "menu-toggle" : ""}`}>
      <Nav />
      <div className="content-body message-body mh-auto">
        <div className="container-fluid mh-auto p-0">
          {(props.userRoleInLocalStorage == props.userRole) && (props.userRoleInLocalStorage == 2) ? <Outlet /> : navigate('/page-error-403')}
        </div>
      </div>
    </div>
  )
}


export default Markup;