import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import { checkExistingItem } from '../common-helpers/commonServices';
import CustomClearIndicator from './MultiSelectExamBodies';
import Select from 'react-select';
import { setDropDownStyle } from '../CommonHelper';



const CourseTypeModal = forwardRef((props, ref) => {
    const [modalBox, setModalBox] = useState(false);
    const [action, setAction] = useState('');
    let initialData = {
        sn: 0,
        sort: 0,
        courseType: '',
        moduleType: 'course'
    }
    const [courseTypeData, setCourseTypeData] = useState(initialData);

    //check for existing items       
    const [itemAlreadyExist, setItemAlreadyExists] = useState(false);
    const [currentTitle, setCurrentTitle] = useState('');//for updating 
    useEffect(() => {
        if (courseTypeData.courseType && currentTitle.toLowerCase().trim() != courseTypeData.courseType.toLowerCase().trim()) { // current title is for updating case
            console.log('enter')
            let timer = setTimeout(() => {
                checkExistingItem('in_coursetype', 'ctype', courseTypeData.courseType).then(response => { //tablename and columnNane value
                    setItemAlreadyExists(response)
                })

            }, 500);
            return () => clearTimeout(timer);
        }

    }, [courseTypeData.courseType]);


    //batch-testseries mapping 
    const [previouslyMappedExamIds, setPreviouslyMappedExamIds] = useState();

    const [mappedExamBodiess, setMappedExamBodies] = useState();
    const handleUpdateExamBodiesIds = async () => {
        const mappedTestIds = mappedExamBodiess.map((test) => `${test.id}`).toString().trim();
        console.log(mappedTestIds)
        await axios.post(BACKEND_SERVER_URL + '/setExamIds/' + courseTypeData.sn, mappedTestIds)
            .then((response) => {
                toast.success(response.data.message)
                props.setCourseTypesLoading(!props.loadCourseTypes);
            })
            .catch((error) => {
                toast.error(error.response.data.message)
                props.setCourseTypesLoading(!props.loadCourseTypes);
            });
        setModalBox(false);
    };
    const setExamBodiesToBeReturn = (testsToBeReturn) => {
        setMappedExamBodies(testsToBeReturn)
    }

    useImperativeHandle(ref, () => ({
        openModal(action, item = {}) {
            console.log(item)
            setAction(action)
            if (item.sn > 0) {
                setCourseTypeData({ sn: item.sn, courseType: item.courseType, moduleType: item.moduleType, sort: item.sort })
                setCurrentTitle(item.courseType)
            }
            if (action == 'exambody_mapping') {
                if (item?.examBodies != '' && item?.examBodies != undefined) {
                    setPreviouslyMappedExamIds(item?.examBodies?.split(',').map(Number));
                } else {
                    setPreviouslyMappedExamIds(null)
                }
            }
            setModalBox(true);
        }
    }));

    const handleSaveCourseType = (value) => {
        if (courseTypeData.courseType.trim().length === 0) {
            toast.error("Please Enter Course Type")
        } else if (itemAlreadyExist && currentTitle.toLowerCase().trim() != courseTypeData.courseType.toLowerCase().trim()) {
            toast.error("Item Already Exists")
            return;
        }
        axios.post(BACKEND_SERVER_URL + `${!courseTypeData.sn ? '/saveCourseType' : `/updateCourseType`}`, courseTypeData).then((response) => {
            if (response.data.statusCode === 201) {
                toast.success(response.data.message)
                props.setCourseTypesLoading(!props.loadCourseTypes);
                setCourseTypeData(initialData)
                setModalBox(false);
            }
        }).catch(error => {
            console.log(error)
            toast.error(error.response.data.message)
        })
    }
    useEffect(() => {
        if (!modalBox) {
            setCourseTypeData(initialData); // Reset form data when modal is closed
            setItemAlreadyExists(false)
            setCurrentTitle('')
        }
    }, [modalBox]);

    return (
        <>
            {action == 'addOrUpdate' &&
                <Modal onHide={setModalBox} show={modalBox} size="md" backdrop="static"
                    keyboard={false} >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"> {courseTypeData.sn ? "Update Course Type" : "Add Course Type "}</h5>
                            <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
                        </div>
                        <div className="modal-body pt-3">
                            <div className="mb-3 ">
                                <label htmlFor="inputAcademicYear" className="form-label text-primary ">Module Type<span className="required">*</span></label>
                                <Select
                                    value={{ value: courseTypeData.moduleType, label: courseTypeData.moduleType.toUpperCase() }}
                                    onChange={(selectedOption) => setCourseTypeData((preData) => ({ ...preData, moduleType: selectedOption.value }))}
                                    options={['course', 'test-series']?.map((item) => ({ value: item, label: item.toUpperCase() }))}
                                    styles={setDropDownStyle()}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="inputAcademicYear" className="form-label text-primary"> Type<span className="required">*</span></label>
                                <input className='form-control' type="text" name="" id="inputAcademicYear" value={courseTypeData.courseType} onChange={(e) => setCourseTypeData({ ...courseTypeData, courseType: e.target.value })} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="sort" className="form-label text-primary">Priority<span className="required">*</span></label>
                                <input className='form-control' type="number" name="" id="sort" value={courseTypeData.sort} onChange={(e) => setCourseTypeData({ ...courseTypeData, sort: e.target.value })} />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger btn-xs" onClick={() => setModalBox(false)}>Close</button>
                            <button type="button" className="btn btn-primary btn-xs " onClick={handleSaveCourseType}>{courseTypeData.sn ? "Update" : "Add "}</button>
                        </div>
                    </div>
                </Modal>
            }
            {
                action == 'exambody_mapping' &&
                <Modal onHide={setModalBox} show={modalBox} top backdrop='static'>
                    <Fragment>
                        <div className="row  select-wrapper"></div>
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-4">
                                        <h4 className="card-title">Select Exam Bodies</h4>
                                    </div>
                                    <CustomClearIndicator setExamBodiesToBeReturn={setExamBodiesToBeReturn} previouslyMappedExamIds={previouslyMappedExamIds}></CustomClearIndicator>
                                </div>
                            </div>
                        </div>
                    </Fragment >
                    <div className="position-absolute bottom-0 start-0 m-3">
                        <button className='btn btn-danger  btn-xs ' onClick={() => setModalBox(false)}>Cancel</button>
                    </div>
                    <div className="position-absolute bottom-0 end-0 m-3">
                        <button className='btn btn-primary  btn-xs ' onClick={handleUpdateExamBodiesIds}>Link Exam Bodies</button>
                    </div>
                </Modal>

            }
        </>
    )
})
export default CourseTypeModal;