import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Badge, Button, Spinner } from 'react-bootstrap';
import TeacherReplyModal from './TeacherReplyModal'
import { MdDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { FaDownload, FaRegFile } from "react-icons/fa";
import parse from 'html-react-parser';

import swal from 'sweetalert';
import axios from 'axios';
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import { Pagination } from '@mui/material';
import { bulkOperations, deleteItem, handleChecked, handleCheckedAll, handleSearch, showTableResponse } from '../common-helpers/commonServices';
import ProdutsDetailsTabsAtTop from '../Common-Components/ProdutsDetailsTabsAtTop';
import SearchBox from '../Common-Components/SearchBox';
import { toast } from 'react-toastify';
import { RiUpload2Fill } from "react-icons/ri";
import { handleOpenPDF } from '../CommonHelper';

const TeacherSupport = () => {
    const childRef = useRef();
    const [faculties, setFaculties] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [checked, setChecked] = useState(faculties);
    const [unchecked, setUnChecked] = useState(true);

    const [loadTeacherComments, setTeacherCommentsLoading] = useState(false);
    const [totalActiveFaculties, setTotalActiveFaculties] = useState([]);
    const [totalInactiveFaculties, setTotalInactiveFaculties] = useState([]);
    const [totalArchivedFaculties, setTotalFaculties] = useState([]);


    useEffect(() => {
        axios.get(BACKEND_SERVER_URL + '/getTeacherComments').then((res) => {
            let teacherComments = res.data.teacherComments;  //recieving batch here
            console.log(teacherComments)
            if (teacherComments) {
                setFaculties(teacherComments);
                setChecked(teacherComments);
            }
        }).catch((error) => {
            console.log(error)
            toast.error(error.response.data.message);
        })
    }, [loadTeacherComments])

    const pageSize = 50;
    const lastIndex = currentPage * pageSize;
    const firstIndex = lastIndex - pageSize;
    const records = checked?.slice(firstIndex, lastIndex);
    const npage = Math.ceil(checked.length / pageSize)
    const number = [...Array(npage + 1).keys()].slice(1)

    return (
        <>
            <div className="row">
                <div className="col-xl-12">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className=" page-title p-2 mb-2">{/*  flex-wrap  */}
                                <div className="row w-100 d-flex justify-content-between">
                                    <div className=" ms-3 col-sm-6 input-group search-area mb-md-0 mb-3">
                                        <SearchBox setChecked={setChecked} dataArray={faculties} searchingProperty={'firstName'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 wow fadeInUp" data-wow-delay="1.5s">
                            <div className="d-sm-flex text-center justify-content-between align-items-center">
                                <div className='dataTables_info text-dark'>
                                    Records: {checked.length}
                                </div>
                                {checked.length > pageSize ? <div className="dataTables_paginate paging_simple_numbers justify-content-center  mb-0 pt-2"
                                    id="example-student_wrapper">
                                    <Pagination count={number.length} variant="outlined" color='primary' size="small" page={currentPage} siblingCount={1} boundaryCount={2} onChange={(e, p) => setCurrentPage(parseInt(p))} />
                                </div> : null}
                            </div>
                            <div className="table-responsive full-data" style={{ paddingBottom: "1px", transform: 'rotateX(180deg)' }}>
                                <div id="example-student_wrapper" className="dataTables_wrapper no-footer">
                                    <table className="table-responsive-lg table display dataTablesCard student-tab 
                                    dataTable no-footer mt-0 mb-0" style={{ transform: 'rotateX(180deg)' }} id="example-student" >
                                        <thead style={{ backgroundColor: "#cccccc" }}>
                                            <tr className='text-center text-dark border border-left'>
                                                <th className=' custom-table-header  p-2'>SN</th>
                                                <th className=' text-start custom-table-header  p-2' >STUDENT</th>
                                                <th className=' text-start p-2 custom-table-header '>COURSE/TESTSERIES</th>
                                                <th className=' text-start custom-table-header  p-2' > QUESTION DETAILS</th>
                                                <th className=' text-center custom-table-header  p-2' > Files</th>
                                                {/* <th className='p-2  text-start custom-table-header '>REPLY</th> */}
                                                <th className=' text-start custom-table-header  p-2' > C-TYPE</th>
                                                {/* <th className=' custom-table-header  p-2' > POST DATE</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {records.length > 0 ? records.map((item, ind) => (
                                                <tr key={ind} className={`${ind % 2 == 1 ? 'custom row-height' : 'row-height'}`} >
                                                    <td className='text-center p-1 column-width' style={{ border: "1px solid #cccccc", width: "65px" }} ><h6 className="sn-font-weight mb-0"> {item.sn}</h6></td>
                                                    <td className='p-1' style={{ border: "1px solid #cccccc" }}>
                                                        <div className="">
                                                            <h6 className='mb-0 ' style={{ fontSize: '13px', fontWeight: 'bold' }}>  {item.firstName} {' '}{item.lastName ? item.lastName : ''}{' '}
                                                                <CiEdit cursor='pointer' title='Reply' size={18} className='ms-3'
                                                                    onClick={() => childRef.current.openModal(item)} />
                                                                <MdDeleteOutline color='red' title='Delete Comment' cursor='pointer' size={18} className='ms-2' onClick={() => { deleteItem(item.sn, 'Teacher Comment', '/deleteTeacherComment/', setTeacherCommentsLoading, loadTeacherComments) }} />

                                                            </h6>
                                                            <p className='p-0 m-0'>
                                                                <span className='text-primary' style={{ fontSize: '13px', fontWeight: '510' }}>Post Date : </span>
                                                                <span style={{ fontSize: '13px', whiteSpace: 'pre-wrap' }}>{new Date(item?.postDate)?.toLocaleDateString('en-GB')}</span>
                                                            </p>
                                                            {/* <h6>
                                                                <MdDeleteOutline color='red' title='Delete Comment' cursor='pointer' size={18} className='ms-2' onClick={() => { deleteItem(item.sn, 'Teacher Comment', '/deleteTeacherComment/', setTeacherCommentsLoading, loadTeacherComments) }} />
                                                                <Badge bg="" className='badge-primary light badge-sm ms-2 cursor-pointer pe-3 ps-3' onClick={() => childRef.current.openModal(item)} >Reply</Badge>
                                                               
                                                            </h6> */}
                                                        </div>
                                                    </td>
                                                    <td className='text-start p-1 active-col-width' style={{ border: "1px solid #cccccc", width: "80px" }}>
                                                        <h6 className='mb-0 '>{item.title}{' '} </h6>
                                                    </td>
                                                    <td className='p-1' style={{ border: "1px solid #cccccc", minWidth: '300px' }}>
                                                        <div>
                                                            {/* <h6 className='mb-0'>
                                                                <span style={{ fontSize: '13px', fontWeight: 'bold' }}>SUBJECT : </span>
                                                                <span style={{ fontSize: '13px', fontWeight: 'bold', whiteSpace: 'pre-wrap' }}>{item?.subject?.toUpperCase()}</span>
                                                            </h6> */}
                                                            <p className='p-0 m-0 text-primary '>
                                                                <span style={{ fontSize: '13px', fontWeight: 'bold' }}>MESSAGE : </span>
                                                                <span style={{ whiteSpace: 'pre-wrap' }}>{item.commentTitle ? parse(item.commentTitle) : null}</span>
                                                            </p>
                                                            <p className='p-0 m-0 text-primary d-flex'>
                                                                <span style={{ fontSize: '13px', fontWeight: 'bold', color: "green" }}>RESPONSE : </span>
                                                                <span className='ps-1' style={{ whiteSpace: 'pre-wrap' }}>{item.teacherReply ? parse(item.teacherReply) : null}</span>

                                                            </p>
                                                        </div>
                                                    </td>
                                                    <td className='p-1' style={{ border: "1px solid #cccccc" }}>
                                                        <Badge style={!item.studentFile ? { opacity: 0.6, pointerEvents: 'none' } : {}} bg="" className='badge-secondary light badge-xs ms-2 cursor-pointer' onClick={(e) => handleOpenPDF(item.studentFile)}>Student<FaDownload className='ms-2 ' size={10} /></Badge>
                                                        <Badge style={!item.teacherFile ? { opacity: 0.6, pointerEvents: 'none' } : {}} bg="" className=' badge-primary light badge-xs ms-2 cursor-pointer' onClick={(e) => handleOpenPDF(item.teacherFile)} >Teacher<FaDownload className='ms-2 ' size={10} /></Badge>
                                                    </td>
                                                    {/*
                                                    <td className='text-start p-1 ' style={{ border: "1px solid #cccccc", width: "80px" }}>
                                                        <h6 className='mb-0'>{parse(item.teacherReply || '')}{' '} </h6>
                                                    </td> */}
                                                    <td className='text-center p-1  column-width' style={{ border: "1px solid #cccccc", width: "80px" }}>
                                                        <h6 style={{ fontSize: '13px', fontWeight: 'bold' }} className='mb-0 '>{item.ctype == 'upload' ? <FaRegFile size={16} /> : 'Q'}{' '} </h6>
                                                    </td>
                                                </tr>
                                            )) :
                                                <tr>
                                                    <td colSpan="9" className="text-center">
                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                                                            {
                                                                showTableResponse(faculties, checked)
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TeacherReplyModal ref={childRef} loadTeacherComments={loadTeacherComments} setTeacherCommentsLoading={setTeacherCommentsLoading} />
        </>
    )
}

export default TeacherSupport;



