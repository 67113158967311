import React, { useEffect, useRef, useState } from 'react'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Alert, Button, Spinner } from 'react-bootstrap';

import { format } from 'date-fns';
import { GrUpload } from "react-icons/gr";
import { FaDownload } from "react-icons/fa";
import { TiTickOutline } from "react-icons/ti";


import axios from 'axios';
import { BACKEND_SERVER_URL } from '../../../../http/http-config';
import { Accordion, Pagination } from '@mui/material';
import ButtonMUI from '@mui/material/Button';

import Typography from '@mui/material/Typography';
import { SlArrowLeft } from 'react-icons/sl';
import { toast } from 'react-toastify';
import { handleOpenPDF } from '../../CommonHelper';



const ModerateSolution = () => {
  const location = useLocation();
  console.log(location)
  const packageId = location.state?.packageId;
  const courseId = location.state?.courseId;
  const gotoPendingMains = location.state?.gotoPendingMains;


  //file input
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [replyData, setReplyData] = useState({ marks: 0, filePath: '' });
  console.log(replyData)



  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [checked, setChecked] = useState(questions);
  const [loadQuestions, setQuestionLoading] = useState(false);
  const [testSeriesName, setTestSeriesName] = useState('');

  console.log(questions)



  useEffect(() => {
    axios.get(BACKEND_SERVER_URL + `/getSubjectiveQuestions/${packageId}/${courseId}`).then((res) => {
      let testSeriesTitle = res.data.data.testSeriesTitle;
      setTestSeriesName(testSeriesTitle)
      let questions = res.data.data.questions;  //recieving batch here
      setQuestions(questions)
      console.log(questions)
      if (questions) {
        setQuestions(questions);
        setChecked(questions);
      }
    })
  }, [loadQuestions])

  const pageSize = 20;
  const lastIndex = currentPage * pageSize;
  const firstIndex = lastIndex - pageSize;
  const records = checked?.slice(firstIndex, lastIndex);
  const npage = Math.ceil(checked.length / pageSize)
  const number = [...Array(npage + 1).keys()].slice(1)





  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);

  };
  useEffect(() => {
    if (selectedFile) {
      //upload image on server
      const formData = new FormData();
      formData.append('uploadedFile', selectedFile, selectedFile.name);

      axios.post(BACKEND_SERVER_URL + '/uploadFile', formData)
        .then((response) => {
          if (response.data.statusCode == 201) {
            setReplyData((preData) => ({
              ...preData, filePath: response.data.imagePath
            }))
            toast.success("File Uploaded at Server")
          }
        })
        .catch(error => {
          toast.error(error.response.data.message);
        });
    }
  }, [selectedFile])

  const handleSubmitResponse = (sn) => {
    axios.post(BACKEND_SERVER_URL + '/subjectiveQuestionReply/' + sn, replyData)
      .then((response) => {
        if (response.data.statusCode == 200) {
          setQuestionLoading(!loadQuestions)
          toast.success(response.data.message)
          setReplyData({ marks: 0, filePath: '' })
        }
      })
      .catch(error => {
        toast.error(error.response.data.message);
      });
  }
  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="row">
            <div className="col-xl-12">
              <div className=" page-title p-2 mb-2">{/*  flex-wrap  */}
                {/* <div className="col-2">
                  <button type="button" className=" btn btn-dark btn-sm "
                    onClick={() => childRef.current.openModal(courseId)}
                  >
                    + Add
                  </button>
                </div> */}
                <div className="col-12 ">
                  <h4 className='text-center'>
                    <span style={{ fontWeight: 'bold' }}>
                      Moderate Solution For {" " + testSeriesName}
                    </span>
                    <span style={{ textDecoration: 'underline', color: 'green' }}>
                      {/* {currentTestSeries?.title && currentTestSeries.title.toUpperCase()} */}
                    </span>
                  </h4>
                </div>
              </div>

            </div>
            <div className="col-xl-12 wow fadeInUp" data-wow-delay="1.5s">
              <div className="table-responsive full-data">
                <div id="example-student_wrapper" className="dataTables_wrapper no-footer">
                  <div className="d-sm-flex text-center justify-content-between align-items-center">
                    <div >
                      <ButtonMUI className="ms-2 mt-2 mb-2" variant="outlined" color="secondary" startIcon={<SlArrowLeft size={10} />} onClick={() => gotoPendingMains ? navigate('/pending-mains') : navigate('/test-lists', { state: { courseId: courseId } })} >
                        Back
                      </ButtonMUI>
                    </div>

                    <div className='dataTables_info text-dark'>
                      Records: {checked.length}
                    </div>
                    {checked.length > pageSize ? <div className="dataTables_paginate paging_simple_numbers justify-content-center  mb-0 pt-2"
                      id="example-student_wrapper">
                      <Pagination count={number.length} variant="outlined" color='primary' size="small" page={currentPage} siblingCount={1} boundaryCount={2} onChange={(e, p) => setCurrentPage(parseInt(p))} />
                    </div> : null}
                  </div>
                  <div>
                    {questions?.map(item => (
                      <div className="card h-auto mb-2">
                        <div className="card-body p-1 pb-1">
                          <Alert variant="white" className="solid alert-square mb-0">
                            <div style={{ display: 'flex', width: '100%' }} className='text-primary'>
                              <div style={{ flex: 2.5 }}>
                                <Typography style={{ color: '#303972', fontWeight: "bold" }}>{item.lastName ? `${item.firstName} ${item.lastName}` : item.firstName} <span className='text-primary ms-2' style={{ color: '#303972', fontWeight: "250" }}></span></Typography>
                              </div>
                              <div style={{ flex: 2 }}>
                                <Typography style={{ color: '#303972', fontWeight: "bold" }}>{format(new Date(item.uploadDate), "MMMM d, yyyy")}</Typography>
                              </div>
                              <div style={{ flex: 1.5 }}>
                                <Typography style={{ color: '#303972' }}>
                                  <ButtonMUI variant="outlined" size='small' style={{ color: '#103f05', borderColor: '#b94343' }} onClick={(e) => handleOpenPDF(item.answerLink)} >Answer<FaDownload className='ms-2 text-info' size={14} /></ButtonMUI>
                                </Typography>
                              </div>
                              <div style={{ flex: 1.5 }}>
                                <Typography style={{ color: '#303972' }}>
                                  <ButtonMUI className='mt-0 pt-1' variant="" size='small' style={{ color: 'white', borderColor: '#4CAF50', backgroundColor: "#10415d" }} onClick={(e) => fileInputRef.current.click()} >Upload<GrUpload className='ms-2' size={12} /></ButtonMUI>
                                </Typography>
                                <input
                                  type="file"
                                  ref={fileInputRef}
                                  style={{ display: 'none' }}
                                  accept='.pdf'
                                  onChange={handleFileChange}
                                  className='form-control' />
                              </div>
                              <div style={{ flex: 2.5 }}>
                                <Typography style={{ color: '#303972' }}>
                                  <ButtonMUI disabled={!item.evaluateCopyLink} variant="outlined" size='small' style={{ color: '#103f05', borderColor: 'green' }} onClick={(e) => handleOpenPDF(item.evaluateCopyLink)}>Evaluated<FaDownload className='ms-2 text-info' size={14} /></ButtonMUI> <TiTickOutline size={23} color={`${item.evaluateCopyLink && item.checkAnswer ? 'green' : 'red'}`} />

                                </Typography>
                              </div>
                              <div style={{ flex: 1.5 }}>
                                <Typography style={{ color: '#303972' }}>
                                  <input placeholder='Marks...' type="number" name="marks" id="" /* value={item.marks} */ style={{ width: "90%", padding: ".2rem", border: "1px solid green", borderRadius: "5%" }} onChange={(e) => setReplyData((preData) => ({ ...preData, marks: e.target.value }))} />
                                </Typography>
                              </div>
                              <div style={{ flex: .5 }}>
                                <ButtonMUI className='mt-0 pt-1' variant="" size='small' style={{ color: 'white', borderColor: 'green', backgroundColor: "green" }} onClick={(e) => handleSubmitResponse(item.sn)}>Save</ButtonMUI>
                              </div>
                            </div>
                          </Alert>
                        </div>
                      </div>))
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ModerateSolution;



