import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import { TiTick } from "react-icons/ti";
import { BiEdit } from "react-icons/bi";
import { MdDeleteOutline } from "react-icons/md";
import { CiEdit, CiLock, CiUnlock } from "react-icons/ci";
import swal from 'sweetalert';
import Pagination from '@mui/material/Pagination';
import axios from 'axios';
import { toast } from 'react-toastify';
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { IoSearchSharp } from "react-icons/io5";
import '../common-css/Table.css'
import { showTableResponse } from '../common-helpers/commonServices';
import Select from 'react-select';
import { getBatches, getCourses, getTestSeries, getTestSeriesBatches, setDropDownStyle } from '../CommonHelper';

const OrderManagement = () => {
    const { orderType } = useParams()
    console.log(orderType)
    const [orders, setOrders] = useState([])


    //dropdowns
    const [courseWiseOrders, setCourseWiseOrders] = useState([]);
    const [batchWiseOrders, setBatchWiseOrders] = useState([]);
    const [courses, setCourses] = useState([]);
    const [batches, setBatches] = useState([]);
    const [courseWiseBatches, setCourseWiseBatches] = useState([]);
    let intialValue = orderType == 'course-orders' ? "All Courses" : "All Test Series"
    const [selectedCourse, setSelectedCourse] = useState({ courseTitle: intialValue });
    const [selectedBatch, setSelectedBatch] = useState({ batchName: "All Batches" });
    const [transactionStatus, setTransactionStatus] = useState("All Transactions");
    console.log(selectedCourse)

    const [currentPage, setCurrentPage] = useState(1);
    const [checked, setChecked] = useState(orders);
    const [unchecked, setUnChecked] = useState(true);
    const [loadStudents, setStudentLoading] = useState(false);
    const [totalSuccessOrders, setTotalSuccessOrders] = useState(0);
    const [totalTimeoutOrders, setTotalTimeoutOrders] = useState(0);
    const [totalFailureOrders, setTotalFailureOrders] = useState(0);


    // search functionality    
    const [searchTerm, setSearchTerm] = useState('');
    useEffect(() => {
        const filteredStudents = orders?.filter(
            item =>
                item?.courseTitle?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
                item?.email?.toLowerCase().includes(searchTerm?.toLowerCase())
        );
        setChecked(filteredStudents)
    }, [searchTerm])
    const handleSearch = (e) => {
        setSearchTerm(e.target.value)
    }
    console.log(orderType == 'course-orders')

    //set Table data
    useEffect(() => {
        axios.get(BACKEND_SERVER_URL + `${orderType == 'course-orders' ? '/getOrders' : '/getTestSeriesOrders'}`).then((res) => {
            console.log(res.data.orders)
            if (res.data.orders) {
                let orders = res.data.orders;
                setTotalSuccessOrders(orders.filter(order => order.transactionStatus == 'Success'))
                setTotalFailureOrders(orders.filter(order => order.transactionStatus == 'Failure'))
                setTotalTimeoutOrders(orders.filter(order => order.transactionStatus == 'Timeout'))
                setOrders(orders);
                setChecked(orders);
            }
        }).catch((error) => {
            console.log(error)
            toast.error(error.response.data.message)
        })
        if (orderType == 'course-orders') {
            setSelectedCourse({ courseTitle: 'All Courses' })
            getCourses().then((res) => {
                res.unshift({ courseTitle: "All Courses" })
                setCourses(res)
            })
            getBatches().then((res) => {
                setBatches(res)
            })
        } else {
            setSelectedCourse({ courseTitle: 'All Test Series' })
            getTestSeries().then((res) => {
                // console.log(res)             
                let testSeries = res?.map(data => ({ ...data, courseTitle: data.title }))
                //console.log(testSeries)

                testSeries.unshift({ courseTitle: "All Test Series" })
                setCourses(testSeries)
            })
            getTestSeriesBatches().then((res) => {
                console.log(res)
                setBatches(res)
            })
        }

    }, [loadStudents, orderType])


    const pageSize = 100;
    const lastIndex = currentPage * pageSize;
    const firstIndex = lastIndex - pageSize;
    const records = checked.slice(firstIndex, lastIndex);

    const npage = Math.ceil(checked.length / pageSize)
    const number = [...Array(npage + 1).keys()].slice(1)




    const handlePageChange = (e, p) => {
        setCurrentPage(parseInt(p));
    }

    const selectCourseHandler = (selectedCourse) => {
        console.log(selectedCourse)
        setSelectedCourse(selectedCourse);
        setSelectedBatch({ batchName: "All Batches" })
        setTransactionStatus('All Transactions');
        if (selectedCourse.courseTitle == 'All Courses' || selectedCourse.courseTitle == "All Test Series") {
            console.log('enter')
            setChecked(orders);
            setCourseWiseOrders(orders);
            setCourseWiseBatches([{ batchName: "All Batches" }]);
            return;
        }
        let selectedItems = orders.filter(order => order.courseId == selectedCourse.courseId || order.courseTitle == selectedCourse.courseTitle)
        setCourseWiseOrders(selectedItems);
        setChecked(selectedItems);

        //set batches course wise
        let selectedSubjects = batches.filter(subject => subject.courseId == selectedCourse.courseId)
        selectedSubjects.unshift({ batchName: "All Batches" })
        setCourseWiseBatches(selectedSubjects)
    }
    const selectBatchHandler = (selectedBatch) => {
        setSelectedBatch(selectedBatch);
        if (selectedBatch.batchName == 'All Batches') {
            setChecked(courseWiseOrders);
            setBatchWiseOrders(courseWiseOrders)
            return;
        }
        setChecked(courseWiseOrders.filter(topic => topic.batchName == selectedBatch.batchName))
    }
    const getFilterArray = () => {
        if (checked.length > 0) return checked
        else if (batchWiseOrders.length > 0) return batchWiseOrders
        else if (courseWiseOrders.length > 0) return courseWiseOrders
        else return orders
    }
    const selectTransactionHandler = (selectedStatus) => {
        console.log(selectedStatus)
        setTransactionStatus(selectedStatus);
        if (selectedStatus == 'All Transactions') {
            setChecked(getFilterArray());
            return;
        }
        setChecked(getFilterArray().filter(topic => topic.transactionStatus == selectedStatus))
    }


    return (
        <>
            <div className="row">
                <div className="col-xl-12">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className=" page-title  p-2 mb-2 justify-content-between ">{/*  flex-wrap  */}
                                <div className="col-9 d-flex  justify-content-evenly ">
                                    <div className="col-3">
                                        <div className="basic-dropdown">
                                            <Select
                                                value={{ value: selectedCourse, label: selectedCourse.courseTitle }}
                                                onChange={(selectedOption) => selectCourseHandler(selectedOption.value)}
                                                options={courses?.map((course) => ({ value: course, label: course.courseTitle }))}
                                                styles={setDropDownStyle()}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className="basic-dropdown ms-1">
                                            <Select
                                                value={{ value: selectedBatch, label: selectedBatch.batchName }}
                                                onChange={(selectedOption) => selectBatchHandler(selectedOption.value)}
                                                options={courseWiseBatches?.map((subject) => ({ value: subject, label: subject.batchName }))}
                                                styles={setDropDownStyle()}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className="basic-dropdown ms-1">
                                            <Select
                                                value={{ value: transactionStatus, label: transactionStatus }}
                                                onChange={(selectedOption) => selectTransactionHandler(selectedOption.value)}
                                                options={['All Transactions', 'Success', 'Aborted', 'Failure', 'Awaited']?.map((subject) => ({ value: subject, label: subject }))}
                                                styles={setDropDownStyle()}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-group search-area mb-md-0 mb-3">
                                    <input type="text" className="form-control" onChange={handleSearch} value={searchTerm} placeholder="Search by Item, Email..." />
                                    <span className="input-group-text"><Link to={"#"}>
                                        <svg width="15" height="15" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z" fill="#01A3FF" />
                                        </svg>
                                    </Link></span>
                                </div>

                            </div>

                            <div className='page-title pt-1 mb-1 pb-2' style={{ zIndex: '0' }}>
                                <div className="container-fluid p-1 pb-0">
                                    <div className="row">
                                        <div className="col-12 col-sm-6 d-sm-flex ">
                                            <div className="col-12 col-sm-6 mb-3 mb-sm-0 ">
                                                <button
                                                    onClick={() => setChecked(orders)}
                                                    type="button"
                                                    className="btn btn-primary btn-sm btn-block m-auto ps-0 pe-3 w-75"
                                                >
                                                    Total Orders: {orders.length}
                                                </button>
                                            </div>
                                            <div className="col-12 col-sm-6  ">
                                                <button
                                                    onClick={() => setChecked(totalSuccessOrders)}
                                                    type="button"
                                                    className="btn btn-success btn-sm btn-block m-auto ps-0 pe-3 w-75"
                                                >
                                                    Success: {totalSuccessOrders.length}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 d-sm-flex mt-3 mt-sm-0 ">
                                            <div className="col-12 col-sm-6 mb-3 mb-sm-0 ">
                                                <button
                                                    onClick={() => setChecked(totalTimeoutOrders)}
                                                    type="button"
                                                    className="btn btn-warning btn-block btn-sm  m-auto ps-0 w-75"
                                                >
                                                    Timeout: {totalFailureOrders.length}
                                                </button>
                                            </div>
                                            <div className="col-12 col-sm-6 ">
                                                <button
                                                    onClick={() => setChecked(totalFailureOrders)}
                                                    type="button"
                                                    className="btn btn-danger btn-block btn-sm  m-auto ps-0 w-75"
                                                >
                                                    Failure: {totalTimeoutOrders.length}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="col-xl-12 wow fadeInUp" data-wow-delay="1.5s">
                            <div className=" col-12 d-sm-flex text-center justify-content-between align-items-center">
                                <div className='dataTables_info text-dark'>
                                    Records: {checked.length}
                                </div>
                                {checked.length > pageSize ?
                                    <div className=" dataTables_paginate paging_simple_numbers justify-content-end  mb-0 pt-2"
                                        id="example-student_wrapper">
                                        {/*  <div className="col-4 d-flex justify-content-end">
                          <label htmlFor="jumpPage" className='me-2 mt-2'>  Jump to Page</label>
                          <input className='form-control p-1' type="number" name="" id="jumpPage" style={{ width: "30%" }} min="1" max={number.length} value={currentPage} onChange={(e) => setCurrentPage(parseInt(e.target.value))} />
                        </div> */}
                                        <Pagination count={number.length} variant="outlined" color='primary' size="small" page={currentPage} siblingCount={1} boundaryCount={2} onChange={handlePageChange} />
                                    </div> : null}
                            </div>
                            <div className="table-responsive full-data" style={{ paddingBottom: "1px", transform: 'rotateX(180deg)' }}>
                                <div id="example-student_wrapper" className="dataTables_wrapper no-footer">

                                    <table className="table-responsive-lg table display dataTablesCard student-tab 
                                    dataTable no-footer mt-0  mb-0" id="example-student" style={{ transform: 'rotateX(180deg)' }} >
                                        <thead style={{ backgroundColor: "#cccccc" }} className='header-color'>
                                            <tr className='text-center  text-dark  border border-left'>
                                                <th className='p-2 custom-table-header '>SN</th>
                                                {/* <th className='p-2 custom-table-header '>
                                                    <input type="checkbox" className="form-check-input" id="checkAll" checked={!unchecked}
                                                        onClick={() => handleCheckedAll(unchecked)}
                                                    />
                                                </th> */}
                                                {/*    <th className='p-2 custom-table-header '>STATUS</th> */}
                                                <th className=' text-start p-2 custom-table-header  '>COURSE NAME</th>
                                                <th className=' text-start p-2 custom-table-header  '>BATCH NAME</th>
                                                <th className=' p-2  text-start custom-table-header '>EMAIL</th>
                                                <th className='p-2 custom-table-header '>TXN. STATUS</th>
                                                <th className='  p-2   custom-table-header '>PAYMENT DATE</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {records.length > 0 ? records.map((item, ind) => (
                                                <tr key={ind} className={`${ind % 2 == 1 ? 'custom text-center' : 'row-height text-center'}`}>
                                                    <td className='text-center p-1 column-width ' style={{ border: "1px solid #cccccc" }}><h6 className="sn-font-weight mb-0" style={{ fontWeight: "bold" }}> {item.sn}</h6></td>
                                                    {/*  <td className='text-center p-1 column-width ' style={{ border: "1px solid #cccccc" }}>
                                                        <div className="checkbox me-0 align-self-center">
                                                            <div className="custom-control custom-checkbox ">
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    id={`stud-${item.sn}`}
                                                                    checked={item.inputchecked}
                                                                    onChange={() => handleChecked(item.sn)}
                                                                />
                                                                <label className="custom-control-label" htmlFor={`stud-${item.sn}`}></label>
                                                            </div>
                                                        </div>
                                                    </td> */}
                                                    <td className='p-1' style={{ border: "1px solid #cccccc" }}>
                                                        <div className="trans-list sweetalert">
                                                            <h6 className='mb-0 ' style={{ fontWeight: "bold" }}>{item.courseTitle}</h6>
                                                        </div>
                                                    </td>


                                                    <td className='p-1 text-start ' style={{ border: "1px solid #cccccc" }}><h6 className="mb-0">{item.batchName}</h6></td>
                                                    <td className='p-1 text-start text-primary' style={{ border: "1px solid #cccccc" }}><h6 className="mb-0">{item.email}</h6></td>
                                                    <td className='text-center p-1 active-col-width ' style={{ border: "1px solid #cccccc" }}>
                                                        <h6 className="text-success mb-0 "> {item.transactionStatus} </h6>
                                                    </td>
                                                    <td className='text-center p-1' style={{ border: "1px solid #cccccc", width: "85px" }} >
                                                        <h6 className="date mb-0">{format(item?.paymentDate || '0001-01-01', "dd/MM/yyyy")}</h6>
                                                    </td>
                                                </tr>
                                            )) :
                                                <tr>
                                                    <td colSpan="9" className="text-center">
                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                                                            {
                                                                showTableResponse(orders, checked)
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            {/* <StudentModal ref={childRef} loadStudents={loadStudents} setStudentLoading={setStudentLoading} /> */}
        </>
    )
}

export default OrderManagement;
