import React, { Fragment, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'
import CustomClearIndicator from './MultiSelectBatches';
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import { Modal } from 'react-bootstrap';
import { ListItem } from '@mui/material';
import { checkExistingItem } from '../common-helpers/commonServices';

const CourseModal = forwardRef((props, ref) => {
    const [modalBox, setModalBox] = useState(false);
    const [id, setId] = useState();
    let intialData = {
        sNo: 0,
        name: '',
        email: '',
        contactNumber: null,
        password: null
    }
    const [studenData, setStudentData] = useState(intialData)

    //check for existing items       
    const [itemAlreadyExist, setItemAlreadyExists] = useState(false);
    const [currentTitle, setCurrentTitle] = useState('');//for updating 
    useEffect(() => {
        if (studenData.email && currentTitle.toLowerCase().trim() != studenData.email.toLowerCase().trim()) { // current title is for updating case
            console.log('enter')
            let timer = setTimeout(() => {
                checkExistingItem('Registration', 'EmailId', studenData.email).then(response => { //tablename and columnNane value
                    setItemAlreadyExists(response)
                })

            }, 500);
            return () => clearTimeout(timer);
        }

    }, [studenData.email]);
    useImperativeHandle(ref, () => ({
        openModal(item = {}) {
            //console.log(item)
            if (item.sn > 0) {
                setStudentData({
                    sNo: item.sn,
                    name: item.lastName == null || item.lastName == '' ? item.firstName : item.firstName + ' ' + item.lastName,
                    email: item.email,
                    contactNumber: item.contactNumber,
                    password: item.password
                })
                setCurrentTitle(item.email)
            }
            setModalBox(true)
        }
    }));

    const handleSaveStudent = async () => {
        if (!studenData.name) {
            toast.error("Please Enter Name")
            return;
        } else if (!studenData.contactNumber) {
            toast.error("Please Enter Contact Number")
            return;
        } else if (!studenData.email) {
            toast.error("Please Enter Email ID")
            return;
        } else if (!studenData.password) {
            toast.error("Please Enter Password")
            return;
        } else if (itemAlreadyExist && currentTitle.toLowerCase().trim() != studenData.email.toLowerCase().trim()) {
            toast.error("Student Already Exists with this Email ID")
            return;
        }

        try {
            const res = await axios.post(BACKEND_SERVER_URL + (studenData.sNo ? `/updateStudent` : '/createStudent'), studenData);
            if (res?.data.statusCode == 200) {
                toast.success(res.data.message);
                props.setStudentLoading(!props.loadStudents);
                setModalBox(false);
            }
        } catch (error) {
            console.error("An error occurred during the request:", error.message);
            toast.error(error.response.data.message);
        }
    }
    useEffect(() => {
        if (!modalBox) {
            setStudentData(intialData); // Reset form data when modal is closed
        }
    }, [modalBox]);

    return (
        <>
            <Modal onHide={setModalBox} show={modalBox} dialogClassName="custom-modal-width" size="lg" backdrop="static"
                keyboard={false} >
                <Fragment>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="mb-0">{studenData.sNo ? 'Update Student' : 'Create Student'}</h5>
                                    <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-xl-6 col-sm-6">
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput9" className="form-label text-primary" >Student Name<span className="required">*</span></label>
                                                <input type="text" className="form-control" id="exampleFormControlInput9" placeholder="Enter Name" value={studenData.name} onChange={(e) => setStudentData({ ...studenData, name: e.target.value })} />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput10" className="form-label text-primary" >Student Email ID <span className="required">*</span></label>
                                                <input type="email" className="form-control" id="exampleFormControlInput10" placeholder="hello@example.com" value={studenData.email} onChange={(e) => setStudentData({ ...studenData, email: e.target.value })} />
                                            </div>

                                        </div>
                                        <div className="col-xl-6 col-sm-6">

                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput11" className="form-label text-primary" >Contact Number<span className="required">*</span></label>
                                                <input type="number" className="form-control" id="exampleFormControlInput11" placeholder="Enter Contact Number" value={studenData.contactNumber} onChange={(e) => setStudentData({ ...studenData, contactNumber: e.target.value })} />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput12" className="form-label text-primary" >Password<span className="required">*</span></label>
                                                <input type="text" className="form-control" id="exampleFormControlInput12" placeholder="Enter Password" value={studenData.password} onChange={(e) => setStudentData({ ...studenData, password: e.target.value })} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer pb-0">
                                    <button className='btn btn-danger  btn-xs ' onClick={() => setModalBox(false)}>Cancel</button>
                                    <button className='btn btn-primary  btn-xs ' onClick={handleSaveStudent}>{studenData.sNo ? 'Update Student' : 'Create Student'}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment >
            </Modal>
        </>
    )
})
export default CourseModal;