import React from 'react';
import smallLogo from '../new images/smallLogoInsightDelhi.png'
import parse from 'html-react-parser';

const DummyPage = () => {
    return (
        <>
            <div className="container">
                {parse(`<p class="MsoNormal" style="text-indent: -27.0pt; mso-list: l0 level1 lfo1; tab-stops: 27.0pt; mso-layout-grid-align: none; text-autospace: none; margin: 0in 0in 12.0pt 27.0pt;">&nbsp;</p>
<p class="MsoNormal" style="text-align: center; text-indent: -27.0pt; tab-stops: 0in 27.0pt 40.5pt; background: #FFC000; margin: 0in -.1in 12.0pt 27.0pt;" align="center"><strong style="mso-bidi-font-weight: normal;"><span style="font-size: 18.0pt; font-family: 'Arial Black',sans-serif; mso-bidi-font-family: Calibri; color: #002060;">CLASS QUESTIONS: MAURYAN EMPIRE</span></strong></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -27.0pt; mso-list: l0 level1 lfo1; tab-stops: 27.0pt 40.5pt 400.5pt; mso-layout-grid-align: none; text-autospace: none; margin: 0in 0in 12.0pt 27.0pt;"><!-- [if !supportLists]--><strong style="mso-bidi-font-weight: normal;"><span style="font-size: 18.0pt; font-family: 'Calibri',sans-serif; mso-fareast-font-family: Calibri; color: #002060;"><span style="mso-list: Ignore;">1.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span></strong><!--[endif]--><span style="font-size: 18.0pt; font-family: 'Calibri',sans-serif; color: #002060;">Discuss the factors that contributed to the success of Magadhan imperialism.</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -27.0pt; mso-list: l0 level1 lfo1; tab-stops: 27.0pt 40.5pt 400.5pt; mso-layout-grid-align: none; text-autospace: none; margin: 0in 0in 12.0pt 27.0pt;"><!-- [if !supportLists]--><strong style="mso-bidi-font-weight: normal;"><span style="font-size: 18.0pt; font-family: 'Calibri',sans-serif; mso-fareast-font-family: Calibri; color: #002060;"><span style="mso-list: Ignore;">2.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span></strong><!--[endif]--><span style="font-size: 18.0pt; font-family: 'Calibri',sans-serif; color: #002060;">Analyse the cause of the success of the Magadhan imperialism upto the reign of Ashoka the Great. </span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -27.0pt; mso-list: l0 level1 lfo1; tab-stops: 27.0pt 40.5pt 400.5pt; mso-layout-grid-align: none; text-autospace: none; margin: 0in 0in 12.0pt 27.0pt;"><!-- [if !supportLists]--><strong style="mso-bidi-font-weight: normal;"><span style="font-size: 18.0pt; font-family: 'Calibri',sans-serif; mso-fareast-font-family: Calibri; color: #002060;"><span style="mso-list: Ignore;">3.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span></strong><!--[endif]--><span style="font-size: 18.0pt; font-family: 'Calibri',sans-serif; color: #002060;">Examine the significance of the favourable geographical location in the success of Magadhan imperialism. </span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -27.0pt; mso-list: l0 level1 lfo1; tab-stops: 27.0pt 40.5pt 400.5pt; mso-layout-grid-align: none; text-autospace: none; margin: 0in 0in 12.0pt 27.0pt;"><!-- [if !supportLists]--><strong style="mso-bidi-font-weight: normal;"><span style="font-size: 18.0pt; font-family: 'Calibri',sans-serif; mso-fareast-font-family: Calibri; color: #002060;"><span style="mso-list: Ignore;">4.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span></strong><!--[endif]--><span style="font-size: 18.0pt; font-family: 'Calibri',sans-serif; color: #002060;">Examine the nature of the Mauryan State. Bring out the features of their administrative system. </span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -27.0pt; mso-list: l0 level1 lfo1; tab-stops: 27.0pt 40.5pt 400.5pt; mso-layout-grid-align: none; text-autospace: none; margin: 0in 0in 12.0pt 27.0pt;"><!-- [if !supportLists]--><strong style="mso-bidi-font-weight: normal;"><span style="font-size: 18.0pt; font-family: 'Calibri',sans-serif; mso-fareast-font-family: Calibri; color: #002060;"><span style="mso-list: Ignore;">5.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span></strong><!--[endif]--><span style="font-size: 18.0pt; font-family: 'Calibri',sans-serif; color: #002060;">Do you agree with the popular view that Mauryans established a unitary and highly centralized if not monolithic state system?</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -27.0pt; mso-list: l0 level1 lfo1; tab-stops: 27.0pt 40.5pt 400.5pt; mso-layout-grid-align: none; text-autospace: none; margin: 0in 0in 12.0pt 27.0pt;"><!-- [if !supportLists]--><strong style="mso-bidi-font-weight: normal;"><span style="font-size: 18.0pt; font-family: 'Calibri',sans-serif; mso-fareast-font-family: Calibri; color: #002060;"><span style="mso-list: Ignore;">6.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span></strong><!--[endif]--><span style="font-size: 18.0pt; font-family: 'Calibri',sans-serif; color: #002060;">Examine the nature and character of the Mauryan administrative system. </span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -27.0pt; mso-list: l0 level1 lfo1; tab-stops: 27.0pt 40.5pt 400.5pt; mso-layout-grid-align: none; text-autospace: none; margin: 0in 0in 12.0pt 27.0pt;"><!-- [if !supportLists]--><strong style="mso-bidi-font-weight: normal;"><span style="font-size: 18.0pt; font-family: 'Calibri',sans-serif; mso-fareast-font-family: Calibri; color: #002060;"><span style="mso-list: Ignore;">7.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span></strong><!--[endif]--><span style="font-size: 18.0pt; font-family: 'Calibri',sans-serif; color: #002060;">Bring out the elements of change and continuity in the domestic and foreign policies of Ashoka.</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -27.0pt; mso-list: l0 level1 lfo1; tab-stops: 27.0pt 40.5pt 400.5pt; mso-layout-grid-align: none; text-autospace: none; margin: 0in 0in 12.0pt 27.0pt;"><!-- [if !supportLists]--><strong style="mso-bidi-font-weight: normal;"><span style="font-size: 18.0pt; font-family: 'Calibri',sans-serif; mso-fareast-font-family: Calibri; color: #002060;"><span style="mso-list: Ignore;">8.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span></strong><!--[endif]--><span style="font-size: 18.0pt; font-family: 'Calibri',sans-serif; color: #002060;">How did Ashoka contribute to the moral and administrative welfare of his people? </span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -27.0pt; mso-list: l0 level1 lfo1; tab-stops: 27.0pt 40.5pt 400.5pt; mso-layout-grid-align: none; text-autospace: none; margin: 0in 0in 12.0pt 27.0pt;"><!-- [if !supportLists]--><strong style="mso-bidi-font-weight: normal;"><span style="font-size: 18.0pt; font-family: 'Calibri',sans-serif; mso-fareast-font-family: Calibri; color: #002060;"><span style="mso-list: Ignore;">9.<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span></strong><!--[endif]--><span style="font-size: 18.0pt; font-family: 'Calibri',sans-serif; color: #002060;">Examine the importance of Ashoka&rsquo;s Rock Edicts XIII for determining the extent of the Maurya Empire. </span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -27.0pt; mso-list: l0 level1 lfo1; tab-stops: 27.0pt 40.5pt 400.5pt; mso-layout-grid-align: none; text-autospace: none; margin: 0in 0in 12.0pt 27.0pt;"><!-- [if !supportLists]--><strong style="mso-bidi-font-weight: normal;"><span style="font-size: 18.0pt; font-family: 'Calibri',sans-serif; mso-fareast-font-family: Calibri; color: #002060;"><span style="mso-list: Ignore;">10.<span style="font: 7.0pt 'Times New Roman';">&nbsp; </span></span></span></strong><!--[endif]--><span style="font-size: 18.0pt; font-family: 'Calibri',sans-serif; color: #002060;">Determine the territorial extent of the Mauryan Empire on the basis of all available sources.</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -27.0pt; mso-list: l0 level1 lfo1; tab-stops: 27.0pt 40.5pt 400.5pt; mso-layout-grid-align: none; text-autospace: none; margin: 0in 0in 12.0pt 27.0pt;"><!-- [if !supportLists]--><strong style="mso-bidi-font-weight: normal;"><span style="font-size: 18.0pt; font-family: 'Calibri',sans-serif; mso-fareast-font-family: Calibri; color: #002060;"><span style="mso-list: Ignore;">11.<span style="font: 7.0pt 'Times New Roman';">&nbsp; </span></span></span></strong><!--[endif]--><span style="font-size: 18.0pt; font-family: 'Calibri',sans-serif; color: #002060;">Discuss different interpretations of historians about the nature of Asoka&rsquo;s &lsquo;Dhamma&rsquo;. Did his principle of &lsquo;Dhamma-vijaya&rsquo; render the Mauryan Empire militaristically weak?<span style="mso-spacerun: yes;">&nbsp; </span></span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -27.0pt; mso-list: l0 level1 lfo1; tab-stops: 27.0pt 40.5pt 400.5pt; mso-layout-grid-align: none; text-autospace: none; margin: 0in 0in 12.0pt 27.0pt;"><!-- [if !supportLists]--><strong style="mso-bidi-font-weight: normal;"><span style="font-size: 18.0pt; font-family: 'Calibri',sans-serif; mso-fareast-font-family: Calibri; color: #002060;"><span style="mso-list: Ignore;">12.<span style="font: 7.0pt 'Times New Roman';">&nbsp; </span></span></span></strong><!--[endif]--><span style="font-size: 18.0pt; font-family: 'Calibri',sans-serif; color: #002060;">"The concept of Ashoka's Dhamma as found through his inscriptions had its roots in Vedic-Upanishadic literature." Discuss.</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -27.0pt; mso-list: l0 level1 lfo1; tab-stops: 27.0pt 40.5pt 400.5pt; mso-layout-grid-align: none; text-autospace: none; margin: 0in 0in 12.0pt 27.0pt;"><!-- [if !supportLists]--><strong style="mso-bidi-font-weight: normal;"><span style="font-size: 18.0pt; font-family: 'Calibri',sans-serif; mso-fareast-font-family: Calibri; color: #002060;"><span style="mso-list: Ignore;">13.<span style="font: 7.0pt 'Times New Roman';">&nbsp; </span></span></span></strong><!--[endif]--><span style="font-size: 18.0pt; font-family: 'Calibri',sans-serif; color: #002060;">Analyse Ashoka&rsquo;s policy of Dharma and account for its failure.</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -27.0pt; mso-list: l0 level1 lfo1; tab-stops: 27.0pt 40.5pt 400.5pt; mso-layout-grid-align: none; text-autospace: none; margin: 0in 0in 12.0pt 27.0pt;"><!-- [if !supportLists]--><strong style="mso-bidi-font-weight: normal;"><span style="font-size: 18.0pt; font-family: 'Calibri',sans-serif; mso-fareast-font-family: Calibri; color: #002060;"><span style="mso-list: Ignore;">14.<span style="font: 7.0pt 'Times New Roman';">&nbsp; </span></span></span></strong><!--[endif]--><span style="font-size: 18.0pt; font-family: 'Calibri',sans-serif; color: #002060;">Bring out the essential elements of Ashoka&rsquo;s Dhamma. Why was Ashoka keen to propagate it?</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -27.0pt; mso-list: l0 level1 lfo1; tab-stops: 27.0pt 40.5pt 400.5pt; mso-layout-grid-align: none; text-autospace: none; margin: 0in 0in 12.0pt 27.0pt;"><!-- [if !supportLists]--><strong style="mso-bidi-font-weight: normal;"><span style="font-size: 18.0pt; font-family: 'Calibri',sans-serif; mso-fareast-font-family: Calibri; color: #002060;"><span style="mso-list: Ignore;">15.<span style="font: 7.0pt 'Times New Roman';">&nbsp; </span></span></span></strong><!--[endif]--><span style="font-size: 18.0pt; font-family: 'Calibri',sans-serif; color: #002060;">Explain how Ashoka used religion as a tool of political aggrandizement? </span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -27.0pt; mso-list: l0 level1 lfo1; tab-stops: 27.0pt 40.5pt 400.5pt; mso-layout-grid-align: none; text-autospace: none; margin: 0in 0in 12.0pt 27.0pt;"><!-- [if !supportLists]--><strong style="mso-bidi-font-weight: normal;"><span style="font-size: 18.0pt; font-family: 'Calibri',sans-serif; mso-fareast-font-family: Calibri; color: #002060;"><span style="mso-list: Ignore;">16.<span style="font: 7.0pt 'Times New Roman';">&nbsp; </span></span></span></strong><!--[endif]--><span style="font-size: 18.0pt; font-family: 'Calibri',sans-serif; color: #002060;">Ashoka&rsquo;s Dhamma was propagated not just for moral upliftment and social harmony but also for expansion of the state&rsquo;s authority. Analyse the statement.</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -27.0pt; mso-list: l0 level1 lfo1; tab-stops: 27.0pt 40.5pt 400.5pt; mso-layout-grid-align: none; text-autospace: none; margin: 0in 0in 12.0pt 27.0pt;"><!-- [if !supportLists]--><strong style="mso-bidi-font-weight: normal;"><span style="font-size: 18.0pt; font-family: 'Calibri',sans-serif; mso-fareast-font-family: Calibri; color: #002060;"><span style="mso-list: Ignore;">17.<span style="font: 7.0pt 'Times New Roman';">&nbsp; </span></span></span></strong><!--[endif]--><span style="font-size: 18.0pt; font-family: 'Calibri',sans-serif; color: #002060;">Examine various views associated with the disintegration of Mauryan Empire. Why did the Mauryan Empire collapse after flourishing for more than one hundred years? </span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -27.0pt; mso-list: l0 level1 lfo1; tab-stops: 27.0pt 40.5pt 400.5pt; mso-layout-grid-align: none; text-autospace: none; margin: 0in 0in 12.0pt 27.0pt;"><!-- [if !supportLists]--><strong style="mso-bidi-font-weight: normal;"><span style="font-size: 18.0pt; font-family: 'Calibri',sans-serif; mso-fareast-font-family: Calibri; color: #002060;"><span style="mso-list: Ignore;">18.<span style="font: 7.0pt 'Times New Roman';">&nbsp; </span></span></span></strong><!--[endif]--><span style="font-size: 18.0pt; font-family: 'Calibri',sans-serif; color: #002060;">Critically examine the responsibility of Ashoka in the disintegration of the Mauryan Empire.</span></p>
<p class="MsoNormal" style="text-align: justify; text-indent: -27.0pt; mso-list: l0 level1 lfo1; tab-stops: 27.0pt 40.5pt 400.5pt; mso-layout-grid-align: none; text-autospace: none; margin: 0in 0in 12.0pt 27.0pt;"><!-- [if !supportLists]--><strong style="mso-bidi-font-weight: normal;"><span style="font-size: 18.0pt; font-family: 'Calibri',sans-serif; mso-fareast-font-family: Calibri; color: #002060;"><span style="mso-list: Ignore;">19.<span style="font: 7.0pt 'Times New Roman';">&nbsp; </span></span></span></strong><!--[endif]--><span style="font-size: 18.0pt; font-family: 'Calibri',sans-serif; color: #002060;">Do you think that the economic factors were alone responsible for the disintegration of the Mauryan empire? </span></p>`)}
            </div>
        </>
    );
}

export default DummyPage;
