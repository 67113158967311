import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify'
import { BACKEND_SERVER_URL } from '../../../../http/http-config';
import Input from '../../Common-Components/Input';
import FileInput from '../../Common-Components/FileInput';
import DropdownInput from '../../Common-Components/DropdownInput';
import DateInput from '../../Common-Components/DateInput';
import '../../Course/Course.css'
import CKEditorInput from '../../Common-Components/CKEditorInput';
import { generateUniqueID } from './../CourseHelper';
import { getCourses, getSubjects, getTopic, validateTopicData } from './TopicHelper';
import CourseAndSubjectDropDown from '../../Common-Components/CourseAndSubjectDropDown';
import { checkExistingItem } from '../../common-helpers/commonServices';



const TopicModal = forwardRef((props, ref) => {

    let initialFormData = {
        title: '',
        sNo: '',
        sectionId: generateUniqueID(),
        packageId: '',
        password: '',
        courseId: '',
        subject: '',
        subjectId: 0,
        demo: 'NO',
        publishDate: new Date(),
        description: '',
        imageURL: '',
        pdfURL: '',
        videoContent: '',
        videoId: '',
        tags: '',
        totalAttempt: 1,
        questionCount: 0,
        quizDuration: 60,
        totalMarks: 0,
        marksPerQuestion: 0,
        negativeMarks: 0,
        initialRank: 0
    }
    const [modalBox, setModalBox] = useState(false);    //new course
    const [updatingTopicId, setUpdatingTopicId] = useState();
    const [formData, setFormData] = useState(initialFormData);
    const [updatingDataReady, setUpdatingDataReady] = useState(false);

    const handleChange = (name, value) => {
        console.log(name, value)
        setFormData((formData) => (
            { ...formData, [name]: value }
        ));
    };

    //check for existing items       
    const [itemAlreadyExist, setItemAlreadyExists] = useState(false);
    const [currentTitle, setCurrentTitle] = useState('');//for updating 
    useEffect(() => {
        if (formData.title && currentTitle.toLowerCase().trim() != formData.title.toLowerCase().trim()) { // current title is for updating case
            console.log('enter')
            let timer = setTimeout(() => {
                checkExistingItem('TblLession', 'Title', formData.title).then(response => { //tablename and columnNane value
                    setItemAlreadyExists(response)
                })

            }, 500);
            return () => clearTimeout(timer);
        }

    }, [formData.title]);

    useImperativeHandle(ref, () => ({
        openModal(topicId) {
            if (topicId) {
                setUpdatingTopicId(topicId)
                getTopic(topicId).then((res) => {
                    console.log(res)
                    setFormData(res);
                    setCurrentTitle(res.title)
                    setUpdatingDataReady(true);
                    setModalBox(true);
                })
            } else {
                setUpdatingDataReady(true);
                setModalBox(true);
            }
        }
    }));

    const handleSubmitTopic = () => {
        let errorMessgae = validateTopicData(formData)
        console.log(errorMessgae)
        if (errorMessgae) {
            toast.error(errorMessgae)
            return;
        } else if (itemAlreadyExist && currentTitle.toLowerCase().trim() != formData.title.toLowerCase().trim()) {
            toast.error("Topic Already Exists")
            return;
        }


        axios.post(BACKEND_SERVER_URL + (updatingTopicId ? `/updateTopic` : '/saveTopic'), formData).then((response) => {
            if (response.data.statusCode == 201) {
                setFormData(initialFormData)
                toast.success(response.data.message)
                setModalBox(false);
                props.setTopicsLoading(!props.loadTopics)
                setUpdatingTopicId(0);
            }
        }).catch((error) => {
            toast.error(error.response.data.message)
            console.log(error)
        })

    }
    useEffect(() => {
        if (!modalBox) {
            setFormData(initialFormData); // Reset form data when modal is closed 
            setUpdatingTopicId(0)
            setItemAlreadyExists(false)
            setCurrentTitle('')
        }
    }, [modalBox]);


    return (
        <>
            <Modal className="" show={modalBox} onHide={setModalBox} dialogClassName="custom-modal-width" backdrop="static"
                keyboard={false} >
                <Modal.Header>
                    <Modal.Title>{updatingTopicId ? 'Update Lesson' : 'Add Lesson'}</Modal.Title>
                    <Button variant="" className="btn-close" onClick={() => setModalBox(false)}></Button>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-around  ">
                                <CourseAndSubjectDropDown setFormData={setFormData} formData={formData} courseId={formData.courseId} subjectId={formData.subjectId} />
                            </div>
                            <div className="col-12 d-flex justify-content-around ">
                                <div className="col-5 d-flex">
                                    <Input isMandatory={true} inputType="text" handleChangeCallBack={handleChange} labelName="Title" inputValue={formData?.title} inputName='title' divSizes={[4, 8]} />
                                </div>
                                <div className="col-5 d-flex ">
                                    <Input isMandatory={true} handleChangeCallBack={handleChange} labelName="Package ID" inputType="text" inputValue={formData?.packageId} inputName='packageId' divSizes={[4, 8]} />
                                </div>
                            </div>

                            <div className="col-12 d-flex justify-content-around  ">
                                <div className="col-5 d-flex ">
                                    <Input isMandatory={true} handleChangeCallBack={handleChange} labelName="Section ID" inputType="text" inputValue={formData?.sectionId} inputName='sectionId' divSizes={[4, 8]} />
                                </div>
                                <div className="col-5 d-flex align-items-center">
                                  
                                    <DateInput labelName="Publish Date" selected={formData?.publishDate} handleChangeCallBack={handleChange} inputName='publishDate' />
                                
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-around">
                                <div className="col-5 d-flex ">
                                    <DropdownInput handleChangeCallBack={handleChange} labelName="Demo" selectedValue={formData?.demo} inputName='demo' dropdownValuesArray={['YES', 'NO']} />
                                </div>
                                <div className="col-5 d-flex ">
                                    <Input isMandatory={true} handleChangeCallBack={handleChange} labelName="S. No." inputType="number" inputValue={formData?.sNo} inputName='sNo' divSizes={[4, 8]} />
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-around">
                                <div className="col-5 d-flex ">
                                    <Input isMandatory={false} handleChangeCallBack={handleChange} labelName="Video Content" inputType="number" inputValue={formData?.videoContent} inputName='videoContent' divSizes={[4, 8]} />
                                </div>
                                <div className="col-5 d-flex ">
                                    <Input isMandatory={false} handleChangeCallBack={handleChange} labelName="Password" inputType="text" inputValue={formData?.password} inputName='password' divSizes={[4, 8]} />

                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-around">
                                <div className="col-5 d-flex ">
                                    <Input isMandatory={true} handleChangeCallBack={handleChange} labelName="Total Question" inputType="number" inputValue={formData?.questionCount} inputName='questionCount' divSizes={[4, 8]} />
                                </div>
                                <div className="col-5 d-flex ">
                                    <Input isMandatory={false} handleChangeCallBack={handleChange} labelName="Quiz Duration(Minutes)" inputType="number" inputValue={formData?.quizDuration} inputName='quizDuration' divSizes={[4, 8]} />
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-around">
                                <div className="col-5 d-flex ">
                                    <Input isMandatory={false} handleChangeCallBack={handleChange} labelName="Total Marks" inputType="number" inputValue={formData?.totalMarks} inputName='totalMarks' divSizes={[4, 8]} />
                                </div>
                                <div className="col-5 d-flex ">
                                    <Input isMandatory={false} handleChangeCallBack={handleChange} labelName="Marks P. Q." inputType="number" inputValue={formData?.marksPerQuestion} inputName='marksPerQuestion' divSizes={[4, 8]} />
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-around">
                                <div className="col-5 d-flex ">
                                    <Input isMandatory={false} handleChangeCallBack={handleChange} labelName="Negative Marks" inputType="number" inputValue={formData?.negativeMarks} inputName='negativeMarks' divSizes={[4, 8]} />
                                </div>
                                <div className="col-5 d-flex ">
                                    <Input isMandatory={false} handleChangeCallBack={handleChange} labelName="Video ID" inputType="text" inputValue={formData?.videoId} inputName='videoId' divSizes={[4, 8]} />
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-around">
                                <div className="col-5 d-flex ">
                                    <Input isMandatory={false} handleChangeCallBack={handleChange} labelName="Total Attempt" inputType="number" inputValue={formData?.totalAttempt} inputName='totalAttempt' divSizes={[4, 8]} />
                                </div>
                                <div className="col-5 d-flex ">
                                    <Input handleChangeCallBack={handleChange} labelName="Initial Rank" inputType="number" inputValue={formData?.initialRank} inputName='initialRank' divSizes={[4, 8]} />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <CKEditorInput isMandatory={false} handleChangeCallBack={handleChange} labelName="Description" inputValue={formData?.description} inputName='description' />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <FileInput isMandatory={false} handleChangeCallBack={handleChange} labelName="Image" inputType="text" uploadedFileURL={formData?.imageURL} inputName='imageURL' accept="image/jpeg, image/png, image/gif" />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <FileInput isMandatory={false} handleChangeCallBack={handleChange} labelName="PDF URL" inputType="text" uploadedFileURL={formData?.pdfURL} inputName='pdfURL' accept="pdf" />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <Input isMandatory={false} handleChangeCallBack={handleChange} labelName="Tags" inputType="textarea" inputValue={formData?.tags} inputName='tags' divSizes={[2, 10]} />
                                </div>
                            </div>
                            {/* <div className="col-12 ">
                                <div className="d-flex">
                                    <Input isMandatory={false} handleChangeCallBack={handleChange} labelName="Video ID" inputType="text" inputValue={formData?.videoId} inputName='videoId' divSizes={[2, 10]} />
                                </div>
                            </div> */}

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger light" className='btn-sm' onClick={() => setModalBox(false)}>Close</Button>
                    <Button variant="primary " className='btn-sm ' onClick={handleSubmitTopic}>{updatingTopicId ? 'Update Lesson' : 'Add Lesson'}</Button>
                </Modal.Footer>
            </Modal>


        </>
    )
})
export default TopicModal;