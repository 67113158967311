import React, { useEffect, useRef, useState } from 'react'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Alert, Button, Spinner } from 'react-bootstrap';

import { format } from 'date-fns';
import { GrUpload } from "react-icons/gr";
import { FaDownload } from "react-icons/fa";
import { TiTickOutline } from "react-icons/ti";
import Select from 'react-select';

import axios from 'axios';
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import { Accordion, Pagination } from '@mui/material';
import ButtonMUI from '@mui/material/Button';

import Typography from '@mui/material/Typography';
import { SlArrowLeft } from 'react-icons/sl';
import { toast } from 'react-toastify';
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { getTestSeries, getTestsForDropdown, handleOpenPDF, setDropDownStyle } from '../CommonHelper';



const ModerateSolution = () => {
   //logic for course lesson 
    const [sectionIds, setSectionIds] = useState({ copyFromSectionId: '', copyInSectionId: '' })
    const copyLessonHandler = async () => {
        try {
            if (!sectionIds.copyFromSectionId || !sectionIds.copyInSectionId) {
                toast.error("Please Enter Both Section IDS")
                return;
            }
            const resposne = await axios.post(BACKEND_SERVER_URL + '/copyLessonQuestions', sectionIds);
            if (resposne.data.statusCode == 200) {
                toast.success(resposne.data.message)
                setSectionIds({ copyFromSectionId: '', copyInSectionId: '' })
            }
        } catch (error) {
            toast.error(error.resposne.data.message)
        }

    }

    // login for test series
    const [testSeries, setTestSeries] = useState([])
    const [selectedTestSeries, setSelectedTestSeries] = useState({ title: "Select Test Series" });
    const [selectedTestSeries2, setSelectedTestSeries2] = useState({ title: "Select Test Series" });
    const [selectedTest, setSelectedTest] = useState({ title: "Select Test" });
    const [selectedTest2, setSelectedTest2] = useState({ title: "Select Test" });
    // const [selectedTestSeriesSn, setSelectedTestSeriesSn] = useState(0)
    const [testSeriesTests, setTestSeriesTests] = useState([])
    const [testSeriesTests2, setTestSeriesTests2] = useState([])
    console.log(selectedTestSeries)

    useEffect(() => {
        getTestSeries().then((res) => {
            res.unshift({ title: "Select Test Series" })
            setTestSeries(res)
        })
    }, [])

    useEffect(() => {
        if (selectedTestSeries.courseId) {
            getTestsForDropdown(selectedTestSeries.courseId).then((res) => {
                console.log(res)
                res.unshift({ title: "Select Test" })
                setTestSeriesTests(res)
            })
        }

    }, [selectedTestSeries])

    useEffect(() => {
        if (selectedTestSeries2.courseId) {
            getTestsForDropdown(selectedTestSeries2.courseId).then((res) => {
                console.log(res)
                res.unshift({ title: "Select Test" })
                setTestSeriesTests2(res)
            })
        }
    }, [selectedTestSeries2])

    const handleSelectTestSeries = (selectedTestSeries) => {
        setSelectedTestSeries(selectedTestSeries)
        setSelectedTest({ title: "Select Test" })
    }
    const handleSelectTestSeries2 = (selectedTestSeries) => {
        setSelectedTestSeries2(selectedTestSeries)
        setSelectedTest2({ title: "Select Test" })
    }

    const selectTestHandler = (selectedTest) => {
        setSelectedTest(selectedTest)
    }
    const selectTestHandler2 = (selectedTest) => {
        setSelectedTest2(selectedTest)
    }



    const copyTestHandler = async () => {
        try {
            if (!selectedTestSeries.sn || !selectedTest.packageId || !selectedTestSeries2.sn || !selectedTest2.packageId) {
                toast.error("Please Select all the inputs")
                return;
            }
            let data = {
                data1: { testSeriesSn: selectedTestSeries.sn, packageId: selectedTest.packageId },
                data2: { testSeriesSn: selectedTestSeries2.sn, packageId: selectedTest2.packageId }
            }
            const resposne = await axios.post(BACKEND_SERVER_URL + '/copyTestSeriesQuestions', data);
            if (resposne.data.statusCode == 200) {
                toast.success(resposne.data.message)
                // setPackageIds({ copyFromPackageId: '', copyInPackageId: '' })
            }
        } catch (error) {
            toast.error(error.resposne.data.message)
        }

    }







    return (
        <>
            <div className="row" >
                <div className="col-xl-12">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className=" page-title p-2 mb-2">
                                <div className="col-12 ">
                                    <h4 className='text-center text-uppercase'>
                                        <span style={{ fontWeight: 'bold' }}>
                                            Test Sereis Tests
                                        </span>
                                    </h4>
                                </div>
                            </div>

                        </div>


                      
                            <div>
                                <div className="card h-auto mb-2">
                                    <div className="card-body p-1 pb-1">
                                        <Alert variant="white" className="solid alert-square mb-0" >
                                            <div className='text-primary d-flex justify-content-evenly align-items-center' >
                                                <div className="col-5 d-flex justify-content-between">
                                                    <div className="col-6" >
                                                        <div className="basic-dropdown" >
                                                            <Select
                                                                value={{ value: selectedTestSeries, label: selectedTestSeries.title }}
                                                                onChange={(selectedOption) => handleSelectTestSeries(selectedOption.value)}
                                                                options={testSeries?.map((testseries) => ({ value: testseries, label: testseries.title }))}
                                                                styles={setDropDownStyle()}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="basic-dropdown ms-1">
                                                            <Select
                                                                isDisabled={!selectedTestSeries.courseId}
                                                                value={{ value: selectedTest, label: selectedTest.title }}
                                                                onChange={(selectedOption) => selectTestHandler(selectedOption.value)}
                                                                options={testSeriesTests?.map((test) => ({ value: test, label: test.title }))}
                                                                styles={setDropDownStyle()}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <HiOutlineArrowNarrowRight size={35} color='' />
                                                <div className="col-5 d-flex justify-content-between">
                                                    <div className="col-6" >
                                                        <div className="basic-dropdown" >
                                                            <Select
                                                                value={{ value: selectedTestSeries2, label: selectedTestSeries2.title }}
                                                                onChange={(selectedOption) => handleSelectTestSeries2(selectedOption.value)}
                                                                options={testSeries?.map((testseries) => ({ value: testseries, label: testseries.title }))}
                                                                styles={setDropDownStyle()}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="basic-dropdown ms-1">
                                                            <Select
                                                                isDisabled={!selectedTestSeries2.courseId}
                                                                value={{ value: selectedTest2, label: selectedTest2.title }}
                                                                onChange={(selectedOption) => selectTestHandler2(selectedOption.value)}
                                                                options={testSeriesTests2?.map((test) => ({ value: test, label: test.title }))}
                                                                styles={setDropDownStyle()}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-1 '>
                                                    <ButtonMUI className='mt-0 pt-1' variant="" size='small' style={{ color: 'white', borderColor: '#4CAF50', backgroundColor: "#10415d" }} onClick={copyTestHandler} >SUBMIT</ButtonMUI>
                                                </div>

                                            </div>
                                        </Alert>
                                    </div>
                                </div>
                            </div>
                       


                    </div>
                </div>
                <div className="col-xl-12 mt-5">
                    <div className="row">
                        <div className="col-xl-12" style={{ zIndex: '0' }}>
                            <div className=" page-title p-2 mb-2">
                                <div className="col-12 ">
                                    <h4 className='text-center text-uppercase'>
                                        <span style={{ fontWeight: 'bold' }}>
                                            Course Lessons
                                        </span>
                                    </h4>
                                </div>
                            </div>

                        </div>
                        <div className="col-xl-12 wow fadeInUp" data-wow-delay="1.5s">
                            <div className="table-responsive full-data">
                                <div id="example-student_wrapper" className="dataTables_wrapper no-footer">
                                    <div>
                                        <div className="card h-auto mb-2">
                                            <div className="card-body p-1 pb-1">
                                                <Alert variant="white" className="solid alert-square mb-0">
                                                    <div className='text-primary d-flex justify-content-evenly align-items-center'>
                                                        <div className='col-sm-4'>
                                                            <input
                                                                type="text"
                                                                style={{ display: '' }}
                                                                placeholder='Copy from Section ID'
                                                                value={sectionIds.copyFromSectionId}
                                                                onChange={(e) => setSectionIds((prevData) => ({ ...prevData, copyFromSectionId: e.target.value }))}
                                                                className='form-control' />
                                                        </div>
                                                        <HiOutlineArrowNarrowRight size={35} color='' />
                                                        <div className='col-sm-4'>
                                                            <input
                                                                type="text"
                                                                style={{ display: '' }}
                                                                placeholder='Copy in Section ID'
                                                                value={sectionIds.copyInSectionId}
                                                                onChange={(e) => setSectionIds((prevData) => ({ ...prevData, copyInSectionId: e.target.value }))}
                                                                className='form-control' />
                                                        </div>
                                                        <div className=''>
                                                            <ButtonMUI className='mt-0 pt-1' variant="" size='small' style={{ color: 'white', borderColor: '#4CAF50', backgroundColor: "#10415d" }} onClick={copyLessonHandler} >SUBMIT</ButtonMUI>
                                                        </div>

                                                    </div>
                                                </Alert>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ModerateSolution;



