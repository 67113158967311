import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import NotificationModal from './NotificationModal'
import { MdDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { BiEdit } from "react-icons/bi";
import swal from 'sweetalert';
import axios from 'axios';
import { toast } from 'react-toastify';
import { BACKEND_SERVER_URL } from '../../../http/http-config';



const Notifications = () => {
  const childRef = useRef();
  const [notifications, setCourses] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [checked, setChecked] = useState(notifications);
  const [unchecked, setUnChecked] = useState(true);
  const [loadNotifications, setNotificationsLoading] = useState(false);
  const [totalActiveNotifications, setTotalActiveNotifications] = useState(0);
  const [totalInactiveNotifications, setTotalInactiveNotifications] = useState(0);
  const [totalArchivedNotifications, setTotalArchivedNotifications] = useState(0);
  console.log(unchecked)
  // search functionality    
  const [searchTerm, setSearchTerm] = useState('');
  useEffect(() => {
    const filteredNotifications = notifications?.filter(
      item => item?.notificationTitle?.toLowerCase().includes(searchTerm?.toLowerCase())
    );
    setChecked(filteredNotifications)
  }, [searchTerm])
  const handleSearch = (e) => {
    setSearchTerm(e.target.value)
  }

  //set Table datainputchecked
  useEffect(() => {
    axios.get(BACKEND_SERVER_URL + '/getNotifications').then((res) => {
      let notifications = res.data.notifications;
      console.log(notifications)
      if (notifications) {
        setTotalActiveNotifications(notifications.filter(course => course.status == 1 && course.hide == 0).length)
        setTotalInactiveNotifications(notifications.filter(course => course.status == 0 && course.hide == 0).length)
        setTotalArchivedNotifications(notifications.filter(course => course.hide == 1).length)
        notifications = notifications.filter(course => course.hide == 0)
        setCourses(notifications);
        setChecked(notifications);
      }
    })
  }, [loadNotifications])

  const handleChecked = (id) => {
    let temp = checked.map((data) => {
      if (id === data.id) {
        return { ...data, inputchecked: !data.inputchecked };
      }
      return data;
    });
    setChecked(temp);
  };

  const handleCheckedAll = (value) => {
    let temp = checked.map((data) => {
      return { ...data, inputchecked: value };
    });
    setChecked(temp);
    setUnChecked(!unchecked);
  };

  const pageSize = 15;
  const lastIndex = currentPage * pageSize;
  const firstIndex = lastIndex - pageSize;
  const records = checked.slice(firstIndex, lastIndex);

  const npage = Math.ceil(checked.length / pageSize)
  const number = [...Array(npage + 1).keys()].slice(1)


  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1)
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1)
    }
  }
  //handle delete course
  const handleDeleteNotification = (notificationId) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this notification",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axios.delete(BACKEND_SERVER_URL + '/deleteNotification/' + notificationId).then((res) => {
          if (res) {
            swal("Poof! Your notification has been deleted!", {
              icon: "success",
            });
            setNotificationsLoading(!loadNotifications);
          }
        })
      }
    })
  }

  const getSn = (index) => {
    return notifications.length - index - ((currentPage - 1) * pageSize);
  }


  const handleNotificationSave = async (notificationTitle, notificationDetails, selectedBatchId, id = 0) => {
    console.log(notificationTitle, notificationDetails, selectedBatchId, id)
    try {
      if (id <= 0) {
        const res = await axios.post(BACKEND_SERVER_URL + '/createNotification', { notificationTitle, notificationDetails, batchId: selectedBatchId });
        if (res?.data) {
          toast.success(res.data.message);
          setNotificationsLoading(!loadNotifications);
        }
      } else {
        const res = await axios.put(BACKEND_SERVER_URL + '/updateNotification/' + id, { notificationTitle, notificationDetails, batchId: selectedBatchId });
        if (res?.data) {
          toast.success(res.data.message);
          setNotificationsLoading(!loadNotifications);
        }
      }

    } catch (error) {
      console.error("An error occurred during the request:", error.message);

    }
  }
  const resetHeaderCheckBox = () => {
    document.querySelectorAll('input[name="Checkall"]').forEach((input) => {
      input.checked = false;
    });
  }

  //common bulk handler
  const commonHandler = (action) => {
    let ids = records.filter(record => record.inputchecked).map(record => record.id);
    if (ids.length <= 0) {
      toast.error('Please select atleast one notification');
      return;
    }
    const postData = { ids: ids };
    switch (action) {
      case "deleteBulk":
        swal({
          title: "Are you sure?",
          text:
            "Once deleted, you will not be able to recover these notifications",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            axios.post(BACKEND_SERVER_URL + '/deleteNotifications', postData).then((response) => {
              if (response) {
                toast.success(response.data.message);
                setNotificationsLoading(!loadNotifications)
                setUnChecked(!unchecked)

              }
            }).catch((error) => {
              toast.error(error.message);
            });

          }
        })
        break;
      case "activateBulk":
        swal({
          title: "Are you sure?",
          text:
            "Do you really want to activate these notifications",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            axios.post(BACKEND_SERVER_URL + '/activateNotifications', postData).then((response) => {
              if (response) {
                toast.success(response.data.message);
                setNotificationsLoading(!loadNotifications)
                setUnChecked(!unchecked)

              }
            }).catch((error) => {
              toast.error(error.message);
            });

          }
        })
        break;
      case "InActivateBulk":
        swal({
          title: "Are you sure?",
          text:
            "Do you really want to inactivate these notifications",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            axios.post(BACKEND_SERVER_URL + '/inActivateNotifications', postData).then((response) => {
              if (response) {
                toast.success(response.data.message);
                setNotificationsLoading(!loadNotifications)
                setUnChecked(!unchecked)

              }
            }).catch((error) => {
              toast.error(error.message);
            });

          }
        })
        break;
    }
  }
  const handleTabClick = (action) => {
    if (action === "activeTab") {
      let active = notifications.filter(course => course.status == 1 && course.hide == 0)
      setChecked(active)

    } else if (action === "inActiveTab") {
      let inactive = notifications.filter(course => course.status == 0 && course.hide == 0);
      setChecked(inactive)

    } else {
      setChecked(notifications)
    }
  }

  return (
    <>

      <div className="row mt-0">
        <div className="col-xl-12">
          <div className="row">
            <div className="col-xl-12">
              <div className=" page-title p-2 mb-2">{/*  flex-wrap  */}
                <div className='d-flex justify-content-between'>
                  <button type="button" className="btn btn-dark btn-sm"
                    onClick={() => childRef.current.openModal("add course")}
                  >
                    + Add Notification
                  </button>

                </div>
                <div className="input-group search-area mb-md-0 mb-3">

                  <input type="text" className="form-control" onChange={handleSearch} value={searchTerm} placeholder="Search here..." />
                  <span className="input-group-text"><Link to={"#"}>
                    <svg width="15" height="15" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z" fill="#01A3FF" />
                    </svg>
                  </Link></span>
                </div>
              </div>

              <div className='page-title pt-1 mb-1 pb-2'>
                <div className="container-fluid p-1 pb-0">
                  <div className="row ">
                    <div className="col-12 col-sm-6 d-sm-flex ">
                      <div className="col-12 col-sm-6 mb-3 mb-sm-0 ">
                        <button
                          onClick={() => handleTabClick('allTests')}
                          type="button"
                          className="btn btn-primary btn-sm btn-block m-auto ps-0 pe-3 w-75"
                        >
                          All Notifications: {notifications.length}
                        </button>
                      </div>
                      <div className="col-12 col-sm-6  ">
                        <button
                          onClick={() => handleTabClick('activeTab')}
                          type="button"
                          className="btn btn-success btn-sm btn-block m-auto ps-0 pe-3 w-75"
                        >
                          Active: {totalActiveNotifications}
                        </button>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 d-sm-flex mt-3 mt-sm-0 ">
                      <div className="col-12 col-sm-6 mb-3 mb-sm-0 ">
                        <button
                          onClick={() => handleTabClick('inActiveTab')}
                          type="button"
                          className="btn btn-warning btn-block btn-sm  m-auto ps-0 w-75"
                        >
                          Inactive: {totalInactiveNotifications}
                        </button>
                      </div>
                      <div className="col-12 col-sm-6 ">
                        <button
                          type="button"
                          className="btn btn-danger btn-block btn-sm  m-auto ps-0 w-75"
                        >
                          Archive: {totalArchivedNotifications}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12 wow fadeInUp" data-wow-delay="1.5s">
              <div className="table-responsive full-data">
                <div id="example-student_wrapper" className="dataTables_wrapper no-footer">

                  <div className="d-sm-flex text-center justify-content-between align-items-center">
                    <div>
                      <Button variant="danger ms-3 " size='xs' onClick={(e) => commonHandler("deleteBulk")}>Delete</Button>
                      <Button variant="success ms-3 " size='xs' onClick={(e) => commonHandler("activateBulk")}>Active</Button>
                      <Button variant="warning ms-3" size='xs' onClick={(e) => commonHandler("InActivateBulk")}>Inactive</Button>
                    </div>
                    <div className='dataTables_info text-dark'>
                      Records: {records.length}
                    </div>
                    <div className="dataTables_paginate paging_simple_numbers justify-content-center  mb-0 pt-2"
                      id="example-student_wrapper">
                      <Link
                        className="paginate_button previous disabled"
                        to="#"
                        onClick={prePage}
                        style={{ fontSize: "5px" }}
                      >
                        <i className="fa-solid fa-angle-left" />
                      </Link>
                      <span >
                        {number.map((n, i) => (
                          <Link className={`paginate_button ${currentPage === n ? 'current' : ''}  rounded-circle  `} key={i}
                            onClick={() => changeCPage(n)}
                          >
                            <span style={{ fontSize: "14px", display: "flex", justifyContent: "center", alignItems: "center" }} >{n}</span>

                          </Link>
                        ))}
                      </span>
                      <Link
                        className="paginate_button next"
                        to="#"
                        onClick={nextPage}
                      >
                        <i className="fa-solid fa-angle-right" />
                      </Link>
                    </div>
                  </div>


                  <table className="table-responsive-lg table display dataTablesCard student-tab 
                                    dataTable no-footer mt-0   " id="example-student" >
                    <thead style={{ backgroundColor: "#cccccc" }} className='header-color '>

                      <tr className='text-center border border-left' >
                        <th className='text-dark p-2'>SN</th>
                        <th className='text-dark p-2'>
                          <input type="checkbox" className="form-check-input" id="checkAll" name='Checkall'
                            checked={!unchecked}
                            onClick={() => handleCheckedAll(unchecked)}
                          />
                        </th>
                        <th className=' text-dark p-2 text-start'>STATUS</th>
                        <th className='text-dark text-start p-2'>Title</th>
                        <th className='text-dark text-start p-2'>Details</th>
                        <th className=' text-dark text-start p-2'>BATCH</th>
                        {/* <th className='text-dark p-2'>DATE</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {records.length > 0 ? records.map((item, ind) => (
                        <tr key={ind} className={`${ind % 2 == 1 ? 'custom' : 'row-height'}`}>
                          <td className='text-center p-1 ' style={{ border: "1px solid #cccccc", width: "65px" }}><h6 className="text-primary mb-0"> {getSn(ind)}</h6></td>
                          <td className='text-center p-1' style={{ border: "1px solid #cccccc", width: "65px" }}>
                            <div className="checkbox me-0 align-self-center">
                              <div className="custom-control custom-checkbox">
                                <input type="checkbox"
                                  className="form-check-input"
                                  id={`stud-${item.id}`}
                                  checked={item.inputchecked}
                                  onChange={() => handleChecked(item.id)}
                                />
                                <label className="custom-control-label" htmlFor={`stud-${item.id}`}></label>
                              </div>
                            </div>
                          </td>
                          <td className='text-start p-1 ' style={{ border: "1px solid #cccccc", width: "80px" }}>
                            {item.status == 1 && <h6 className="text-success mb-0">Active </h6>}
                            {item.status == 0 && <h6 className="text-danger mb-0">Inactive </h6>}
                          </td>

                          <td className='p-1' style={{ border: "1px solid #cccccc" }}>
                            <div className="">
                              {/* <img src={item.image} alt="" className="avatar avatar-sm me-3" /> */}
                              <h6 style={{ fontSize: '13px', fontWeight: 'bold' }} className='mb-0 '>{item.notificationTitle}{' '}
                                <CiEdit cursor='pointer' title='Edit Course' size={18} className='ms-3' onClick={() => childRef.current.openModal(item)} />
                                <MdDeleteOutline color='red' title='Delete Notification' cursor='pointer' size={18} className='ms-2' onClick={() => { handleDeleteNotification(item.id) }}
                                />
                              </h6>
                            </div>
                          </td>
                          {/*   <td className='p-1' style={{ border: "1px solid #cccccc" }}>
                            <div className="d-flex justify-content-between ">
                              <h6 className={`mb-0 ${item.batches == '' ? 'text-light' : ''}`}
                              > {item.batches == '' ? 'No batch' : item.batches.split(',').map(Number).sort((a, b) => a - b).join(',')}

                              </h6>
                              <h6>
                                <BiEdit cursor='pointer' title='Edit Batches' size={18} className='ms-3 text-dark' onClick={() => childRef.current.openModal("batch_mapping", item)} />
                              </h6>
                            </div>
                          </td> */}
                          <td className='p-1' style={{ border: "1px solid #cccccc" }}><h6 className="mb-0">{item.notificationDetails}</h6></td>
                          <td className='p-1' style={{ border: "1px solid #cccccc", width: "175px" }}>
                            <h6 className="date mb-0">{item.batchName}</h6>
                          </td>
                          {/*  <td className='text-center p-1' style={{ border: "1px solid #cccccc", width: "175px" }}>
                            <h6 className="date mb-0">{item.createdOn}</h6>
                          </td> */}

                        </tr>
                      )) :
                        <tr>
                          <td colSpan="9" className="text-center">
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                              <Spinner animation="grow" variant="info" />
                            </div>
                          </td>
                        </tr>}
                    </tbody>
                  </table>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NotificationModal ref={childRef} saveCallBack={handleNotificationSave} loadCourses={loadNotifications} setCourseLoading={setNotificationsLoading} />
    </>
  )
}

export default Notifications;
