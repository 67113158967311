import React, { Fragment, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'
import CourseSampleFile from '../../../download_files/course_sample_file.docx'
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import { CKEditor } from '@ckeditor/ckeditor5-react';

import Input from '../Common-Components/Input';
import FileInput from '../Common-Components/FileInput';
import DropdownInput from '../Common-Components/DropdownInput'
import BatchInput from '../Course/BatchInput';
import { fetchCourseData, formateCourseData, generateUniqueID, getExamTypes, getFaculties, getTypes, validateCourseData } from '../Course/CourseHelper';
import DateInput from '../Common-Components/DateInput';
import '../Course/Course.css'
import CKEditorInput from '../Common-Components/CKEditorInput';
import { fetchTestData, formateTestData, validateTestData } from '../TestManagement/TestHelper';
import { setDropDownStyle, validateQuizQuestionData } from '../CommonHelper';
import CourseAndSubjectDropDown from '../Common-Components/CourseAndSubjectDropDown';
import SubjectAndSectionDropdown from './SubjectAndSectionDropdown';

let initialFormData = {
    question: '',
    option1: '',
    option2: '',
    option3: '',
    option4: '',
    titleURL: '',
    difficultyLevel: 0,
    subjectId: 0,
    sectionId: 0,
    tags: '',
    correctAnswer: 'Select Correct Answer',
    description: '',
    quizId: '',
}
const QuizQuestionBankModal = forwardRef((props, ref) => {
    const [modalBox, setModalBox] = useState(false);
    const [updatingQuestionId, setUpdatingQuestionId] = useState(0);

    const [formData, setFormData] = useState(initialFormData);
    const [updatingDataReady, setUpdatingDataReady] = useState(false);
  
    useImperativeHandle(ref, () => ({
        openModal(questionData = {}) { 
                  
            setModalBox(true);
            if (questionData.sn) {
                setUpdatingQuestionId(questionData.sn)
                setFormData(questionData)
                setUpdatingDataReady(true)
            } else {
                let quizId = generateUniqueID();
                handleChange('quizId', quizId)//property name and value               
            }
        }
    }));

    const handleChange = (name, value) => {
        
        setFormData((previousData) => ({
            ...previousData,
            [name]: value
        }));
    };
    // console.log(formData)
    const handleSubmitTest = () => {
        let errorMessgae = validateQuizQuestionData(formData)
       
        if (errorMessgae) {
            toast.error(errorMessgae)
            return;
        }
        axios.post(BACKEND_SERVER_URL + (updatingQuestionId ? `/updateQuizQuestion` : '/saveQuizQuestion'), formData).then((response) => {
            if (response.data.statusCode == 200) {
                setFormData(initialFormData)
                toast.success(response.data.message)
                props.setLoadQuestions(!props.loadQuestions)
                setModalBox(false);
            }
        }).catch((error) => {
            toast.error(error.response.data.message)
            console.log(error)
        })

    }
    useEffect(() => {
        if (!modalBox) {
            setUpdatingQuestionId(0);
            setFormData(initialFormData); // Reset form data when modal is closed
        }
    }, [modalBox]);

    return (
        <>
            {((updatingQuestionId && updatingDataReady && modalBox) || (!updatingQuestionId && modalBox)) && (<Modal className="" show={modalBox} onHide={setModalBox} dialogClassName="custom-modal-width" backdrop="static"
                keyboard={false} >
                <Modal.Header>
                    <Modal.Title>{updatingQuestionId ? 'Update Question' : 'Create Question'}</Modal.Title>
                    <Button variant="" className="btn-close" onClick={() => setModalBox(false)}></Button>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-around ">
                                <div className="col-5 d-flex">
                                    <Input inputType="text" handleChangeCallBack={handleChange} labelName="Quiz ID" inputValue={formData?.quizId} inputName='quizId' divSizes={[4, 8]} />
                                </div>
                                <div className="col-5 d-flex">
                                    <Input inputType="text" handleChangeCallBack={handleChange} labelName="Difficulty Level" inputValue={formData?.difficultyLevel} inputName='difficultyLevel' divSizes={[4, 8]} />
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-around ">
                                <SubjectAndSectionDropdown setFormData={setFormData} formData={formData} subjectId={formData.subjectId} sectionId={formData.sectionId} />
                            </div>
                            <div className="col-12 d-flex justify-content-around ">
                                <div className="col-5 d-flex">
                                    <Input inputType="text" handleChangeCallBack={handleChange} labelName="Title URL" inputValue={formData?.titleURL} inputName='titleURL' divSizes={[4, 8]} />
                                </div>
                                <div className="col-5 d-flex">
                                    <DropdownInput handleChangeCallBack={handleChange} labelName="Correct Answer" selectedValue={formData?.correctAnswer} inputName='correctAnswer' dropdownValuesArray={['A', 'B', 'C', 'D']} />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <CKEditorInput handleChangeCallBack={handleChange} labelName="Question Content" inputValue={formData?.question} inputName='question' />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <CKEditorInput handleChangeCallBack={handleChange} labelName="Option (A)" inputValue={formData?.option1} inputName='option1' />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <CKEditorInput handleChangeCallBack={handleChange} labelName="Option (B)" inputValue={formData?.option2} inputName='option2' />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <CKEditorInput handleChangeCallBack={handleChange} labelName="Option (C)" inputValue={formData?.option3} inputName='option3' />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <CKEditorInput handleChangeCallBack={handleChange} labelName="Option (D)" inputValue={formData?.option4} inputName='option4' />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <CKEditorInput handleChangeCallBack={handleChange} labelName="Description" inputValue={formData?.description} inputName='description' />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <Input inputType="text" handleChangeCallBack={handleChange} labelName="Tags" inputValue={formData?.tags} inputName='tags' divSizes={[2, 10]} />
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger light" className='btn-sm' onClick={() => setModalBox(false)}>Close</Button>
                    <Button variant="primary " className='btn-sm ' onClick={handleSubmitTest}>{updatingQuestionId ? 'Update Question' : 'Create Question'}</Button>
                </Modal.Footer>
            </Modal>)
            }

        </>
    )
})
export default QuizQuestionBankModal;