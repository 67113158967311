import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Badge, Button, Spinner } from 'react-bootstrap';

import { MdDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { MdKeyboardArrowUp } from "react-icons/md";
import swal from 'sweetalert';
import axios from 'axios';
import { toast } from 'react-toastify';
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import DatePicker from 'react-datepicker';
import { BiEdit } from "react-icons/bi";
import Select from 'react-select';
import BlogsModal from './BlogsModal';
import { setDropDownStyle } from '../Batch-Management/BatchHelper';
import { Pagination } from '@mui/material';
import { IoSearchSharp } from "react-icons/io5";
import { format } from 'date-fns';
import { getBlogCategories } from '../CommonHelper';
import { showTableResponse, sortInAlphabeticalOrder } from '../common-helpers/commonServices';

const Blogs = () => {
    const childRef = useRef();
    const navigate = useNavigate();
    const [blogs, setBlogs] = useState([]);
    const [categoryWiseBlogs, setCategoryWiseBlogs] = useState([]);
    const [blogCategories, setBlogCategories] = useState([]);

    const [selectedCategory, setSelectedCategory] = useState({ title: "All Categories" });
    const [selectedPublicStatus, setPublicStatus] = useState("Select Publish Status ");

    const [unAssociatedBatches, setUnassociatedBatches] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [checked, setChecked] = useState(blogs);
    const [unchecked, setUnChecked] = useState(true);
    const [loadBlogs, setBlogsLoading] = useState(false);
    const [fileterDate, setFileterDate] = useState({ startDate: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"), endDate: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'") });


    // search functionality    
    const [searchTerm, setSearchTerm] = useState('');
    useEffect(() => {
        const filteredBatches = blogs?.filter(
            item => item?.title?.toLowerCase().includes(searchTerm?.toLowerCase())
        );
        setChecked(filteredBatches)
    }, [searchTerm])
    const handleSearch = (e) => {
        setSearchTerm(e.target.value)
    }

    //set Table data
    useEffect(() => {
        axios.get(BACKEND_SERVER_URL + '/getBlogs').then((res) => {
            let blogs = res.data.blogs;
            let dates = blogs.map(blog => new Date(blog.pulishedDate))
            const oldestDate = new Date(Math.min(...dates));
            setFileterDate({ ...fileterDate, startDate: format(oldestDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'") })
            if (blogs) {
                setBlogs(blogs);
                setChecked(blogs);
            }
        }).catch((error) => {
            console.log(error)
            toast.error(error.response.data.message);
        })
        getBlogCategories().then(res => {
            res.unshift({ title: "All Categories" })
            setBlogCategories(res);
        })
    }, [loadBlogs])

    const handleChecked = (sn) => {
        let temp = checked.map((data) => {
            if (sn === data.sn) {
                return { ...data, inputchecked: !data.inputchecked };
            }
            return data;
        });
        setChecked(temp);
    };
    const handleCheckedAll = (value) => {
        let temp = checked.map((data) => {
            return { ...data, inputchecked: value };
        });
        setChecked(temp);
        setUnChecked(!unchecked);
    };

    const pageSize = 50;
    const lastIndex = currentPage * pageSize;
    const firstIndex = lastIndex - pageSize;
    const records = checked?.slice(firstIndex, lastIndex);
    const npage = Math.ceil(checked.length / pageSize)
    const number = [...Array(npage + 1).keys()].slice(1)
    //handle delete course
    const handleDeleteBatches = (courseId) => {
        swal({
            title: "Are you sure?",
            text:
                "Once deleted, you will not be able to recover this batch",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                axios.delete(BACKEND_SERVER_URL + '/deleteBatch/' + courseId).then((res) => {
                    if (res) {
                        swal("Poof! Your batch has been deleted!", {
                            icon: "success",
                        });
                        setBlogsLoading(!loadBlogs);
                    }
                }).catch((error) => {
                    console.log(error)
                    toast.error(error.response.data.message);
                })
            }
        })
    }



    //common bulk handler
    const commonHandler = (action) => {
        let ids = records.filter(record => record.inputchecked).map(record => record.sn);
        if (ids.length <= 0) {
            toast.error('Please select atleast one blog');
            return;
        }
        const postData = { ids: ids };
        switch (action) {
            case "deleteBulk":
                swal({
                    title: "Are you sure?",
                    text:
                        "Once deleted, you will not be able to recover these blogs",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                }).then((yes) => {
                    if (yes) {
                        axios.post(BACKEND_SERVER_URL + '/deleteBlogs', postData).then((response) => {
                            if (response) {
                                toast.success(response.data.message);
                                setUnChecked(!unchecked)
                                setBlogsLoading(!loadBlogs)
                            }
                        }).catch((error) => {
                            console.log(error)
                            toast.error(error.response.data.message);
                        })

                    }
                })
                break;
            case "Publish":
                swal({
                    title: "Are you sure?",
                    text:
                        "Do you really want to publish these blogs",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                }).then((yes) => {
                    if (yes) {
                        axios.post(BACKEND_SERVER_URL + '/publishBlogs', postData).then((response) => {
                            if (response) {
                                toast.success(response.data.message);
                                setBlogsLoading(!loadBlogs)
                                setUnChecked(!unchecked)
                            }
                        }).catch((error) => {
                            console.log(error)
                            toast.error(error.response.data.message);
                        })

                    }
                })
                break;
            case "UnPublish":
                swal({
                    title: "Are you sure?",
                    text:
                        "Do you really want to UnPublish these blogs",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                }).then((yes) => {
                    if (yes) {
                        axios.post(BACKEND_SERVER_URL + '/unPublishBlogs', postData).then((response) => {
                            if (response) {
                                toast.success(response.data.message);
                                setBlogsLoading(!loadBlogs)
                                setUnChecked(!unchecked)
                            }
                        }).catch((error) => {
                            console.log(error)
                            toast.error(error.response.data.message);
                        })

                    }
                })
                break;
        }
    }

    const selectCategoryHandler = (value) => {
        console.log(value)
        setSelectedCategory(value)
        if (value.title == "All Categories") {
            setChecked(blogs)
            setCategoryWiseBlogs(blogs)
            return;
        }
        let filteredBlogs = blogs.filter(blog => blog.categoryId == value.sn)
        setChecked(sortInAlphabeticalOrder(filteredBlogs, 'title'))
        setCategoryWiseBlogs(filteredBlogs)

    }
    const selectPublishHandler = (value) => {
        setPublicStatus(value)
        if (value == "All Blogs") {
            setChecked((categoryWiseBlogs.length > 0 ? categoryWiseBlogs : blogs))
            return;
        }
        if (value == "Published") {
            setChecked((categoryWiseBlogs.length > 0 ? categoryWiseBlogs : blogs).filter(blog => blog.published?.toLowerCase() == 'yes'))
            return;
        }
        if (value == "UnPublished") {

            setChecked((categoryWiseBlogs.length > 0 ? categoryWiseBlogs : blogs).filter(blog => blog.published && blog.published.toLowerCase() == 'no'))
            return;
        }
    }

    const handleSearchDateWise = () => {
        let filteredBlogs = blogs.filter(blog => blog.pulishedDate >= fileterDate.startDate && blog.pulishedDate <= fileterDate.endDate)
        console.log(filteredBlogs)
        setChecked(filteredBlogs);
    }

    const handlePageChange = (e, p) => {
        setCurrentPage(parseInt(p));
    }


    return (
        <>
            <div className="row">
                <div className="col-xl-12">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className=" page-title p-2 mb-2">{/*  flex-wrap  */}
                                <div className=' col-8 d-flex justify-content-between'>
                                    <div className="col-sm-2">
                                        <button type="button" className="btn btn-dark btn-sm me-2"
                                            onClick={() => childRef.current.openModal()}
                                        >
                                            + Add Blogs
                                        </button>
                                    </div>
                                    <div className="col-sm-5">
                                        <div className="basic-dropdown">
                                            <Select
                                                value={{ value: selectedCategory.title, label: selectedCategory.title }}
                                                onChange={(selectedOption) => selectCategoryHandler(selectedOption.value)}
                                                options={blogCategories?.map((category) => ({ value: category, label: category.title }))}
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        width: '90%',
                                                        backgroundColor: 'transparent',
                                                        borderColor: '#17a2b8',
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-5">
                                        <div className="basic-dropdown">
                                            <Select
                                                value={{ value: selectedPublicStatus, label: selectedPublicStatus }}
                                                onChange={(selectedOption) => selectPublishHandler(selectedOption.value)}
                                                options={["All Blogs", "Published", "UnPublished"].map((publish) => ({ value: publish, label: publish }))}
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        width: '90%',
                                                        backgroundColor: 'transparent',
                                                        borderColor: '#17a2b8',
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>

                                </div>

                                <div className="input-group search-area mb-md-0 mb-3">
                                    <input type="text" className="form-control" onChange={handleSearch} value={searchTerm} placeholder="Search here..." />
                                    <span className="input-group-text"><Link to={"#"}>
                                        <svg width="15" height="15" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z" fill="#01A3FF" />
                                        </svg>
                                    </Link></span>
                                </div>
                            </div>

                            {/*   <div className='page-title pt-1 mb-1 pb-2' style={{ zIndex: "0" }}>
                                <div className="container-fluid p-1 pb-0">
                                    <div className="row">
                                        <div className="col-12 col-sm-6 d-sm-flex ">
                                            <div className="col-12 col-sm-6 mb-3 mb-sm-0 ">
                                                <button
                                                    onClick={() => handleTabClick('allBatches')}
                                                    type="button"
                                                    className="btn btn-primary btn-sm btn-block m-auto ps-0 pe-3 w-75"
                                                >
                                                    All Batches: {blogs.length}
                                                </button>
                                            </div>
                                            <div className="col-12 col-sm-6  ">
                                                <button
                                                    onClick={() => handleTabClick('activeTab')}
                                                    type="button"
                                                    className="btn btn-success btn-sm btn-block m-auto ps-0 pe-3 w-75"
                                                >
                                                    Active: {totalActiveBatches}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 d-sm-flex mt-3 mt-sm-0 ">
                                            <div className="col-12 col-sm-6 mb-3 mb-sm-0 ">
                                                <button
                                                    onClick={() => handleTabClick('inActiveTab')}
                                                    type="button"
                                                    className="btn btn-warning btn-block btn-sm  m-auto ps-0 w-75"
                                                >
                                                    Inactive: {totalInactiveBatches}
                                                </button>
                                            </div>
                                            <div className="col-12 col-sm-6 ">
                                                <button
                                                    onClick={() => handleTabClick('unAssociatedTab')}
                                                    type="button"
                                                    className="btn btn-danger btn-block btn-sm  m-auto ps-0 w-75"
                                                >
                                                    Archived: {unAssociatedBatches?.length}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div className="col-xl-12 wow fadeInUp" data-wow-delay="1.5s">
                            <div className="col-xl-10 d-sm-flex text-center justify-content-around align-items-center">
                                <div className="m-2 d-flex " /* style={{ width: '30%' }} */ >
                                    <label className="form-label  text-dark mt-2">Publish Start Date<span className="required">*</span></label>
                                    <DatePicker
                                        className="form-control full-width full-width-container .react-datepicker-wrapper"
                                        selected={new Date(fileterDate.startDate)}
                                        // showTimeSelect
                                        // timeFormat="HH:mm"
                                        onChange={(date) => setFileterDate({ ...fileterDate, startDate: format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'") })}
                                        //timeIntervals={15}
                                        yearDropdownItemNumber={20}
                                        dateFormat="dd/MM/yyyy"
                                        //  timeCaption="Time"
                                        showYearDropdown
                                        scrollableYearDropdown
                                        todayButton="Today"

                                    />
                                </div>
                                <div className="m-2 d-flex" /* style={{ width: '30%' }} */ >
                                    <label className="form-label text-dark mt-2">Publish End Date<span className="required">*</span></label>
                                    <DatePicker
                                        className="form-control full-width full-width-container .react-datepicker-wrapper"
                                        selected={new Date(fileterDate.endDate)}
                                        //showTimeSelect
                                        //  timeFormat="HH:mm"
                                        onChange={(date) => setFileterDate({ ...fileterDate, endDate: format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'") })}
                                        //timeIntervals={15}
                                        yearDropdownItemNumber={20}
                                        dateFormat="dd/MM/yyyy"
                                        // timeCaption="Time"
                                        showYearDropdown
                                        scrollableYearDropdown
                                        todayButton="Today"

                                    />
                                </div>
                                <div className="m-2">
                                    <button type="button" className="btn btn-dark btn-sm" onClick={handleSearchDateWise}
                                    >
                                        <IoSearchSharp size={18} color='white' /> Search
                                    </button>
                                </div>


                            </div>
                        </div>
                        <div className=" d-sm-flex text-center justify-content-between align-items-center">
                            <div>
                                <Button variant="danger ms-3" size='xs' onClick={(e) => commonHandler("deleteBulk")}>Delete</Button>
                                <Button variant="success ms-3" size='xs' onClick={(e) => commonHandler("Publish")}>Publish</Button>
                                <Button variant="warning ms-3" size='xs' onClick={(e) => commonHandler("UnPublish")}>UnPublish</Button>
                            </div>

                            <div className='dataTables_info text-dark'>
                                Records: {checked.length}
                            </div>
                            <div className=" col-7 dataTables_paginate paging_simple_numbers d-flex justify-content-end align-items-center  mb-0 pt-2"
                                id="example-student_wrapper">
                                {/*   <div className="col-4 d-flex justify-content-end">
                                                <label htmlFor="jumpPage" className='me-2 mt-2'>  Jump to Page</label>
                                                <input className='form-control p-1' type="number" name="" id="jumpPage" style={{ width: "30%" }} min="1" max={number.length} value={currentPage} onChange={(e) => setCurrentPage(parseInt(e.target.value))} />
                                            </div> */}
                                <Pagination count={number.length} variant="outlined" color='primary' size="small" page={currentPage} siblingCount={1} boundaryCount={2} onChange={handlePageChange} />
                            </div>
                        </div>
                        <div className="table-responsive full-data" style={{ paddingBottom: "1px", transform: 'rotateX(180deg)' }}>
                            <div className="dataTables_wrapper no-footer">

                                <div id="example-student_wrapper" className="dataTables_wrapper no-footer">

                                    <table className="table-responsive-lg table display dataTablesCard student-tab 
                                    dataTable no-footer mt-0 mb-0" style={{ transform: 'rotateX(180deg)' }} id="example-student" >
                                        <thead style={{ backgroundColor: "#cccccc" }}>
                                            <tr className='text-center text-dark border border-left'>
                                                <th className=' custom-table-header  p-2'>SN</th>
                                                <th className=' custom-table-header  p-2'>
                                                    <input type="checkbox" className="form-check-input" id="checkAll"
                                                        //checked={!unchecked}
                                                        onClick={() => handleCheckedAll(unchecked)}
                                                    />
                                                </th>
                                                <th className='p-2  custom-table-header text-start '>TITLE</th>
                                                <th className=' text-start custom-table-header  p-2' >CATEGORY</th>
                                                <th className='p-2 custom-table-header '>TOTAL VIEWS</th>
                                                <th className='p-2 custom-table-header '>TOTAL LIKES</th>
                                                <th className='p-2 custom-table-header '>PUBLISH DATE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {records.length > 0 ? records.map((item, ind) => (
                                                <tr key={ind} className={`${ind % 2 == 1 ? 'custom row-height' : 'row-height'}`} >
                                                    <td className='text-center p-1 column-width' style={{ border: "1px solid #cccccc", width: "65px" }} ><h6 className="text-primary  mb-0"> {item.sn}</h6></td>
                                                    <td className='text-center p-1 column-width' style={{ border: "1px solid #cccccc", width: "65px" }}>
                                                        <div className="checkbox me-0 align-self-center">
                                                            <div className="custom-control custom-checkbox ">
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    id={`stud-${item.sn}`}
                                                                    checked={item.inputchecked}
                                                                    onChange={() => handleChecked(item.sn)}
                                                                />
                                                                <label className="custom-control-label" htmlFor={`stud-${item.sn}`}></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    {/*  <td className='text-center p-1 active-col-width' style={{ border: "1px solid #cccccc", width: "80px" }}>
                                                        {item.status == 'ACTIVE' && <h6 className="text-success mb-0 ">Active </h6>}
                                                        {item.status == 'Disactive' && <h6 className="text-danger mb-0">Inactive </h6>}
                                                    </td> */}
                                                    <td className='p-1' style={{ border: "1px solid #cccccc" }}>
                                                        <div className="d-flex justify-content-between ">
                                                            <h6 className='mb-0 ' style={{ fontSize: '13px', fontWeight: 'bold' }}>{item.title}{' '}
                                                                <CiEdit cursor='pointer' title='Edit Blogs' size={18} className='ms-3' onClick={() => childRef.current.openModal(item.sn)} />
                                                                <MdDeleteOutline color="red" title='Delete Blogs' cursor='pointer' size={18} className='ms-2' onClick={() => { handleDeleteBatches(item.id) }}
                                                                />
                                                            </h6>
                                                        </div>
                                                    </td>
                                                    <td className='p-1 active-col-width' style={{ border: "1px solid #cccccc" }}><h6 className="mb-0 ">{item.category}</h6></td>
                                                    <td className='p-1 text-center' style={{ border: "1px solid #cccccc", width: "65px" }}>
                                                        <h6 className="mb-0 cursor-pointer" > {item.preViews || 0} + {item.actualViews || 0} </h6>
                                                    </td>
                                                    <td className='p-1 text-center' style={{ border: "1px solid #cccccc", width: "65px" }}>
                                                        <h6 className="mb-0 cursor-pointer" > {item.preLikes || 0} + {item.actualLikes || 0} </h6>
                                                    </td>
                                                    <td className='text-center p-1' style={{ border: "1px solid #cccccc", width: "65px" }}><h6 className="mb-0">{new Date(item?.pulishedDate?.replace(/\s+/g, ""))?.toLocaleDateString('en-GB')}</h6></td>

                                                </tr>
                                            )) :
                                                <tr>
                                                    <td colSpan="9" className="text-center">
                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                                                            {
                                                                showTableResponse(blogs, checked)
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <BlogsModal ref={childRef} loadBlogs={loadBlogs} setBlogsLoading={setBlogsLoading} />
        </>
    )
}

export default Blogs;