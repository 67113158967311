import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Badge, Button, Spinner } from 'react-bootstrap';
import AcademicYearModal from './AcademicYearModal';
import { MdDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import swal from 'sweetalert';
import axios from 'axios';
import { toast } from 'react-toastify';
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import { FaCircle } from "react-icons/fa";
import { Pagination } from '@mui/material';
import '../common-css/Table.css'
import { bulkOperations, deleteItem, handleChecked, handleCheckedAll, handleSearch, showTableResponse } from '../common-helpers/commonServices';
import SearchBox from '../Common-Components/SearchBox';
import ProdutsDetailsTabsAtTop from '../Common-Components/ProdutsDetailsTabsAtTop';


const AcademicYear = () => {
  const childRef = useRef();
  const navigate = useNavigate();
  const [academicYears, setAcademicYears] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [checked, setChecked] = useState(academicYears);
  const [unchecked, setUnChecked] = useState(true);
  const [loadAcademicYear, setAcademicYearLoading] = useState(false);
  const [totalActiveYears, setTotalActiveYears] = useState([]);
  const [totalInactiveYears, setTotalInactiveYears] = useState([]);
  const [totalArchivedYears, setTotalArchivedYears] = useState([]);



  //set Table data
  useEffect(() => {
    axios.get(BACKEND_SERVER_URL + '/getAcademicYears').then((res) => {
      let academicYears = res.data.academicYears;  //recieving batch here    
      if (academicYears) {
        setTotalActiveYears(academicYears.filter(batch => batch.status == 1 && batch.hide == 0))
        setTotalInactiveYears(academicYears.filter(batch => batch.status == 0 && batch.hide == 0))
        let archivedYears = academicYears.filter(batch => batch.hide == 1)
        setTotalArchivedYears(archivedYears)

        let activeInactiveYears = academicYears.filter(batch => batch.hide == 0)
        setAcademicYears(activeInactiveYears);
        setChecked(activeInactiveYears);
      }
    }).catch((error) => {
      console.log(error)
      toast.error(error.response.data.message);
    })
  }, [loadAcademicYear])

  const pageSize = 20;
  const lastIndex = currentPage * pageSize;
  const firstIndex = lastIndex - pageSize;
  const records = checked?.slice(firstIndex, lastIndex);

  const npage = Math.ceil(checked.length / pageSize)
  const number = [...Array(npage + 1).keys()].slice(1)


  const makeCurrenYear = (sn) => {
    console.log(sn)
    axios.post(BACKEND_SERVER_URL + `/updateCurrentYear/${sn}`).then(res => {
      if (res.data.statusCode == 200) {
        setAcademicYearLoading(!loadAcademicYear)
      }
    })
  }


  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="row">
            <div className="col-xl-12">
              <div className=" page-title p-2 mb-2">{/*  flex-wrap  */}
                <div className="row w-100 d-flex justify-content-between">
                  <div className=' col-sm-6 d-flex justify-content-start'>
                    {/*     <input className='form-control w-50' type="text" name="" id="" value={inputYear} onChange={(e) => setInputYear(e.target.value)} /> */}
                    <button type="button" className=" btn btn-dark btn-sm me-2 w-25"
                      onClick={() => childRef.current.openModal()}
                    >
                      + Add
                    </button>
                  </div>
                  <div className=" col-sm-6 input-group search-area mb-md-0 mb-3">
                    <SearchBox setChecked={setChecked} dataArray={academicYears} searchingProperty={'academicYear'} />
                  </div>
                </div>

              </div>
              <ProdutsDetailsTabsAtTop setChecked={setChecked} totalItems={academicYears} totalActiveItems={totalActiveYears} totalInactiveItems={totalInactiveYears} totalArchivedItems={totalArchivedYears} />
            </div>
            <div className="col-xl-12 wow fadeInUp" data-wow-delay="1.5s">
              <div className="d-sm-flex text-center justify-content-between align-items-center">
                <div>
                  <Button variant="danger ms-3" size='xs' onClick={(e) => bulkOperations("delete", records, '/hideAcademicYears', 'Academic years', setAcademicYearLoading, loadAcademicYear)}>Delete</Button>
                  <Button variant="success ms-3 " size='xs' onClick={(e) => bulkOperations("activate", records, '/activateAcademicYears', 'Academic years', setAcademicYearLoading, loadAcademicYear)}>Active</Button>
                  <Button variant="warning ms-3" size='xs' onClick={(e) => bulkOperations("InActivate", records, '/inActivateAcademicYears', 'Academic years', setAcademicYearLoading, loadAcademicYear)}>Inactive</Button>
                </div>

                <div className='dataTables_info text-dark'>
                  Records: {checked.length}
                </div>
                {checked.length > pageSize ? <div className="dataTables_paginate paging_simple_numbers justify-content-center  mb-0 pt-2"
                  id="example-student_wrapper">
                  <Pagination count={number.length} variant="outlined" color='primary' size="small" page={currentPage} siblingCount={1} boundaryCount={2} onChange={(e, p) => setCurrentPage(parseInt(p))} />
                </div> : null}
              </div>
              <div className="table-responsive full-data" style={{ paddingBottom: "1px", transform: 'rotateX(180deg)' }}>
                <div id="example-student_wrapper" className="dataTables_wrapper no-footer">
                  <table className="table-responsive-lg table display dataTablesCard student-tab 
                                    dataTable no-footer mt-0 mb-0" style={{ transform: 'rotateX(180deg)' }} id="example-student" >
                    <thead style={{ backgroundColor: "#cccccc" }}>
                      <tr className='text-center text-dark border border-left'>
                        <th className=' custom-table-header  p-2'>SN</th>
                        <th className=' custom-table-header  p-2'>
                          <input type="checkbox" className="form-check-input" id="checkAll"
                            /*   checked={!unchecked} */
                            onClick={() => handleCheckedAll(unchecked, checked, setChecked, setUnChecked)}
                          />
                        </th>
                        <th className='p-2 text-start custom-table-header '>STATUS</th>
                        <th className=' text-start custom-table-header  p-2' >ACADEMIC YEAR</th>
                        <th className=' text-center custom-table-header  p-2' >CURRENT YEAR</th>
                        <th className='p-2 custom-table-header '>DATE</th>
                      </tr>
                    </thead>
                    <tbody>
                      {records.length > 0 ? records.map((item, ind) => (
                        <tr key={ind} className={`${ind % 2 == 1 ? 'custom row-height' : 'row-height'}`} >
                          <td className='text-center p-1 column-width ' style={{ border: "1px solid #cccccc", width: "65px" }} ><h6 className="sn-font-weight mb-0" > {item.sn}</h6></td>
                          <td className='text-center p-1 column-width' style={{ border: "1px solid #cccccc", width: "65px" }}>
                            <div className="checkbox me-0 align-self-center">
                              <div className="custom-control custom-checkbox ">
                                <input type="checkbox"
                                  className="form-check-input"
                                  id={`stud-${item.sn}`}
                                  checked={item.inputchecked}
                                  onChange={() => handleChecked(item.sn, setChecked, checked)}
                                />
                                <label className="custom-control-label" htmlFor={`stud-${item.sn}`}></label>
                              </div>
                            </div>
                          </td>
                          <td className='text-start p-1 active-col-width' style={{ border: "1px solid #cccccc", width: "80px" }}>
                            {item.status === 1 && <h6 className="text-success mb-0 ">Active </h6>}
                            {item.status == 0 && <h6 className="text-danger mb-0">Inactive </h6>}
                          </td>
                          <td className='p-1' style={{ border: "1px solid #cccccc" }}>
                            <div className="d-flex justify-content-between ">
                              <h6 className='mb-0 ' style={{ fontSize: '13px', fontWeight: 'bold' }}>{item.academicYear}
                                <CiEdit cursor='pointer' title='Edit Academic Year' size={18} className='ms-3' /* onClick={() => handleUpdateYear(item)}  */
                                  onClick={() => childRef.current.openModal(item)} />
                                <MdDeleteOutline color="red" title='Delete Academic Year' cursor='pointer' size={18} className='ms-2' onClick={() => { deleteItem(item.sn, 'Academic Year', '/deleteAcademicYear/', setAcademicYearLoading, loadAcademicYear) }}

                                />
                              </h6>
                            </div>
                          </td>
                          <td className='p-1 text-center' style={{ border: "1px solid #cccccc", width: "65px" }}>
                            <h6 className="mb-0 text-success">

                              {item.currentYear ? 'YES' : <Badge bg="" className='badge-primary light badge-sm ms-2 me-2 cursor-pointer' onClick={() => makeCurrenYear(item.sn)}>Make As Current Year</Badge>}

                            </h6>
                          </td>
                          <td className='text-center date-column-width  p-1' style={{ border: "1px solid #cccccc", width: "85px" }}><h6 className="mb-0">{new Date(item?.createdOn?.replace(/\s+/g, ""))?.toLocaleDateString('en-GB')}</h6></td>
                        </tr>
                      )) :
                        <tr>
                          <td colSpan="9" className="text-center">
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                              {
                                showTableResponse(academicYears, checked)
                              }
                            </div>
                          </td>
                        </tr>}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AcademicYearModal ref={childRef} loadAcademicYear={loadAcademicYear} setAcademicYearLoading={setAcademicYearLoading} />
    </>
  )
}

export default AcademicYear;
