import React, { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor'

import axios from 'axios';
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import { toast } from 'react-toastify';

const CKEditorInput = ({ inputValue, handleChangeCallBack, labelName, inputName, isMandatory }) => {
    function uploadAdapter(loader) {
        console.log(loader)
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    const body = new FormData();
                    loader.file.then((file) => {
                        console.log('Uploading file:', file);
                        body.append("uploadedFile", file);
                        console.log('body', body)
                        axios.post(BACKEND_SERVER_URL + '/uploadFile', body).then(res => {
                            resolve({ default: res.data.imagePath })
                        }).catch(error => {
                            reject(error)
                        })
                    })
                })
            }
        }
    }
    function uploadPlugin(editor) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            console.log('Creating upload adapter for loader:', loader);
            return uploadAdapter(loader)
        }
    }

    return (
        <>
            <div className="col-xl-2 col-sm-2">
                <div className="card-body  ms-0 ps-0" >
                    <label htmlFor={labelName} className="form-label text-primary ">{labelName}{isMandatory && <span className="required">*</span>}</label>
                </div>
            </div>
            <div className="col-xl-10 col-sm-10">
                <div className="card">
                    <div className="card-body custom-ekeditor ms-0 ps-0">
                        <CKEditor
                            config={{
                                extraPlugins: [uploadPlugin],
                               

                            }}

                            editor={Editor}
                            // data="<p>Hello from CKEditor 5!</p>"
                            onReady={editor => {
                                // You can store the "editor" and use when it is needed.
                            }}
                            data={inputValue ?? ''}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                console.log('data', data)
                                handleChangeCallBack(inputName, data)

                            }}
                            onBlur={(event, editor) => {
                            }}
                            onFocus={(event, editor) => {
                                //console.log( 'Focus.', editor );
                            }}

                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default CKEditorInput;
