import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Button, Spinner, Badge } from 'react-bootstrap';
import TopicModal from './TopicModal';
import { MdDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import axios from 'axios';
import { BACKEND_SERVER_URL } from '../../../../http/http-config';
import { Pagination } from '@mui/material';
import '../../common-css/Table.css'
import { bulkOperations, deleteItem, handleChecked, handleCheckedAll, showTableResponse, sortInAlphabeticalOrder } from '../../common-helpers/commonServices';
import SearchBox from '../../Common-Components/SearchBox';
import ProdutsDetailsTabsAtTop from '../../Common-Components/ProdutsDetailsTabsAtTop';
import { getCourses, getSubjects } from '../../CommonHelper';
import Select from 'react-select';
import { setDropDownStyle } from '../../CommonHelper';
import { toast } from 'react-toastify';



const TopicDetails = () => {
    const childRef = useRef();
    const navigate = useNavigate();
    const [topics, setTopics] = useState([]);
    const [courseWisetopics, setCourseWiseTopics] = useState([]);
    const [courses, setCourses] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [courseWiseSubjects, setCourseWiseSubjects] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState({ courseTitle: "All Courses" });
    const [selectedSubject, setSelectedSubject] = useState({ subject: "All Subjects" });
    const [sortingType, setSortingType] = useState('Show by Serial No.');
   
    const [currentPage, setCurrentPage] = useState(1);
    const [checked, setChecked] = useState(topics);
    const [unchecked, setUnChecked] = useState(true);
    const [loadTopics, setTopicsLoading] = useState(false);
    const [totalActiveTopics, setTotalActiveTopics] = useState([]);
    const [totalInactiveTopics, setTotalInactiveTopics] = useState([]);
    const [totalArchivedTopics, setTotalArchivedTopics] = useState([]);
    //set Table data
    useEffect(() => {
        axios.get(BACKEND_SERVER_URL + '/getTopics').then((res) => {
            let topics = res.data.topics;  //recieving batch here    
           
            if (topics) {
                setTotalActiveTopics(topics.filter(batch => batch.status == 1 && batch.hide == 0))
                setTotalInactiveTopics(topics.filter(batch => batch.status == 0 && batch.hide == 0))
                let archivedYears = topics.filter(batch => batch.hide == 1)
                setTotalArchivedTopics(archivedYears)

                let activeInactiveYears = topics.filter(batch => batch.hide == 0)
                setTopics(activeInactiveYears);
                setChecked(activeInactiveYears);
            }
        }).catch((error) => {
            console.log(error)
            toast.error(error.response.data.message)
        })
        getCourses().then((res) => {
            res.unshift({ courseTitle: "All Courses" })
            setCourses(res)
        })
        getSubjects().then((res) => {
          
            setSubjects(res)
        })
    }, [loadTopics])
    const pageSize = 20;
    const lastIndex = currentPage * pageSize;
    const firstIndex = lastIndex - pageSize;
    const records = checked?.slice(firstIndex, lastIndex);
    const npage = Math.ceil(checked.length / pageSize)
    const number = [...Array(npage + 1).keys()].slice(1)

   
    const selectCourseHandler = (selectedCourse) => {
        console.log(selectedCourse.courseId)
        
        if (selectedCourse.courseTitle == 'All Courses') {
            setChecked(topics);
            setCourseWiseTopics(topics);
            setSelectedCourse(selectedCourse);
            setCourseWiseSubjects([{ subject: "All Subjects" }]);
            setSelectedSubject({ subject: "All Subjects" })
            setSortingType('Show by Serial No.')
            return;
        }
        setSelectedCourse(selectedCourse);
        let selectedItems = topics.filter(topic => topic.courseId == selectedCourse.courseId || topic.courseTitle == selectedCourse.courseTitle)
        setCourseWiseTopics(selectedItems);
        setChecked(selectedItems);

        //set subjects course wise
        let selectedSubjects = subjects.filter(subject => subject.courseId == selectedCourse.courseId)
        selectedSubjects.unshift({ subject: "All Subjects" })
        setCourseWiseSubjects(selectedSubjects)
        setSelectedSubject({ subject: "All Subjects" })
    }
    const selectSubjectHandler = (selectedSubject) => {
        console.log(selectedSubject.sn)
        console.log(selectedSubject.subject)
        if (selectedSubject.subject == 'All Subjects') {
            setChecked(courseWisetopics);
            setSelectedSubject(selectedSubject);
            setSortingType('Show by Serial No.')
            return;
        }
        setSelectedSubject(selectedSubject);
        //alphabetical order
        let seletedTopics = courseWisetopics.filter(topic => /* topic.subject == selectedSubject.subject ||  */topic.subjectId == selectedSubject.sn);
        let alphabeticalOrderedTopics = sortInAlphabeticalOrder(seletedTopics, 'title')
        setChecked(alphabeticalOrderedTopics)
    }
    const selectOrderHandler = (selectedOrderType) => {
        let orderBySelectedSubjects = [...checked];
        setSortingType(selectedOrderType)
        if (selectedOrderType == 'Show by Serial No.') {
            orderBySelectedSubjects.sort((a, b) => b.sn - a.sn);
        } else {
            orderBySelectedSubjects.sort((a, b) => b.orderNo - a.orderNo);
        }
        setChecked(orderBySelectedSubjects);
    }


console.log(checked)

    return (
        <>
            <div className="row">
                <div className="col-xl-12">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className=" page-title p-2 mb-2">{/*  flex-wrap  */}
                                <div className=' col-8  d-flex justify-content-between'>
                                    <button type="button" className="btn btn-dark btn-sm me-2"
                                        onClick={() => childRef.current.openModal()}
                                    >
                                        + Add Topic
                                    </button>
                                    <div className="col-3">
                                        <div className="basic-dropdown">
                                            <Select
                                                value={{ value: selectedCourse, label: selectedCourse.courseTitle }}
                                                onChange={(selectedOption) => selectCourseHandler(selectedOption.value)}
                                                options={courses?.map((course) => ({ value: course, label: course.courseTitle }))}
                                                styles={setDropDownStyle()}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className="basic-dropdown ms-1">
                                            <Select
                                                value={{ value: selectedSubject, label: selectedSubject.subject }}
                                                onChange={(selectedOption) => selectSubjectHandler(selectedOption.value)}
                                                options={courseWiseSubjects?.map((subject) => ({ value: subject, label: subject.subject }))}
                                                styles={setDropDownStyle()}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className="basic-dropdown ms-1">
                                            <Select
                                                isDisabled={selectedSubject.sn ? false : true}
                                                value={{ value: sortingType, label: sortingType }}
                                                onChange={(selectedOption) => selectOrderHandler(selectedOption.value)}
                                                options={['Show by Serial No.', 'Show by Order No.']?.map((item) => ({ value: item, label: item }))}
                                                styles={setDropDownStyle()}
                                            />
                                        </div>
                                    </div>
                                    {/*    <button type="button" className="btn btn-dark btn-xs ms-2 " onClick={handleShowAllCourses}> show All</button> */}
                                </div>
                                <div className="input-group search-area mb-md-0 mb-3">
                                    <SearchBox setChecked={setChecked} dataArray={topics} searchingProperty={'title'} />
                                </div>
                            </div>
                            <ProdutsDetailsTabsAtTop setChecked={setChecked} totalItems={topics} totalActiveItems={totalActiveTopics} totalInactiveItems={totalInactiveTopics} totalArchivedItems={totalArchivedTopics} />
                        </div>
                        <div className="col-xl-12 wow fadeInUp" data-wow-delay="1.5s">
                            <div className="d-sm-flex text-center justify-content-between align-items-center">
                                <div>
                                    <Button variant="danger ms-3" size='xs' onClick={(e) => bulkOperations("delete", records, '/hideTopics', 'Topics', setTopicsLoading, loadTopics)}>Delete</Button>
                                    <Button variant="success ms-3 " size='xs' onClick={(e) => bulkOperations("activate", records, '/activateTopics', 'Topics', setTopicsLoading, loadTopics)}>Active</Button>
                                    <Button variant="warning ms-3" size='xs' onClick={(e) => bulkOperations("InActivate", records, '/inActivateTopics', 'Topics', setTopicsLoading, loadTopics)}>Inactive</Button>
                                </div>

                                <div className='dataTables_info text-dark'>
                                    Records: {checked.length}
                                </div>
                                {checked.length > pageSize ? <div className="dataTables_paginate paging_simple_numbers justify-content-center  mb-0 pt-2"
                                    id="example-student_wrapper">
                                    <Pagination count={number.length} variant="outlined" color='primary' size="small" page={currentPage} siblingCount={1} boundaryCount={2} onChange={(e, p) => setCurrentPage(parseInt(p))} />
                                </div> : null}
                            </div>
                            <div className="table-responsive full-data" style={{ paddingBottom: "1px", transform: 'rotateX(180deg)' }}>
                                <div id="example-student_wrapper" className="dataTables_wrapper no-footer">
                                    <table className="table-responsive-lg table display dataTablesCard student-tab 
                                    dataTable no-footer mt-0 mb-0" style={{ transform: 'rotateX(180deg)' }} id="example-student" >
                                        <thead style={{ backgroundColor: "#cccccc" }}>
                                            <tr className='text-center text-dark border border-left'>
                                                <th className=' custom-table-header  p-2'>SN</th>
                                                <th className=' custom-table-header  p-2'>ORDER NO.</th>
                                                <th className=' custom-table-header  p-2'>
                                                    <input type="checkbox" className="form-check-input" id="checkAll"
                                                        /*   checked={!unchecked} */
                                                        onClick={() => handleCheckedAll(unchecked, checked, setChecked, setUnChecked)}
                                                    />
                                                </th>
                                                <th className='p-2 text-start custom-table-header '>STATUS</th>
                                                <th className=' text-start custom-table-header  p-2' >LESSION TITLE</th>
                                                <th className=' text-start custom-table-header  p-2' >COURSE</th>
                                                <th className=' text-start custom-table-header  p-2' >SUBJECT</th>
                                                <th className='custom-table-header  p-2' >QUESTIONS</th>
                                                <th className='custom-table-header  p-2' >DEMO</th>
                                                <th className='p-2 custom-table-header '>PUBLISH DATE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {records.length > 0 ? records.map((item, ind) => (
                                                <tr key={ind} className={`${ind % 2 == 1 ? 'custom row-height' : 'row-height'}`} >
                                                    <td className='text-center p-1 column-width ' style={{ border: "1px solid #cccccc", width: "65px" }} ><h6 className="sn-font-weight mb-0" > {item.sn}</h6></td>
                                                    <td className='text-center p-1 column-width ' style={{ border: "1px solid #cccccc", width: "65px" }} ><h6 className="sn-font-weight mb-0" > {item.orderNo}</h6></td>
                                                    <td className='text-center p-1 column-width' style={{ border: "1px solid #cccccc", width: "65px" }}>
                                                        <div className="checkbox me-0 align-self-center">
                                                            <div className="custom-control custom-checkbox ">
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    id={`stud-${item.sn}`}
                                                                    checked={item.inputchecked}
                                                                    onChange={() => handleChecked(item.sn, setChecked, checked)}
                                                                />
                                                                <label className="custom-control-label" htmlFor={`stud-${item.sn}`}></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='text-start p-1 active-col-width' style={{ border: "1px solid #cccccc", width: "80px" }}>
                                                        {item.status === 1 && <h6 className="text-success mb-0 ">Active </h6>}
                                                        {item.status == 0 && <h6 className="text-danger mb-0">Inactive </h6>}
                                                    </td>
                                                    <td className='p-1' style={{ border: "1px solid #cccccc" }}>
                                                        <div className="d-flex justify-content-between ">
                                                            <h6 className='mb-0 ' style={{ fontSize: '13px', fontWeight: 'bold' }}>{item.title}
                                                                <CiEdit cursor='pointer' title='Edit Topic' size={18} className='ms-3'
                                                                    onClick={() => childRef.current.openModal(item.sn)} />
                                                                <MdDeleteOutline color="red" title='Delete Topic' cursor='pointer' size={18} className='ms-2' onClick={() => { deleteItem(item.sn, 'Topic', '/deleteTopic/', setTopicsLoading, loadTopics) }}

                                                                />
                                                            </h6>
                                                        </div>
                                                    </td>
                                                    <td className='p-1' style={{ border: "1px solid #cccccc" }}>
                                                        <h6 className="mb-0 ">
                                                            {item.courseTitle}
                                                        </h6>
                                                    </td>
                                                    <td className='p-1' style={{ border: "1px solid #cccccc" }}>
                                                        <h6 className="mb-0 ">
                                                            {item.subject}
                                                        </h6>
                                                    </td>
                                                    <td className='p-1 text-center cursor-pointer' style={{ border: "1px solid #cccccc" }} onClick={() => navigate(`/topic-question-bank`, { state: { topicId: item.sn } })} >
                                                        <h6 className="mb-0 ">
                                                            <Badge bg="" className='badge-primary light badge-sm ms-2 cursor-pointer pe-3 ps-3' >{item.questionCount || 0}</Badge>


                                                        </h6>
                                                    </td>
                                                    <td className='p-1 text-center' style={{ border: "1px solid #cccccc" }}>
                                                        <h6 className="mb-0 ">
                                                            {item.demo}
                                                        </h6>
                                                    </td>
                                                    <td className='text-center date-column-width  p-1' style={{ border: "1px solid #cccccc", width: "85px" }}><h6 className="mb-0">{new Date(item?.publishDate?.replace(/\s+/g, ""))?.toLocaleDateString('en-GB')}</h6></td>
                                                </tr>
                                            )) :
                                                <tr>
                                                    <td colSpan="9" className="text-center">
                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                                                            {
                                                                showTableResponse(topics, checked)
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TopicModal ref={childRef} loadTopics={loadTopics} setTopicsLoading={setTopicsLoading} />
        </>
    )
}

export default TopicDetails;

