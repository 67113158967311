import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import { checkExistingItem } from '../common-helpers/commonServices';



const QueryReplyModal = forwardRef((props, ref) => {
    const [modalBox, setModalBox] = useState(false);

    let initialData = {
        sn: 0,
        sort: 0,
        response: '',
        sendEmail: false,
        whatsAppReply: false,
        emailId: '',
        mobileNo:'',
        name: ''
    }

    const [replyData, setReplyData] = useState(initialData);
    console.log(replyData)

    useImperativeHandle(ref, () => ({
        openModal(item = {}) {
            console.log(item)
            if (item.sn > 0) {
                setReplyData({ sn: item.sn, response: item.response, emailId: item.emailId, mobileNo: item.mobileNo, name: item.name })
            }
            setModalBox(true);
        }
    }));

    const handleSaveCourseType = (value) => {
        if (replyData.response.trim().length === 0) {
            toast.error("Input Field can not be Blank")
        }
        axios.post(BACKEND_SERVER_URL + '/saveQueryReply', replyData).then((response) => {
            if (response.data.statusCode === 201) {
                toast.success(response.data.message)
                props.setQueryLoading(!props.loadQueries);
                setReplyData(initialData)
                setModalBox(false);
            }
        }).catch(error => {
            console.log(error)
            toast.error(error.response.data.message)
        })
    }
    useEffect(() => {
        if (!modalBox) {
            setReplyData(initialData); // Reset form data when modal is closed 
        }
    }, [modalBox]);


    return (
        <>
            <Modal onHide={setModalBox} show={modalBox} size="md" backdrop="static"
                keyboard={false} >
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel"> Reply</h5>
                        <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                            <label htmlFor="inputAcademicYear" className="form-label text-primary"> Reply<span className="required">*</span></label>
                            <textarea className='form-control' type="text" name="" id="inputAcademicYear" value={replyData.response} onChange={(e) => setReplyData({ ...replyData, response: e.target.value })} autoFocus />
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked={replyData.sendEmail} onClick={(e) => setReplyData({ ...replyData, sendEmail: e.target.checked })} />
                            <label class="form-check-label" for="flexCheckChecked">
                                Send a Copy via Email
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked={replyData.whatsAppReply} onClick={(e) => setReplyData({ ...replyData, whatsAppReply: e.target.checked })} />
                            <label class="form-check-label" for="flexCheckChecked">
                                Send a Copy via WhatsAppp
                            </label>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger btn-xs" onClick={() => setModalBox(false)}>Close</button>
                        <button type="button" className="btn btn-primary btn-xs " onClick={handleSaveCourseType}>Reply</button>
                    </div>
                </div>
            </Modal>

        </>
    )
})
export default QueryReplyModal;