import React, { Fragment, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'
import CourseSampleFile from '../../../download_files/course_sample_file.docx'

import { BACKEND_SERVER_URL } from '../../../http/http-config';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import Input from '../Common-Components/Input';
import FileInput from '../Common-Components/FileInput';
import DropdownInput from '../Common-Components/DropdownInput';
import BatchInput from '../Course/BatchInput';
import DateInput from '../Common-Components/DateInput';
import '../Course/Course.css'
import CKEditorInput from '../Common-Components/CKEditorInput';
import CategoryInput from './CategoryInput';
import { fetchNewsData, formateNewsData, validateCurrentAffairData } from './CurrentAffairHelper';
import { getNewsCategories, setDropDownStyle } from '../CommonHelper';
import { checkExistingItem } from '../common-helpers/commonServices';



const CurrentAffairsModal = forwardRef((props, ref) => {
    let initialFormData = {
        title: '',
        urlTitle: '',
        published: 'NO',
        featured: 'NO',
        description: '',
        imageURL: '',
        pdfURL: '',
        imageTitle: '',
        likeStart: 0,
        imageCaption: '',
        categories: '',
        primaryCategories: 'Select Category',
        primaryCategoryId: 0,
        tags: '',
        metaTitle: '',
        metaDescription: '',
        videoURL: '',
        publishDate: new Date(),
        writtenBy: ""
    }
    const [modalBox, setModalBox] = useState(false);    //new course
    const [courseData, setCourseData] = useState('');
    const [newsId, setNewsId] = useState('');
    const [updatingNewsId, setUpdatingNewsId] = useState();
    const [examTypesArray, setExamTypesArray] = useState([]);
    const [typesArray, setTypesArray] = useState([]);
    const [newsCategories, setNewsCategories] = useState([]);
    const [formData, setFormData] = useState(initialFormData);
    const [updatingDataReady, setUpdatingDataReady] = useState(false);
    console.log(newsCategories)

    //check for existing items       
    const [itemAlreadyExist, setItemAlreadyExists] = useState(false);
    const [currentTitle, setCurrentTitle] = useState('');//for updating 
    useEffect(() => {
        if (formData.title && currentTitle.toLowerCase().trim() != formData.title.toLowerCase().trim()) { // current title is for updating case
            console.log('enter')
            let timer = setTimeout(() => {
                checkExistingItem('TblNewsMaster', 'Title', formData.title).then(response => { //tablename and columnNane value
                    setItemAlreadyExists(response)
                })

            }, 500);
            return () => clearTimeout(timer);
        }

    }, [formData.title]);

    useImperativeHandle(ref, () => ({
        openModal(newsId) {
            getNewsCategories().then((newsData) => {
                console.log(newsData)
                setNewsCategories(newsData)

            })
            if (newsId) {
                console.log(newsId);
                setUpdatingNewsId(newsId);
                setNewsId(newsId);
                fetchNewsData(newsId).then((newsData) => {
                    console.log(newsData);
                    formateNewsData(newsData).then(formatedData => {
                        if (formatedData.title) {
                            setCurrentTitle(formatedData.title)
                            setFormData(formatedData);
                            setUpdatingDataReady(true);
                            //setModalBox(true);
                        }
                    })
                })
            } else {
                //setModalBox(true);
                setFormData(initialFormData);
            }
            setModalBox(true);
        }
    }));
    console.log(formData)
    const handleChange = (name, value) => {
        setFormData((formData) => (
            { ...formData, [name]: value }
        ));
    };

    console.log(formData)
    const handleSubmitBlog = () => {
        /*   let errorMessgae = validateCurrentAffairData(formData)
          console.log(errorMessgae)
          if (errorMessgae) {
              toast.error(errorMessgae)
              return;
          } else */
        if (itemAlreadyExist && currentTitle.toLowerCase().trim() != formData.title.toLowerCase().trim()) {
            toast.error("News Title Already Exists")
            return;
        }



        axios.post(BACKEND_SERVER_URL + (updatingNewsId ? `/updateCurrentAffair/${updatingNewsId}` : '/createCurrentAffair'), { ...formData, published: formData.published == 'NO' ? 0 : 1 }).then((response) => {
            if (response.data.statusCode == 201) {
                setFormData(initialFormData)
                toast.success(response.data.message)
                setUpdatingNewsId(0);
                setModalBox(false);
                props.setNewsLoading(!props.loadNews)
            }
        }).catch((error) => {
            toast.error(error.response.data.message)
            console.log(error)
        })

    }
    useEffect(() => {
        if (!modalBox) {
            console.log('modal close ,data reset')
            setFormData(initialFormData); // Reset form data when modal is closed 
            setUpdatingNewsId(0)
            setItemAlreadyExists(false)
            setCurrentTitle('')
        }
    }, [modalBox]);

    return (
        <>
            <Modal className="" show={modalBox} onHide={setModalBox} dialogClassName="custom-modal-width" backdrop="static"
                keyboard={false} >
                <Modal.Header>
                    <Modal.Title>{updatingNewsId ? 'Update News' : 'Create News'}</Modal.Title>
                    <Button variant="" className="btn-close" onClick={() => setModalBox(false)}></Button>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-around ">
                                <div className="col-5 d-flex">
                                    <Input inputType="text" handleChangeCallBack={handleChange} labelName="Title" inputValue={formData?.title} inputName='title' divSizes={[4, 8]} />
                                </div>
                                <div className="col-5 d-flex">
                                    <Input inputType="text" handleChangeCallBack={handleChange} labelName="URL Title" inputValue={formData?.urlTitle} inputName='urlTitle' divSizes={[4, 8]} />                                   
                                </div>
                            </div>

                            <div className="col-12 d-flex justify-content-around">
                                <div className="col-5 d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="Written By" inputType="text" inputValue={formData?.writtenBy} inputName='writtenBy' divSizes={[4, 8]} />
                                </div>
                                <div className="col-5 d-flex align-items-center">
                                    <DateInput labelName="Publish Date" selected={formData?.publishDate} handleChangeCallBack={handleChange} inputName='publishDate' />
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-around  ">
                                <div className="col-5 d-flex ">
                                    <DropdownInput handleChangeCallBack={handleChange} labelName="Published" selectedValue={formData?.published} inputName='published' dropdownValuesArray={['YES', 'NO']} />
                                </div>
                                <div className="col-5 d-flex ">
                                    <div className="col-sm-4 pt-2">
                                        <div className="mb-3" >
                                            <label htmlFor='news' className="form-label text-primary">Primary Category</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-8">
                                        <div className="mb-3">
                                            <Select
                                                value={{ value: formData.primaryCategories, label: formData.primaryCategories }}
                                                onChange={(selectedOption) => setFormData({ ...formData, primaryCategoryId: selectedOption.value.sn, primaryCategories: selectedOption.value.title })}
                                                options={newsCategories?.map((item) => ({ value: item, label: item.title }))}
                                                styles={setDropDownStyle()}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-around  ">
                                <div className="col-5 d-flex ">
                                    <DropdownInput handleChangeCallBack={handleChange} labelName="Featured" selectedValue={formData?.featured} inputName='featured' dropdownValuesArray={['YES', 'NO']} />
                                </div>
                                <div className="col-5 d-flex ">
                                   
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <CKEditorInput handleChangeCallBack={handleChange} labelName="Description" inputValue={formData?.description} inputName='description' />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <FileInput handleChangeCallBack={handleChange} labelName="Image" inputType="text" uploadedFileURL={formData?.imageURL} inputName='imageURL' accept="image/jpeg, image/png, image/gif" />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <FileInput handleChangeCallBack={handleChange} labelName="PDF Upload" inputType="text" uploadedFileURL={formData?.pdfURL} inputName='pdfURL' accept="pdf" />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <CategoryInput handleChangeCallBack={handleChange} labelName="Categories" inputValue={formData.categories} inputName='categories' />
                                </div>
                            </div>

                            <div className="col-12 ">
                                <div className="d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="Image Caption" inputType="text" inputValue={formData?.imageCaption} inputName='imageCaption' divSizes={[2, 10]} />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="Image Title" inputType="text" inputValue={formData?.imageTitle} inputName='imageTitle' divSizes={[2, 10]} />
                                </div>
                            </div>
                            {/* <div className="col-12 ">
                                <div className="d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="Video URL" inputType="text" inputValue={formData?.videoURL} inputName='videoURL' divSizes={[2, 10]} />
                                </div>
                            </div> */}
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="Meta Title" inputType="text" inputValue={formData?.metaTitle} inputName='metaTitle' divSizes={[2, 10]} />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="Meta Description" inputType="textarea" inputValue={formData?.metaDescription} inputName='metaDescription' divSizes={[2, 10]} />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="Tags" inputType="textarea" inputValue={formData?.tags} inputName='tags' divSizes={[2, 10]} />
                                </div>
                            </div>                          

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger light" className='btn-sm' onClick={() => setModalBox(false)}>Close</Button>
                    <Button variant="primary " className='btn-sm ' onClick={handleSubmitBlog}>{updatingNewsId ? 'Update News' : 'Create News'}</Button>
                </Modal.Footer>
            </Modal>


        </>
    )
})
export default CurrentAffairsModal;