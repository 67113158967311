import React, { useEffect, useRef, useState } from 'react'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import SubTestModal from './SubTestModal';
import { MdDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { format } from 'date-fns';

import swal from 'sweetalert';
import axios from 'axios';
import { BACKEND_SERVER_URL } from '../../../../http/http-config';
import { Accordion, Pagination } from '@mui/material';
import ButtonMUI from '@mui/material/Button';
import { bulkOperations, deleteItem, handleChecked, handleCheckedAll, handleSearch, showTableResponse } from '../../common-helpers/commonServices';
import ProdutsDetailsTabsAtTop from '../../Common-Components/ProdutsDetailsTabsAtTop';
import SearchBox from '../../Common-Components/SearchBox';
import { fetchTestData, formateTestData } from '../TestHelper';
import { CiSettings } from "react-icons/ci";
import '../test.css'


import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { SlArrowLeft } from 'react-icons/sl';



const TestLists = () => {
  const { state } = useLocation();
  const courseId = state?.courseId;
  const testSeriesSn = state?.testSeriesSn;
  console.log(testSeriesSn)


  


  const childRef = useRef();
  const navigate = useNavigate();
  const [tests, setTests] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [checked, setChecked] = useState(tests);
  const [unchecked, setUnChecked] = useState(true);
  const [loadSubTests, setSubTestLoading] = useState(false);

  //test series data
  const [currentTestSeries, setCurrentTestSeries] = useState({})

  useEffect(() => {
    fetchTestData(courseId).then(res => {
      formateTestData(res).then(formatedData => {
        setCurrentTestSeries(formatedData)
      })
    })

    axios.get(BACKEND_SERVER_URL + `/getSubTests/${courseId}`).then((res) => {
      let tests = res.data.tests;  //recieving batch here   
      if (tests) {
        setTests(tests);
        setChecked(tests);
      }
    })
  }, [loadSubTests])

  const pageSize = 20;
  const lastIndex = currentPage * pageSize;
  const firstIndex = lastIndex - pageSize;
  const records = checked?.slice(firstIndex, lastIndex);
  const npage = Math.ceil(checked.length / pageSize)
  const number = [...Array(npage + 1).keys()].slice(1)

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };
  const handleEditTest = (e, packageId) => {
    e.stopPropagation()
    childRef.current?.openModal(courseId, packageId)
  }
  const handleTestQuestions = (e, packageId, type) => {
    e.stopPropagation();
    if (type == 'MCQ') navigate(`/question-bank`, { state: { packageId: packageId, testSeriesSn } });
    else navigate(`/moderate-solution`, { state: { packageId: packageId,courseId:courseId} })
  
  }



  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="row">
            <div className="col-xl-12">
              <div className=" page-title p-2 mb-2">{/*  flex-wrap  */}
                <div className="col-2">
                  <button type="button" className=" btn btn-dark btn-sm "
                    onClick={() => childRef.current.openModal(courseId)}
                  >
                    + Add
                  </button>
                </div>
                <div className="col-10 ">
                  <h4 className='text-start'>
                    <span style={{ fontWeight: 'bold' }}>
                      TEST SERIES NAME :{" "}
                    </span>
                    <span style={{ textDecoration: 'underline', color: 'green' }}>
                      {currentTestSeries?.title && currentTestSeries.title.toUpperCase()}
                    </span>
                  </h4>



                </div>

              </div>


            </div>
            <div className="col-xl-12 wow fadeInUp" data-wow-delay="1.5s">
              <div className="table-responsive full-data">
                <div id="example-student_wrapper" className="dataTables_wrapper no-footer">
                  <div className="d-sm-flex text-center justify-content-between align-items-center">
                    <div >
                      <ButtonMUI className="ms-2 mt-2 mb-2" variant="outlined" color="secondary" startIcon={<SlArrowLeft size={10} />} onClick={() => navigate('/test-series')} >
                        Back
                      </ButtonMUI>
                    </div>

                    <div className='dataTables_info text-dark'>
                      Records: {checked.length}
                    </div>
                    {checked.length > pageSize ? <div className="dataTables_paginate paging_simple_numbers justify-content-center  mb-0 pt-2"
                      id="example-student_wrapper">
                      <Pagination count={number.length} variant="outlined" color='primary' size="small" page={currentPage} siblingCount={1} boundaryCount={2} onChange={(e, p) => setCurrentPage(parseInt(p))} />
                    </div> : null}
                  </div>
                  <div>
                    {
                      tests?.map(item => (
                        <Accordion key={item.sn} expanded={expanded === item.sn} onChange={handleChange(item.sn)}>
                          <AccordionSummary
                            expandIcon={expanded ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            style={{ backgroundColor: expanded ? '#e0e0e0' : '#f5f5f5 ' }}
                          >
                            <div style={{ display: 'flex', width: '100%' }} className='text-primary'>
                              <div style={{ flex: .6 }}>
                                <Typography style={{ color: '#303972', fontWeight: "bold" }}>{item.type === 'MCQ' ? 'PTS-' : 'MTS-'}{item.accessCode}</Typography>
                              </div>
                              <div style={{ flex: 3 }}>
                                <Typography style={{ color: '#303972', fontWeight: "bold" }}>{item.title} <span className='text-primary ms-2' style={{ color: '#303972', fontWeight: "250" }}>({item.packageId})</span></Typography>
                              </div>
                        
                              {/*  <div style={{ flex: 1 }}>
                                <Typography style={{ color: '#303972' }}>YV MAY</Typography>
                              </div> */}
                              <div style={{ flex: 0.8 }}>
                                <Typography style={{ color: '#303972' }}>
                                  {
                                    item.type == 'MCQ' ? <ButtonMUI variant="outlined" size='small' style={{ color: 'black', borderColor: '#4CAF50' }} onClick={(e) => handleTestQuestions(e, item.packageId, item.type)} >Questions</ButtonMUI>
                                      :
                                      <ButtonMUI variant="outlined" size='small' style={{ color: 'black', borderColor: '#4CAF50' }} onClick={(e) => handleTestQuestions(e, item.packageId, item.type)} >Moderate</ButtonMUI>
                                  }
                                </Typography>
                              </div>
                              <div style={{ flex: 0.3 }}>
                                <Typography style={{ color: '#303972' }}>{<CiSettings size={25} color='#009688' onClick={(e) => handleEditTest(e, item.packageId)} />}</Typography>
                              </div>
                            </div>
                          </AccordionSummary>
                          <AccordionDetails className='custom-accordion-details'>
                            <Typography variant="body1" component="div" style={{ color: "black" }} dangerouslySetInnerHTML={{ __html: item.description }} />
                          </AccordionDetails>
                        </Accordion>
                      ))
                    }
                  </div>

                  {/* <table className="table-responsive-lg table display dataTablesCard student-tab 
                                    dataTable no-footer mt-0 " id="example-student" >
                    <thead style={{ backgroundColor: "#cccccc" }}>
                      <tr className='text-center text-dark border border-left'>
                        <th className=' text-dark  p-2'>SN</th>
                        <th className=' text-dark  p-2'>
                          <input type="checkbox" className="form-check-input" id="checkAll"
                           
                            onClick={() => handleCheckedAll(unchecked, checked, setChecked, setUnChecked)}
                          />
                        </th>
                        <th className='p-2 text-start  text-dark '>STATUS</th>
                        <th className=' text-start text-dark  p-2' > FCULTY NAME</th>
                        <th className='p-2 text-dark '>DATE</th>
                      </tr>
                    </thead>
                    <tbody>
                      {records.length > 0 ? records.map((item, ind) => (
                        <tr key={ind} className={`${ind % 2 == 1 ? 'custom row-height' : 'row-height'}`} >
                          <td className='text-center p-1 column-width' style={{ border: "1px solid #cccccc", width: "65px" }} ><h6 className="sn-font-weight mb-0"> {item.sn}</h6></td>
                          <td className='text-center p-1 column-width' style={{ border: "1px solid #cccccc", width: "65px" }}>
                            <div className="checkbox me-0 align-self-center">
                              <div className="custom-control custom-checkbox ">
                                <input type="checkbox"
                                  className="form-check-input"
                                  id={`stud-${item.sn}`}
                                  checked={item.inputchecked}
                                  onChange={() => handleChecked(item.sn, setChecked, checked)}
                                />
                                <label className="custom-control-label" htmlFor={`stud-${item.sn}`}></label>
                              </div>
                            </div>
                          </td>
                          <td className='text-start p-1 active-col-width' style={{ border: "1px solid #cccccc", width: "80px" }}>
                            {item.status === 1 && <h6 className="text-success mb-0 ">Active </h6>}
                            {item.status == 0 && <h6 className="text-danger mb-0">Inactive </h6>}
                          </td>
                          <td className='p-1' style={{ border: "1px solid #cccccc" }}>
                            <div className="d-flex justify-content-between ">
                              <h6 className='mb-0 ' style={{ fontSize: '13px', fontWeight: 'bold' }}>{item.facultyName}{' '}
                                <CiEdit cursor='pointer' title='Edit Faculty' size={18} className='ms-3'
                                  onClick={() => childRef.current.openModal(item)} />
                                <MdDeleteOutline color='red' title='Delete Faculty' cursor='pointer' size={18} className='ms-2' onClick={() => { deleteItem(item.sn, 'Faculty', '/deleteFaculty/', setFacultiesLoading, loadFaculties) }}

                                />
                              </h6>
                            </div>
                          </td>
                          <td className='text-center date-column-width p-1' style={{ border: "1px solid #cccccc", width: "65px" }}><h6 className="mb-0">{new Date(item?.createdOn?.replace(/\s+/g, ""))?.toLocaleDateString('en-GB')}</h6></td>
                        </tr>
                      )) :
                        <tr>
                          <td colSpan="9" className="text-center">
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                              {
                                showTableResponse(faculties, checked)
                              }
                            </div>
                          </td>
                        </tr>}
                    </tbody>
                  </table> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SubTestModal ref={childRef} loadSubTests={loadSubTests} setSubTestLoading={setSubTestLoading} />
    </>
  )
}

export default TestLists;



