import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Button, Spinner, Badge } from 'react-bootstrap';
import DummyReportModalNew from './DummyReportModalNew';
import { MdDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import axios from 'axios';
import { BACKEND_SERVER_URL } from '../../../../http/http-config';
import { Pagination } from '@mui/material';

import { bulkOperations, deleteItem, handleChecked, handleCheckedAll, showTableResponse, sortInAlphabeticalOrder } from '../../common-helpers/commonServices';
import SearchBox from '../../Common-Components/SearchBox';
import ProdutsDetailsTabsAtTop from '../../Common-Components/ProdutsDetailsTabsAtTop';
import { getCourses, getSubjects, getTopics } from '../../CommonHelper';
import Select from 'react-select';
import { setDropDownStyle } from '../../CommonHelper';
import { toast } from 'react-toastify';



const TopicReports = () => {
  const childRef = useRef();
  const navigate = useNavigate();
  const [reports, setReports] = useState([]);
  const [subjectWisetopics, setSubjectWiseTopics] = useState([]);
  const [courses, setCourses] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [topics, setTopics] = useState([]);
  const [courseWiseSubjects, setCourseWiseSubjects] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState({ courseTitle: "All Courses" });
  const [selectedSubject, setSelectedSubject] = useState({ subject: "All Subjects" });
  const [selectedTopic, setSelectedTopic] = useState({ title: "All Topics" });
  const [sortingType, setSortingType] = useState('Show by Serial No.');

  const [currentPage, setCurrentPage] = useState(1);
  const [checked, setChecked] = useState(reports);
  const [unchecked, setUnChecked] = useState(true);
  const [loadReports, setReportsLoading] = useState(false);
  const [totalActiveTopics, setTotalActiveTopics] = useState([]);
  const [totalInactiveTopics, setTotalInactiveTopics] = useState([]);
  const [totalArchivedTopics, setTotalArchivedTopics] = useState([]);
  //set Table data
  useEffect(() => {
    axios.get(BACKEND_SERVER_URL + '/getLessonReports').then((res) => {
      let reports = res.data.reports;  //recieving batch here    
      console.log('reports', reports)
      setReports(reports);
      setChecked(reports);
      // if (reports) {
      //   setTotalActiveTopics(reports.filter(batch => batch.status == 1 && batch.hide == 0))
      //   setTotalInactiveTopics(reports.filter(batch => batch.status == 0 && batch.hide == 0))
      //   let archivedYears = reports.filter(batch => batch.hide == 1)
      //   setTotalArchivedTopics(archivedYears)

      //   let activeInactiveYears = reports.filter(batch => batch.hide == 0)
      //   setTopics(activeInactiveYears);
      //   setChecked(activeInactiveYears);
      // }
    }).catch((error) => {
      console.log(error)
      toast.error(error.response.data.message)
    })
    getCourses().then((res) => {
      res.unshift({ courseTitle: "All Courses" })
      setCourses(res)
    })
    getSubjects().then((res) => {
      setSubjects(res)
    })
    getTopics().then((res) => {
      console.log(res)
      setTopics(res)
    })
  }, [loadReports])
  const pageSize = 20;
  const lastIndex = currentPage * pageSize;
  const firstIndex = lastIndex - pageSize;
  const records = checked?.slice(firstIndex, lastIndex);
  const npage = Math.ceil(checked.length / pageSize)
  const number = [...Array(npage + 1).keys()].slice(1)


  const selectCourseHandler = (selectedCourse) => {

    if (selectedCourse.courseTitle == 'All Courses') {
      setSelectedCourse(selectedCourse);
      setCourseWiseSubjects([{ subject: "All Subjects" }]);
      setSelectedSubject({ subject: "All Subjects" })
      return;
    }
    setSelectedCourse(selectedCourse);
    //set subjects course wise
    let selectedSubjects = subjects.filter(subject => subject.courseId == selectedCourse.courseId)
    selectedSubjects.unshift({ subject: "All Subjects" })
    setCourseWiseSubjects(selectedSubjects)
    setSelectedSubject({ subject: "All Subjects" })
  }
  const selectSubjectHandler = (selectedSubject) => {
    if (selectedSubject.subject == 'All Subjects') {
      setSelectedSubject(selectedSubject);
      return;
    }
    setSelectedSubject(selectedSubject);
    let selectedTopics = topics.filter(topic => topic.subjectId == selectedSubject.sn)
    // selectedTopics.unshift({ title: "All Topics" })
    setSubjectWiseTopics(selectedTopics)
  }
  console.log(reports)

  const selectTopicHandler = (selectedTopic) => {
    setSelectedTopic(selectedTopic)
    let selectedReports = reports.filter(obj => obj.sectionId == selectedTopic.sectionId || obj.topicId == selectedTopic.sn);
    setChecked(selectedReports)
  }


  console.log('subjectWisetopics', subjectWisetopics)

  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="row">
            <div className="col-xl-12">
              <div className=" page-title p-2 mb-2">{/*  flex-wrap  */}
                <div className=' col-8  d-flex justify-content-between'>
                  <button type="button" className="btn btn-dark btn-sm me-2"
                    onClick={() => childRef.current.openModal()}
                  >
                    + Add Report
                  </button>
                  <div className="col-3">
                    <div className="basic-dropdown">
                      <Select
                        value={{ value: selectedCourse, label: selectedCourse.courseTitle }}
                        onChange={(selectedOption) => selectCourseHandler(selectedOption.value)}
                        options={courses?.map((course) => ({ value: course, label: course.courseTitle }))}
                        styles={setDropDownStyle()}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="basic-dropdown ms-1">
                      <Select
                        value={{ value: selectedSubject, label: selectedSubject.subject }}
                        onChange={(selectedOption) => selectSubjectHandler(selectedOption.value)}
                        options={courseWiseSubjects?.map((subject) => ({ value: subject, label: subject.subject }))}
                        styles={setDropDownStyle()}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="basic-dropdown ms-1">
                      <Select
                        value={{ value: selectedTopic || "All Topics", label: selectedTopic.title || "All Topics" }}
                        onChange={(selectedOption) => selectTopicHandler(selectedOption.value)}
                        options={subjectWisetopics?.map((item) => ({ value: item, label: item.title }))}
                        styles={setDropDownStyle()}
                      />
                    </div>
                  </div>
                  {/*    <button type="button" className="btn btn-dark btn-xs ms-2 " onClick={handleShowAllCourses}> show All</button> */}
                </div>
                <div className="input-group search-area mb-md-0 mb-3">
                  <SearchBox setChecked={setChecked} dataArray={reports} searchingProperty={'firstName'} />
                </div>
              </div>
              {/* <ProdutsDetailsTabsAtTop setChecked={setChecked} totalItems={topics} totalActiveItems={totalActiveTopics} totalInactiveItems={totalInactiveTopics} totalArchivedItems={totalArchivedTopics} /> */}
            </div>
            <div className="col-xl-12 wow fadeInUp" data-wow-delay="1.5s">
              <div className="d-sm-flex text-center justify-content-between align-items-center">
                {/* <div>
                  <Button variant="danger ms-3" size='xs' onClick={(e) => bulkOperations("delete", records, '/hideTopics', 'Topics', setTopicsLoading, loadTopics)}>Delete</Button>
                  <Button variant="success ms-3 " size='xs' onClick={(e) => bulkOperations("activate", records, '/activateTopics', 'Topics', setTopicsLoading, loadTopics)}>Active</Button>
                  <Button variant="warning ms-3" size='xs' onClick={(e) => bulkOperations("InActivate", records, '/inActivateTopics', 'Topics', setTopicsLoading, loadTopics)}>Inactive</Button>
                </div> */}

                <div className='dataTables_info text-dark'>
                  Records: {checked.length}
                </div>
                {checked.length > pageSize ? <div className="dataTables_paginate paging_simple_numbers justify-content-center  mb-0 pt-2"
                  id="example-student_wrapper">
                  <Pagination count={number.length} variant="outlined" color='primary' size="small" page={currentPage} siblingCount={1} boundaryCount={2} onChange={(e, p) => setCurrentPage(parseInt(p))} />
                </div> : null}
              </div>
              <div className="table-responsive full-data" style={{ paddingBottom: "1px", transform: 'rotateX(180deg)' }}>
                <div id="example-student_wrapper" className="dataTables_wrapper no-footer">
                  <table className="table-responsive-lg table display dataTablesCard student-tab 
                                    dataTable no-footer mt-0 mb-0" style={{ transform: 'rotateX(180deg)' }} id="example-student" >
                    <thead style={{ backgroundColor: "#cccccc" }}>
                      <tr className='text-center text-dark border border-left'>
                        <th className=' custom-table-header  p-2'>SN</th>
                        <th className='p-2 text-start custom-table-header '>STATUS</th>
                        <th className=' text-start custom-table-header  p-2' >STUDENT NAME</th>
                        <th className=' text-start custom-table-header  p-2' >LESSON</th>
                        <th className='custom-table-header  p-2' >TOTAL Qs.</th>
                        <th className='custom-table-header  p-2' >ATTEMPTED Qs..</th>
                        <th className='custom-table-header  p-2' >NET SCORE</th>
                        <th className='custom-table-header  p-2' >ATTEMPT COUNT</th>
                      </tr>
                    </thead>
                    <tbody>
                      {records.length > 0 ? records.map((item, ind) => (
                        <tr key={ind} className={`${ind % 2 == 1 ? 'custom row-height' : 'row-height'}`} >
                          <td className='text-center p-1 column-width ' style={{ border: "1px solid #cccccc", width: "65px" }} ><h6 className="sn-font-weight mb-0" > {item.sn}</h6></td>
                          <td className='text-start p-1 active-col-width' style={{ border: "1px solid #cccccc", width: "80px" }}>
                            {item.status === 'Complete' ? <h6 className="text-success mb-0 "> Complete </h6> : <h6 className="text-danger mb-0"> Incomplete </h6>}

                          </td>
                          <td className='p-1' style={{ border: "1px solid #cccccc" }}>
                            <div className="d-flex justify-content-between">
                              {item.firstName ? (
                                <h6 className='mb-0' style={{ fontSize: '13px', fontWeight: 'bold' }}>{item.firstName}</h6>
                              ) : (
                                <h6 className='mb-0 text-secondary' style={{ fontSize: '13px', fontWeight: 'bold' }}>Dummy Report</h6>
                              )}
                            </div>

                          </td>
                          <td className='p-1' style={{ border: "1px solid #cccccc" }}>
                            <h6 className='mb-0 ' style={{ fontSize: '13px', fontWeight: 'bold' }}>{item.lessonName}</h6>
                          </td>
                          <td className='p-1 text-center' style={{ border: "1px solid #cccccc" }}>
                            <h6 className="mb-0 ">
                              {item.totalQuestions}
                            </h6>
                          </td>
                          <td className='p-1 text-center' style={{ border: "1px solid #cccccc" }}>
                            <h6 className="mb-0 ">
                              {item.attemptedQuestions}
                            </h6>
                          </td>
                          <td className='p-1 text-center' style={{ border: "1px solid #cccccc" }}>
                            <h6 className="mb-0 ">
                              {item.netScore}
                            </h6>
                          </td>
                          <td className='p-1 text-center' style={{ border: "1px solid #cccccc", width: "80px" }}>
                            <h6 className="mb-0 ">
                              {item.attemptCount}
                            </h6>
                          </td>

                        </tr>
                      )) :
                        <tr>
                          <td colSpan="9" className="text-center">
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                              {
                                showTableResponse(reports, checked)
                              }
                            </div>
                          </td>
                        </tr>}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DummyReportModalNew ref={childRef} loadTopics={loadReports} setReportsLoading={setReportsLoading} />
    </>
  )
}

export default TopicReports;

