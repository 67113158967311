import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getCourses, getSubjects, setDropDownStyle } from '../CommonHelper';


const CourseAndSubjectDropDown = ({ formData, setFormData, courseId, subjectId }) => {
    const [selectedCourse, setSelectedCourse] = useState({ courseTitle: "Select Course" });
    const [selectedSubject, setSelectedSubject] = useState({ subject: 'Select Subject' });
    const [subjects, setSubjects] = useState([]);
    const [courseWiseSubjects, setCourseWiseSubjects] = useState([]);
    const [courses, setCourses] = useState([]);
    console.log(courseId)
    useEffect(() => {
        getSubjects().then((res) => {
            setSubjects(res);
            if (courseId) {
                setSelectedSubject(res?.find(subject => subject.sn == subjectId))
                setCourseWiseSubjects(res.filter(subject => subject.courseId == courseId))
            }
        })
        getCourses().then((res) => {
            setCourses(res);
            if (courseId) {
                setSelectedCourse(res?.find(course => course.courseId == courseId))
            }
        })
    }, [])
    const selectCourseHandler = (selectedValue) => {
        if (selectedValue.courseTitle !== selectedCourse.courseTitle) {
            setSelectedCourse(selectedValue);
            setCourseWiseSubjects(subjects.filter(subject => subject.courseId === selectedValue.courseId));
            setFormData(prevFormData => ({
                ...prevFormData,
                courseId: selectedValue.courseId,
                packageId: selectedValue.courseId
            }));
            setSelectedSubject({ subject: 'Select Subject' });
            setFormData(prevFormData => ({
                ...prevFormData,
                subjectId: 0,
                subject: ''
            }));
        }
    }

    const selectSubjecHandler = (selectedValue) => {
        setSelectedSubject(selectedValue)
        setFormData({ ...formData, subjectId: selectedValue.sn, subject: selectedValue.subject })
    }
    return (
        <>
            <div className="col-5 d-flex">
                <div className="col-sm-4 pt-2">
                    <div className="mb-3" >
                        <label htmlFor='Course' className="form-label text-primary">Course<span className="required">*</span></label>
                    </div>
                </div>
                <div className="col-sm-8">
                    <div className="mb-3 " >
                        <Select
                            value={{ value: selectedCourse, label: selectedCourse.courseTitle }}
                            onChange={(selectedOption) => selectCourseHandler(selectedOption.value)}
                            options={courses?.map((item) => ({ value: item, label: item.courseTitle }))}
                            styles={setDropDownStyle()}
                        />
                    </div>

                </div>
            </div>
            <div className="col-5 d-flex">
                <div className="col-sm-4 pt-2">
                    <div className="mb-3" >
                        <label htmlFor='Subject' className="form-label text-primary">Subject<span className="required">*</span></label>
                    </div>
                </div>
                <div className="col-sm-8">
                    <div className="mb-3" >
                        <Select
                            isDisabled={selectedCourse.courseId ? false : true}
                            value={{ value: selectedSubject, label: selectedSubject?.subject }}
                            onChange={(selectedOption) => selectSubjecHandler(selectedOption.value)}
                            options={courseWiseSubjects?.map((item) => ({ value: item, label: item.subject }))}
                            styles={setDropDownStyle()}
                        />
                    </div>

                </div>
            </div>





        </>
    );
}

export default CourseAndSubjectDropDown;