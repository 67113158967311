import React, { Fragment, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'
// import CustomClearIndicator from './MultiSelectBatches';
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import { Modal, Table } from 'react-bootstrap';

import Select from 'react-select';
import '../common-css/Table.css'
import { setDropDownStyle, getTestSeries, getTestSeriesTests } from '../CommonHelper';


const TestDummyReportModal = forwardRef((props, ref) => {
    const [modalBox, setModalBox] = useState(false);
    const [testSeries, setTestSeries] = useState([]);
    const [tests, setTests] = useState([]);

    console.log(testSeries)

    const [reportData, setReportData] = useState([])
    let intialCommonData = {
        testSeries: 'Select Test Sereis',
        courseId: "",
        testSeriesSn: 0,
        packageId: '',
        test: 'Select Test'
    }
    const [commonData, setCommonData] = useState(intialCommonData)

    console.log(commonData)



    useEffect(() => {
        if (commonData.courseId) {
            getTestSeriesTests(commonData.courseId).then((res) => {
                setTests(res?.filter(obj => obj.type == 'MCQ'))
            })
            setCommonData((preData) => ({ ...preData, packageId: '', test: 'Select Test' }))
        }

    }, [commonData.courseId])

    const [updatingSubjectData, setUpdatingSubjectData] = useState({ sn: 0, subjectName: '', subjectOrderNo: 0, })
    console.log(updatingSubjectData)
    useImperativeHandle(ref, () => ({
        openModal(item = {}) {
            setModalBox(true)
            if (item.sn > 0) {
                setUpdatingSubjectData({
                    sn: item.sn,
                    subjectName: item.subject,
                    subjectOrderNo: item.subjectOrderNo,
                    courseId: item.courseId,
                    courseTitle: item.courseTitle,
                })

            }
            getTestSeries().then((res) => {
                setTestSeries(res)
            }).catch((error) => {
                console.log(error)
                toast.error(error.response.data.message)
            })


        }
    }));

    const handleSaveReports = async () => {
        if (!commonData.courseId) {
            toast.error("Please Select Test Series")
            return;
        } else if (!commonData.packageId) {
            toast.error("Please Select any Test")
            return;
        }


        let formatedData = reportData.map(reprot => {
            return {
                ...reprot, packageId: commonData.packageId, testSeriesSn: commonData.testSeriesSn, status: 'Complete'
            }
        })
        console.log(formatedData)


        try {
            const res = await axios.post(BACKEND_SERVER_URL + '/saveTestDummyReports', formatedData);
            if (res?.data.statusCode == 201) {
                toast.success(res.data.message);
                props.setReportsLoading(!props.loadReports);
                setModalBox(false);
            }
        } catch (error) {
            toast.error(error.response.data.message)
            console.error("An error occurred during the request:", error);

        }
    }




    useEffect(() => {
        if (!modalBox) {
            setReportData([]); // Reset form data when modal is closed
            setUpdatingSubjectData({})
            setCommonData(intialCommonData)

        }
    }, [modalBox]);

    const handleNumberOfSubjectSelect = (number) => {
        let arr = new Array(number).fill({ sn: 0, email: '', totalQuestions: 1, attemptedQuestions: 1, correctMarks: 1, wrongMarks: 1, totalAttempt: 1 })
        let newArr = arr.map((element, index) => {
            return {
                sn: index + 1, email: '', totalQuestions: 1, attemptedQuestions: 1, correctMarks: 1, wrongMarks: 1, totalAttempt: 1
            }
        })
        setReportData(newArr)
    }
    const handleChange = (index, fieldName, value) => {
        let copy = [...reportData]
        copy[index] = { ...copy[index], [fieldName]: value }
        setReportData(copy)
    }


    return (
        <>
            {
                !updatingSubjectData?.sn ? <Modal onHide={setModalBox} show={modalBox} dialogClassName="custom-modal-width" size="lg" backdrop="static"
                    keyboard={false} >
                    <Fragment>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="mb-0">Add Reports</h5>
                                        <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
                                    </div>
                                    <div className="card-body pt-2 mt-0">
                                        <div className="row">
                                            <div className="col-12 d-flex justify-content-around  ">
                                                <div className="col-5 d-flex ">
                                                    <div className="col-sm-4 pt-2">
                                                        <div className="mb-3" >
                                                            <label htmlFor='testseries' className="form-label text-primary">Test Series<span className="required">*</span></label>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-8">
                                                        <div className="mb-3 " >
                                                            <Select
                                                                value={{ value: commonData.testSeries, label: commonData.testSeries }}
                                                                onChange={(selectedOption) => setCommonData((preData) => ({ ...preData, courseId: selectedOption.value.courseId, testSeries: selectedOption.value.title, testSeriesSn: selectedOption.value.sn }))}
                                                                options={testSeries?.map((item) => ({ value: item, label: item.title }))}
                                                                styles={setDropDownStyle()}
                                                            />
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="col-5 d-flex">
                                                    <div className="col-sm-4 pt-2">
                                                        <div className="mb-3" >
                                                            <label htmlFor='testseries' className="form-label text-primary">Test <span className="required">*</span></label>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-8">
                                                        <div className="mb-3 " >
                                                            <Select
                                                                value={{ value: commonData.tets, label: commonData.test }}
                                                                onChange={(selectedOption) => setCommonData((preData) => ({ ...preData, packageId: selectedOption.value.packageId, test: selectedOption.value.title }))}
                                                                options={tests?.map((item) => ({ value: item, label: item.title }))}
                                                                styles={setDropDownStyle()}
                                                            />
                                                        </div>

                                                    </div>
                                                </div>


                                            </div>

                                            <div className="col-12 m-auto d-flex  justify-content-around mb-3 ">
                                                <div className="col-5 d-flex">
                                                    <div className="col-sm-4 pt-2">
                                                        <div className="mb-3" >
                                                            <label htmlFor='topic' className="form-label text-primary">Number of Reports <span className="required">*</span></label>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-8">
                                                        <div className="mb-3 " >
                                                            <Select
                                                                //  isDisabled={batchData.sNo}
                                                                //  value={batchData.courseTitle ? { value: batchData.courseTitle, label: batchData.courseTitle } : null}
                                                                onChange={(selectedOption) => handleNumberOfSubjectSelect(selectedOption?.value)}
                                                                options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30].map((number) => ({ value: number, label: number }))}
                                                                styles={setDropDownStyle()}

                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-5 d-flex">
                                                    {/* <div className="col-sm-4 pt-2">
                                                        <div className="mb-3" >
                                                            <label htmlFor='topic' className="form-label text-primary">Number of Reports <span className="required">*</span></label>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-8">
                                                        <div className="mb-3 " >
                                                            <Select
                                                                //  isDisabled={batchData.sNo}
                                                                //  value={batchData.courseTitle ? { value: batchData.courseTitle, label: batchData.courseTitle } : null}
                                                                onChange={(selectedOption) => handleNumberOfSubjectSelect(selectedOption?.value)}
                                                                options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30].map((number) => ({ value: number, label: number }))}
                                                                styles={setDropDownStyle()}

                                                            />
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                            <div className="col-sm-12 m-auto ">
                                                <Table responsive bordered className="text-center mt-0 pt-0 ">
                                                    <thead>
                                                        <tr>
                                                            <th>SN.</th>
                                                            <th>EMAIL ID</th>
                                                            <th className='text-start'>TOTAL Qs.</th>
                                                            <th className='text-start'>ATTEMPTED Qs.</th>
                                                            <th className='text-start'>CORRECT MARKS</th>
                                                            <th className='text-start'>WRONG MARKS</th>
                                                            <th className='text-start'>TOTAL ATTEMPTS</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {reportData?.map((item, index) => (
                                                            <tr key={index}>
                                                                <td style={{ width: "100px" }}><h6 className="sn-font-weight mb-0" > {item.sn}</h6></td>
                                                                <td > <input className='form-control' value={reportData[index].email} type="email" name="" id="" onChange={(e) => handleChange(index, 'email', e.target.value)} /></td>
                                                                <td > <input className='form-control' value={reportData[index].totalQuestions} type="number" name="" id="" onChange={(e) => handleChange(index, 'totalQuestions', Number(e.target.value))} /></td>
                                                                <td style={{ width: "100px" }}> <input className='form-control' value={reportData[index].attemptedQuestions} type="number" name="" id="" onChange={(e) => handleChange(index, 'attemptedQuestions', Number(e.target.value))} /></td>
                                                                <td style={{ width: "100px" }}> <input className='form-control' value={reportData[index].correctMarks} type="number" name="" id="" onChange={(e) => handleChange(index, 'correctMarks', Number(e.target.value))} /></td>
                                                                <td style={{ width: "100px" }}> <input className='form-control' value={reportData[index].wrongMarks} type="number" name="" id="" onChange={(e) => handleChange(index, 'wrongMarks', Number(e.target.value))} /></td>
                                                                <td style={{ width: "100px" }}> <input className='form-control' value={reportData[index].totalAttempt} type="number" name="" id="" onChange={(e) => handleChange(index, 'totalAttempt', Number(e.target.value))} /></td>
                                                            </tr>
                                                        ))
                                                        }
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer pb-0">
                                        <button className='btn btn-danger  btn-xs ' onClick={() => setModalBox(false)}>Cancel</button>
                                        <button className='btn btn-primary  btn-xs ' onClick={handleSaveReports}>Add</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Fragment >
                </Modal >
                    :
                    <Modal onHide={setModalBox} show={modalBox} size="md" backdrop="static"
                        keyboard={false} >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel"> Update Subject</h5>
                                <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
                            </div>
                            <div className="modal-body">
                                <div className="mb-3">
                                    <label htmlFor="subjectName" className="form-label text-primary">Course<span className="required">*</span></label>
                                    <div className="basic-dropdown">
                                        <Select
                                            value={{ value: updatingSubjectData.courseTitle, label: updatingSubjectData.courseTitle }}
                                            onChange={(selectedOption) => setUpdatingSubjectData({ ...updatingSubjectData, courseId: selectedOption?.value.courseId, courseTitle: selectedOption?.value.courseTitle })}
                                            options={testSeries?.map((course) => ({ value: course, label: course.courseTitle }))}
                                            styles={setDropDownStyle()}
                                        />
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="subjectName" className="form-label text-primary">Subject<span className="required">*</span></label>
                                    <input autoFocus className='form-control' type="text" name="" id="subjectName" value={updatingSubjectData.subjectName} onChange={(e) => setUpdatingSubjectData({ ...updatingSubjectData, subjectName: e.target.value })} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="sort" className="form-label text-primary">Order No.<span className="required">*</span></label>
                                    <input className='form-control' type="number" name="" id="sort" value={updatingSubjectData.subjectOrderNo} onChange={(e) => setUpdatingSubjectData({ ...updatingSubjectData, subjectOrderNo: e.target.value })} />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger btn-xs" onClick={() => setModalBox(false)}>Close</button>
                                <button type="button" className="btn btn-primary btn-xs " onClick={handleSaveReports}>Update</button>
                            </div>
                        </div>
                    </Modal>
            }
        </>
    )
})
export default TestDummyReportModal;




























































































































































