import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Badge, Button, Spinner } from 'react-bootstrap';
import BatchModal from './BatchModal';
import { MdDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { MdKeyboardArrowUp } from "react-icons/md";
import swal from 'sweetalert';
import axios from 'axios';
import { toast } from 'react-toastify';
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import './Batch.css'
import { BiEdit } from "react-icons/bi";
import Select from 'react-select';
import { getCourses, setDropDownStyle } from '../CommonHelper';
import { Pagination } from '@mui/material';
import { showTableResponse, sortInAlphabeticalOrder } from '../common-helpers/commonServices';


const BatchManagement = () => {
    const childRef = useRef();
    const navigate = useNavigate();
    const [batches, setBatches] = useState([]);
    const [courseNameArray, setCourseNameArray] = useState([]);
    const [selectedCourseName, setSelectedCourseName] = useState('');
    const [courseWiseSelectedBatches, setCourseWiseSelectedBatches] = useState([]);
    const [selectedBatchType, setSelectedBatchType] = useState('');
    const [selectedCurrentBatchType, setSelectedCurrentBatchType] = useState('');
    const [unAssociatedBatches, setUnassociatedBatches] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [checked, setChecked] = useState(batches);
    const [unchecked, setUnChecked] = useState(true);
    const [loadBatches, setBatchLoading] = useState(false);
    const [totalActiveBatches, setTotalActiveBatches] = useState(0);
    const [totalInactiveBatches, setTotalInactiveBatches] = useState(0);
    const [totalArchivedBatches, setTotalArchivedBatches] = useState(0);

    // search functionality    
    const [searchTerm, setSearchTerm] = useState('');
    useEffect(() => {
        const filteredBatches = batches?.filter(
            item => item?.batchName?.toLowerCase().includes(searchTerm?.toLowerCase())
        );
        setChecked(filteredBatches)
    }, [searchTerm])
    const handleSearch = (e) => {
        setSearchTerm(e.target.value)
    }

    //set Table data
    useEffect(() => {
        axios.get(BACKEND_SERVER_URL + '/getBatches').then((res) => {
            let batches = res.data.batches;  //recieving batch here
            console.log(batches)
            if (batches) {
                setTotalActiveBatches(batches.filter(batch => batch.status == 'ACTIVE' && batch.hide == 0).length)
                setTotalInactiveBatches(batches.filter(batch => batch.status == 'Disactive' && batch.hide == 0).length)
                let UnassociatedBatches = batches.filter(batch => batch.hide == 1 || batch.hide == null)
                setUnassociatedBatches(UnassociatedBatches)
                //  setTotalArchivedBatches(UnassociatedBatches.length)
                batches = batches.filter(batch => batch.hide == 0)
                setBatches(batches);
                setChecked(batches);
            }
        })
        getCourses().then((res) => {
            let uniqueCourseTitles = [...new Set(res.map(course => course.courseTitle))];
            uniqueCourseTitles.unshift("All Courses")
            setCourseNameArray(uniqueCourseTitles);
        })
    }, [loadBatches])

    const handleChecked = (sn) => {
        let temp = checked.map((data) => {
            if (sn === data.sn) {
                return { ...data, inputchecked: !data.inputchecked };
            }
            return data;
        });
        setChecked(temp);
    };
    const handleCheckedAll = (value) => {
        console.log(value)
        let temp = checked.map((data) => {
            return { ...data, inputchecked: value };
        });
        setChecked(temp);
        setUnChecked(!unchecked);
    };

    const pageSize = 50;
    const lastIndex = currentPage * pageSize;
    const firstIndex = lastIndex - pageSize;
    const records = checked?.slice(firstIndex, lastIndex);

    const npage = Math.ceil(checked.length / pageSize)
    const number = [...Array(npage + 1).keys()].slice(1)

    //handle delete course
    const handleDeleteBatch = (sn) => {
        swal({
            title: "Are you sure?",
            text:
                "Once deleted, you will not be able to recover this batch",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                axios.delete(BACKEND_SERVER_URL + '/deleteBatch/' + sn).then((res) => {
                    if (res) {
                        swal("Poof! Your batch has been deleted!", {
                            icon: "success",
                        });
                        setBatchLoading(!loadBatches);
                    }
                })
            }
        }).catch((error) => {
            console.log(error)
            toast.error(error.response.data.message)
        })
    }

    //common bulk handler
    const commonHandler = (action) => {
        let ids = records.filter(record => record.inputchecked).map(record => record.sn);
        if (ids.length <= 0) {
            toast.error('Please select atleast one batch');
            return;
        }
        const postData = { ids: ids };
        switch (action) {
            case "deleteBulk":
                swal({
                    title: "Are you sure?",
                    text:
                        "Once deleted, you will not be able to recover these batch",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                }).then((yes) => {
                    if (yes) {
                        axios.post(BACKEND_SERVER_URL + '/deleteBatches', postData).then((response) => {
                            if (response) {
                                toast.success(response.data.message);
                                setBatchLoading(!loadBatches)
                            }
                        }).catch((error) => {
                            console.log(error)
                            toast.error(error.response.data.message)
                        })

                    }
                })
                break;
            case "activateBulk":
                swal({
                    title: "Are you sure?",
                    text:
                        "Do you really want to activate these batch",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                }).then((yes) => {
                    if (yes) {
                        axios.post(BACKEND_SERVER_URL + '/activateBatches', postData).then((response) => {
                            if (response) {
                                toast.success(response.data.message);
                                setBatchLoading(!loadBatches)

                            }
                        }).catch((error) => {
                            console.log(error)
                            toast.error(error.response.data.message)
                        })

                    }
                })
                break;
            case "InActivateBulk":
                swal({
                    title: "Are you sure?",
                    text:
                        "Do you really want to inactivate these batches",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                }).then((yes) => {
                    if (yes) {
                        axios.post(BACKEND_SERVER_URL + '/inactivateBatches', postData).then((response) => {
                            if (response) {
                                toast.success(response.data.message);
                                setBatchLoading(!loadBatches)

                            }
                        }).catch((error) => {
                            console.log(error)
                            toast.error(error.response.data.message)
                        })

                    }
                })
                break;
        }
    }
    const handleTabClick = (action) => {
        if (action === "activeTab") {
            let active = batches?.filter(batch => batch.status == "ACTIVE" && batch.hide == 0)
            console.log(active)
            setChecked(active)
            /*  setCourseWiseSelectedBatches([]) */

        } else if (action === "inActiveTab") {
            let inactive = batches?.filter(batch => batch.status == 'Disactive');
            setChecked(inactive)
            /*  setCourseWiseSelectedBatches([]) */

        } else if (action == "unAssociatedTab") {
            setChecked(unAssociatedBatches)
            /*  setCourseWiseSelectedBatches([]) */
        }
        else {
            setChecked(batches)
        }
    }
    const selectCourseHandler = (value) => {
        setSelectedCourseName(value)
        if (value == "All Courses") {
            setChecked(batches)
            setCourseWiseSelectedBatches(batches)
            return;
        }
        let selectedBatches = batches.filter((batch) => batch.courseTitle == value)
        selectedBatches = sortInAlphabeticalOrder(selectedBatches, 'batchName')
        setCourseWiseSelectedBatches(selectedBatches)
        setChecked(selectedBatches)
    }
    const selectBatchHandler = (value) => {
        setSelectedBatchType(value)
        if (value == "All Batch") {
            setChecked(courseWiseSelectedBatches.length > 0 ? courseWiseSelectedBatches : checked)
        } else if (value == "Active Batch") {

            let selectedBatches = (courseWiseSelectedBatches.length > 0 ? courseWiseSelectedBatches : checked).filter(batch => batch.status == 'ACTIVE');

            setChecked(selectedBatches)
        } else {
            let selectedBatches = (courseWiseSelectedBatches.length > 0 ? courseWiseSelectedBatches : checked).filter((batch) => batch.status == 'Disactive')
            setChecked(selectedBatches)
        }
    }
    const selectCurrentBatchHandler = (value) => {
        setSelectedCurrentBatchType(value)
        if (value == "All Batch") {
            setChecked(courseWiseSelectedBatches.length > 0 ? courseWiseSelectedBatches : checked)
        } else if (value == "Current Batches") {
            let selectedBatches = (courseWiseSelectedBatches.length > 0 ? courseWiseSelectedBatches : batches).filter(batch => batch.isCurrentBatch == true);
            setChecked(selectedBatches)
        } else {
            let selectedBatches = (courseWiseSelectedBatches.length > 0 ? courseWiseSelectedBatches : batches).filter((batch) => batch.isCurrentBatch == false)
            setChecked(selectedBatches)
        }


    }
    const toggleCurrentBatchHandler = async (sn, courseId, checked) => {
        console.log(sn, courseId, checked)
        try {
            const res = await axios.put(BACKEND_SERVER_URL + '/makeAsCurrentBatch', { sn, courseId })
            if (res.data.statusCode == 200) {
                toast.success(res.data.message)
                setBatchLoading(!loadBatches)
            }
        } catch (error) {
            toast.error(error.response.data.message);
        }
    }
    const handlePageChange = (e, p) => {
        setCurrentPage(parseInt(p));
    }

    return (
        <>
            <div className="row">
                <div className="col-xl-12">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className=" page-title p-2 mb-2">{/*  flex-wrap  */}
                                <div className=' col-8  d-flex justify-content-between'>
                                    <button type="button" className="btn btn-dark btn-sm me-2"
                                        onClick={() => childRef.current.openModal()}
                                    >
                                        + Add Batch
                                    </button>
                                    <div className="col-3">
                                        <div className="basic-dropdown">
                                            <Select className=''
                                                value={{ value: selectedCourseName || "All Courses", label: selectedCourseName || "All Courses" }}
                                                onChange={(selectedOption) => selectCourseHandler(selectedOption.value)}
                                                options={courseNameArray?.map((courseTitle) => ({ value: courseTitle, label: courseTitle }))}
                                                styles={setDropDownStyle()}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className="basic-dropdown ms-1">
                                            <Select className=''
                                                value={{ value: selectedBatchType || "All Batch", label: selectedBatchType || "All Batch" }}
                                                onChange={(selectedOption) => selectBatchHandler(selectedOption.value)}
                                                options={['All Batch', 'Active Batch', 'Inactive Batch']?.map((batchType) => ({ value: batchType, label: batchType }))}
                                                styles={setDropDownStyle()}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className="basic-dropdown ms-1">
                                            <Select className=''
                                                value={{ value: selectedCurrentBatchType || "All Batch", label: selectedCurrentBatchType || "All Batch" }}
                                                onChange={(selectedOption) => selectCurrentBatchHandler(selectedOption.value)}
                                                options={['All Batch', 'Current Batches', 'Previous batches']?.map((batchType) => ({ value: batchType, label: batchType }))}
                                                styles={setDropDownStyle()}
                                            />
                                        </div>
                                    </div>
                                    {/*    <button type="button" className="btn btn-dark btn-xs ms-2 " onClick={handleShowAllCourses}> show All</button> */}
                                </div>
                                <div className="input-group search-area mb-md-0 mb-3">
                                    <input type="text" className="form-control" onChange={handleSearch} value={searchTerm} placeholder="Search here..." />
                                    <span className="input-group-text"><Link to={"#"}>
                                        <svg width="15" height="15" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z" fill="#01A3FF" />
                                        </svg>
                                    </Link></span>
                                </div>
                            </div>

                            <div className='page-title pt-1 mb-1 pb-2' style={{ zIndex: "0" }}>
                                <div className="container-fluid p-1 pb-0">
                                    <div className="row">
                                        <div className="col-12 col-sm-6 d-sm-flex ">
                                            <div className="col-12 col-sm-6 mb-3 mb-sm-0 ">
                                                <button
                                                    onClick={() => handleTabClick('allBatches')}
                                                    type="button"
                                                    className="btn btn-primary btn-sm btn-block m-auto ps-0 pe-3 w-75"
                                                >
                                                    All Batches: {batches.length}
                                                </button>
                                            </div>
                                            <div className="col-12 col-sm-6  ">
                                                <button
                                                    onClick={() => handleTabClick('activeTab')}
                                                    type="button"
                                                    className="btn btn-success btn-sm btn-block m-auto ps-0 pe-3 w-75"
                                                >
                                                    Active: {totalActiveBatches}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 d-sm-flex mt-3 mt-sm-0 ">
                                            <div className="col-12 col-sm-6 mb-3 mb-sm-0 ">
                                                <button
                                                    onClick={() => handleTabClick('inActiveTab')}
                                                    type="button"
                                                    className="btn btn-warning btn-block btn-sm  m-auto ps-0 w-75"
                                                >
                                                    Inactive: {totalInactiveBatches}
                                                </button>
                                            </div>
                                            <div className="col-12 col-sm-6 ">
                                                <button
                                                    onClick={() => handleTabClick('unAssociatedTab')}
                                                    type="button"
                                                    className="btn btn-danger btn-block btn-sm  m-auto ps-0 w-75"
                                                >
                                                    UnAssociated: {unAssociatedBatches?.length}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 wow fadeInUp" data-wow-delay="1.5s">
                            <div className="d-sm-flex text-center justify-content-between align-items-center">
                                <div>{
                                    records[0]?.hide != 0 ?
                                        <Button variant="danger ms-3" size='xs' onClick={(e) => commonHandler("deleteBulk")}>Delete</Button> :
                                        <>
                                            <Button variant="success ms-3 " size='xs' onClick={(e) => commonHandler("activateBulk")}>Active</Button>
                                            <Button variant="warning ms-3" size='xs' onClick={(e) => commonHandler("InActivateBulk")}>Inactive</Button>
                                        </>
                                }
                                </div>

                                <div className='dataTables_info text-dark'>
                                    Records: {checked.length}
                                </div>
                                {checked.length > pageSize ? <div className="dataTables_paginate paging_simple_numbers justify-content-center  mb-0 pt-2"
                                    id="example-student_wrapper">
                                    <Pagination count={number.length} variant="outlined" color='primary' size="small" page={currentPage} siblingCount={1} boundaryCount={2} onChange={handlePageChange} />
                                </div> : null}
                            </div>
                            <div className="table-responsive full-data" style={{ paddingBottom: "1px", transform: 'rotateX(180deg)' }}>
                                <div id="example-student_wrapper" className="dataTables_wrapper no-footer">

                                    <table className="table-responsive-lg table display dataTablesCard student-tab 
                                    dataTable no-footer mt-0 mb-0" style={{ transform: 'rotateX(180deg)' }} id="example-student" >
                                        <thead style={{ backgroundColor: "#cccccc" }}>
                                            <tr className='text-center text-dark border border-left'>
                                                <th className=' custom-table-header  p-2'>SN</th>
                                                <th className=' custom-table-header  p-2'>
                                                    <input type="checkbox" className="form-check-input" id="checkAll"
                                                        /*   checked={!unchecked} */
                                                        onClick={() => handleCheckedAll(unchecked)}
                                                    />
                                                </th>
                                                <th className='p-2  custom-table-header '>STATUS</th>
                                                <th className=' text-start custom-table-header  p-2' >BATCH NAME</th>
                                                <th className='custom-table-header  custom-table-header p-2' >CURRENT</th>
                                                {/* <th className='text-start  custom-table-header p-2'>DESCRIPTION</th> */}
                                                <th className='text-start  custom-table-header p-2'>PRICE</th>
                                                <th className='p-2 custom-table-header '>CURRENT YEAR</th>
                                                <th className='p-2 custom-table-header '>SUBSCRIBERS</th>
                                                <th className='p-2 text-start custom-table-header '>COURSE NAME</th>
                                                <th className='p-2 custom-table-header '>START DATE</th>
                                                <th className='p-2 custom-table-header '>ADMIN DATE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {records.length > 0 ? records.map((item, ind) => (
                                                <tr key={ind} className={`${ind % 2 == 1 ? 'custom row-height' : 'row-height'}`} >
                                                    <td className='text-center p-1 column-width' style={{ border: "1px solid #cccccc", width: "65px" }} ><h6 className="sn-font-weight  mb-0"> {item.sn}</h6></td>
                                                    <td className='text-center p-1 column-width' style={{ border: "1px solid #cccccc", width: "65px" }}>
                                                        <div className="checkbox me-0 align-self-center">
                                                            <div className="custom-control custom-checkbox ">
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    id={`stud-${item.sn}`}
                                                                    checked={item.inputchecked}
                                                                    onChange={() => handleChecked(item.sn)}
                                                                />
                                                                <label className="custom-control-label" htmlFor={`stud-${item.sn}`}></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='text-center p-1 active-col-width' style={{ border: "1px solid #cccccc", width: "80px" }}>
                                                        {item.status == 'ACTIVE' && <h6 className="text-success mb-0 ">Active </h6>}
                                                        {item.status == 'Disactive' && <h6 className="text-danger mb-0">Inactive </h6>}
                                                    </td>
                                                    <td className='p-1' style={{ border: "1px solid #cccccc" }}>
                                                        <div className="d-flex justify-content-between ">
                                                            <h6 className='mb-0 ' style={{ fontSize: '13px', fontWeight: 'bold' }}>{item.batchName}{' '}
                                                                <CiEdit cursor='pointer' title='Edit Batch' size={18} className='ms-3' onClick={() => childRef.current.openModal(item)} />
                                                                <MdDeleteOutline color="red" style={item.hide == 0 ? { pointerEvents: 'none', opacity: 0.5 } : null} title='Delete Batch' cursor='pointer' size={18} className='ms-2' onClick={() => { handleDeleteBatch(item.sn) }}
                                                                />
                                                            </h6>
                                                        </div>
                                                    </td>

                                                    <td className='p-1 column-width  ' style={{ border: "1px solid #cccccc", width: "60px" }}>
                                                        <h6 className=" form-check form-switch  ">
                                                            <div className="d-flex justify-content-center ">
                                                                <input disabled={item.status == 'Disactive' || item.isCurrentBatch} className="form-check-input cursor-pointer text-center" type="checkbox" role="switch" id="flexSwitchCheckDefault" style={{ width: "35px", height: "16px", backgroundColor: `${item.isCurrentBatch ? "#E0F5F0" : "#FFE8E8"}`, border: `1px solid ${item.isCurrentBatch ? 'green' : "red"}` }} checked={item.isCurrentBatch} onChange={() => toggleCurrentBatchHandler(item.sn, item.courseId, item.isCurrentBatch)} />
                                                            </div>
                                                        </h6>
                                                    </td>





                                                    <td className='p-1 active-col-width' style={{ border: "1px solid #cccccc", width: '80px' }}><h6 className="mb-0 price-color">₹ {item.price}</h6></td>
                                                    <td className='p-1 text-center active-col-width' style={{ border: "1px solid #cccccc", width: '80px' }}><h6 className="mb-0">{item.currentYear}</h6></td>
                                                    <td className='p-1 text-center' style={{ border: "1px solid #cccccc", width: "65px" }}>
                                                        <h6 className="mb-0 cursor-pointer" onClick={() => navigate('/subscribers', { state: { batchId: item.batchId, courseId: item.courseId } })}>
                                                            <Badge bg="" className='badge-primary light badge-sm ms-2 cursor-pointer pe-3 ps-3' >{item.subscribers}</Badge>

                                                        </h6>
                                                    </td>
                                                    <td className='text-start p-1' style={{ border: "1px solid #cccccc" }}>
                                                        <h6 className="mb-0" style={{ fontSize: '13px', fontWeight: 'bold' }}>{item.courseTitle}</h6>
                                                    </td>
                                                    <td className='text-center p-1' style={{ border: "1px solid #cccccc", width: "65px" }}><h6 className="mb-0">{new Date(item?.startDate?.replace(/\s+/g, ""))?.toLocaleDateString('en-GB')}</h6></td>
                                                    <td className='text-center p-1' style={{ border: "1px solid #cccccc", width: "65px" }}><h6 className="mb-0">{new Date(item?.adminCloseDate?.replace(/\s+/g, ""))?.toLocaleDateString('en-GB')}</h6></td>

                                                </tr>
                                            )) :
                                                <tr>
                                                    <td colSpan="9" className="text-center">
                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                                                            {
                                                                showTableResponse(batches, checked)
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <BatchModal ref={childRef} loadBatches={loadBatches} setBatchLoading={setBatchLoading} />
        </>
    )
}

export default BatchManagement;