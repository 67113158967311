import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import { MdDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";

import swal from 'sweetalert';
import axios from 'axios';
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import { Pagination } from '@mui/material';
import { bulkOperations, deleteItem, handleChecked, handleCheckedAll, handleSearch, showTableResponse } from '../common-helpers/commonServices';
import ProdutsDetailsTabsAtTop from '../Common-Components/ProdutsDetailsTabsAtTop';
import SearchBox from '../Common-Components/SearchBox';
import { toast } from 'react-toastify';
import QuizSubjectModal from './QuizSubjectModal';


const QuizSubjects = () => {
    const childRef = useRef();
    const [quizSubjects, setQuizSubjects] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [checked, setChecked] = useState(quizSubjects);
    const [unchecked, setUnChecked] = useState(true);

    const [loadSubject, setSubjectLoading] = useState(false);
    const [totalActiveSubject, setTotalActiveSubject] = useState([]);
    const [totalInactiveSubjects, setTotalInactiveSubjects] = useState([]);
    const [totalArchivedSubjects, setTotalArchivedSubjects] = useState([]);

    console.log(quizSubjects)
    useEffect(() => {
        axios.get(BACKEND_SERVER_URL + '/getQuizSubjects').then((res) => {
            let subjects = res.data.subjects;  //recieving batch here           
            if (subjects) {
                setTotalActiveSubject(subjects.filter(batch => batch.status == 1 && batch.hide == 0))
                setTotalInactiveSubjects(subjects.filter(batch => batch.status == 0 && batch.hide == 0))
                let archivedSubjects = subjects.filter(batch => batch.hide == 1)
                setTotalArchivedSubjects(archivedSubjects)
                let activeInactiveSubjects = subjects.filter(batch => batch.hide == 0)
                setQuizSubjects(activeInactiveSubjects);
                setChecked(activeInactiveSubjects);
            }
        }).catch((error) => {
            console.log(error)
            toast.error(error.response.data.message);
        })
    }, [loadSubject])

    const pageSize = 20;
    const lastIndex = currentPage * pageSize;
    const firstIndex = lastIndex - pageSize;
    const records = checked?.slice(firstIndex, lastIndex);
    const npage = Math.ceil(checked.length / pageSize)
    const number = [...Array(npage + 1).keys()].slice(1)

    return (
        <>
            <div className="row">
                <div className="col-xl-12">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className=" page-title p-2 mb-2">{/*  flex-wrap  */}
                                <div className="row w-100 d-flex justify-content-between">
                                    <div className=' col-sm-6 d-flex justify-content-start'>
                                        <button type="button" className=" btn btn-dark btn-sm me-2 w-25"
                                            onClick={() => childRef.current.openModal()}
                                        >
                                            + Add
                                        </button>
                                    </div>
                                    <div className=" col-sm-6 input-group search-area mb-md-0 mb-3">
                                        <SearchBox setChecked={setChecked} dataArray={quizSubjects} searchingProperty={'facultyName'} />
                                    </div>
                                </div>
                            </div>
                            <ProdutsDetailsTabsAtTop setChecked={setChecked} totalItems={quizSubjects} totalActiveItems={totalActiveSubject} totalInactiveItems={totalInactiveSubjects} totalArchivedItems={totalArchivedSubjects} />
                        </div>
                        <div className="col-xl-12 wow fadeInUp" data-wow-delay="1.5s">
                            <div className="d-sm-flex text-center justify-content-between align-items-center">
                                <div>
                                    <Button variant="danger ms-3" size='xs' onClick={(e) => bulkOperations("delete", records, '/hideQuizSubjects', 'Subjects', setSubjectLoading, loadSubject)}>Delete</Button>
                                    <Button variant="success ms-3 " size='xs' onClick={(e) => bulkOperations("activate", records, '/activateQuizSubjects', 'Subjects', setSubjectLoading, loadSubject)}>Active</Button>
                                    <Button variant="warning ms-3" size='xs' onClick={(e) => bulkOperations("InActivate", records, '/inActivateQuizSubjects', 'Subjects', setSubjectLoading, loadSubject)}>Inactive</Button>
                                </div>

                                <div className='dataTables_info text-dark'>
                                    Records: {checked.length}
                                </div>
                                {checked.length > pageSize ? <div className="dataTables_paginate paging_simple_numbers justify-content-center  mb-0 pt-2"
                                    id="example-student_wrapper">
                                    <Pagination count={number.length} variant="outlined" color='primary' size="small" page={currentPage} siblingCount={1} boundaryCount={2} onChange={(e, p) => setCurrentPage(parseInt(p))} />
                                </div> : null}
                            </div>
                            <div className="table-responsive full-data" style={{ paddingBottom: "1px", transform: 'rotateX(180deg)' }}>
                                <div id="example-student_wrapper" className="dataTables_wrapper no-footer">
                                    <table className="table-responsive-lg table display dataTablesCard student-tab 
                                    dataTable no-footer mt-0 mb-0" style={{ transform: 'rotateX(180deg)' }} id="example-student" >
                                        <thead style={{ backgroundColor: "#cccccc" }}>
                                            <tr className='text-center text-dark border border-left'>
                                                <th className=' custom-table-header  p-2'>SN</th>
                                                <th className=' custom-table-header  p-2'>
                                                    <input type="checkbox" className="form-check-input" id="checkAll"
                                                        /*   checked={!unchecked} */
                                                        onClick={() => handleCheckedAll(unchecked, checked, setChecked, setUnChecked)}
                                                    />
                                                </th>
                                                <th className='p-2 text-start  custom-table-header '>STATUS</th>
                                                <th className=' text-start custom-table-header  p-2' > SUBJECT</th>
                                                {/* <th className='p-2 custom-table-header '>DATE</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {records.length > 0 ? records.map((item, ind) => (
                                                <tr key={ind} className={`${ind % 2 == 1 ? 'custom row-height' : 'row-height'}`} >
                                                    <td className='text-center p-1 column-width' style={{ border: "1px solid #cccccc", width: "65px" }} ><h6 className="sn-font-weight mb-0"> {item.sn}</h6></td>
                                                    <td className='text-center p-1 column-width' style={{ border: "1px solid #cccccc", width: "65px" }}>
                                                        <div className="checkbox me-0 align-self-center">
                                                            <div className="custom-control custom-checkbox ">
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    id={`stud-${item.sn}`}
                                                                    checked={item.inputchecked}
                                                                    onChange={() => handleChecked(item.sn, setChecked, checked)}
                                                                />
                                                                <label className="custom-control-label" htmlFor={`stud-${item.sn}`}></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='text-start p-1 active-col-width' style={{ border: "1px solid #cccccc", width: "80px" }}>
                                                        {item.status === 1 && <h6 className="text-success mb-0 ">Active </h6>}
                                                        {item.status == 0 && <h6 className="text-danger mb-0">Inactive </h6>}
                                                    </td>
                                                    <td className='p-1' style={{ border: "1px solid #cccccc" }}>
                                                        <div className="d-flex justify-content-between ">
                                                            <h6 className='mb-0 ' style={{ fontSize: '13px', fontWeight: 'bold' }}>{item.subject}{' '}
                                                                <CiEdit cursor='pointer' title='Edit Subject' size={18} className='ms-3'
                                                                    onClick={() => childRef.current.openModal(item)} />
                                                                <MdDeleteOutline color='red' title='Delete Subject' cursor='pointer' size={18} className='ms-2' onClick={() => { deleteItem(item.sn, 'Subject', '/deleteQuizSubject/', setSubjectLoading, loadSubject) }}

                                                                />
                                                            </h6>
                                                        </div>
                                                    </td>
                                                    {/* <td className='text-center date-column-width p-1' style={{ border: "1px solid #cccccc", width: "65px" }}><h6 className="mb-0">{new Date(item?.createdOn?.replace(/\s+/g, ""))?.toLocaleDateString('en-GB')}</h6></td> */}
                                                </tr>
                                            )) :
                                                <tr>
                                                    <td colSpan="9" className="text-center">
                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                                                            {
                                                                showTableResponse(quizSubjects, checked)
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <QuizSubjectModal ref={childRef} loadAcademicYear={loadSubject} setAcademicYearLoading={setSubjectLoading} />
        </>
    )
}

export default QuizSubjects;



