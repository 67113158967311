import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getCourses, getSubjects, setDropDownStyle } from '../CommonHelper';
import { getQuizSectionsForDropdowns, getQuizSubjectsForDropdowns } from '../common-helpers/commonServices';


const SubjectAndSectionDropdown = ({ formData, setFormData, subjectId,  sectionId }) => {
    const [selectedSubject, setSelectedSubject] = useState({ subject: "Select Subject" });
    const [selectedSection, setSelectedSection] = useState({ section: 'Select Section' });
    const [subjects, setSubjects] = useState([]);
    const [sections, setSections] = useState([]);
    const [subjectWiseSections, setSubjectWiseSections] = useState([]);
   
    useEffect(() => {
        getQuizSubjectsForDropdowns().then(res => {
            setSubjects(res);
            if (subjectId) {
                setSelectedSubject(res?.find(subject => subject.sn == subjectId))
            }
        })     
        getQuizSectionsForDropdowns().then(res => {
            setSections(res);
            if (sectionId) {
                setSelectedSection(res?.find(subject => subject.sn == sectionId))
                setSubjectWiseSections(res.filter(section => section.subjectId == subjectId))
            }
        })
       
    }, [])
   console.log(sections)
    const selectSubjectHandler = (selectedValue) => {
        console.log(selectedValue)
        if (selectedValue.subject !== selectedSubject.subject) {          
            setSelectedSubject(selectedValue);          
            setSubjectWiseSections(sections.filter(section => section.subjectId === selectedValue.sn));
            setFormData(prevFormData => ({
                ...prevFormData,
                subjectId: selectedValue.sn,
               
            }));
            setSelectedSection({ section: 'Select Section' });
            setFormData(prevFormData => ({
                ...prevFormData,
                sectionId: 0,              
            }));
        }
    }

    const selectSectionHandler = (selectedValue) => {
        setSelectedSection(selectedValue)
        setFormData({ ...formData, sectionId: selectedValue.sn })
    }
    return (
        <>
            <div className="col-5 d-flex">
                <div className="col-sm-4 pt-2">
                    <div className="mb-3" >
                        <label htmlFor='Course' className="form-label text-primary">Subject{/* <span className="required">*</span> */}</label>
                    </div>
                </div>
                <div className="col-sm-8">
                    <div className="mb-3 " >
                        <Select
                            value={{ value: selectedSubject, label: selectedSubject.subject }}
                            onChange={(selectedOption) => selectSubjectHandler(selectedOption.value)}
                            options={subjects?.map((item) => ({ value: item, label: item.subject }))}
                            styles={setDropDownStyle()}
                        />
                    </div>

                </div>
            </div>
            <div className="col-5 d-flex">
                <div className="col-sm-4 pt-2">
                    <div className="mb-3" >
                        <label htmlFor='Subject' className="form-label text-primary">Section{/* <span className="required">*</span> */}</label>
                    </div>
                </div>
                <div className="col-sm-8">
                    <div className="mb-3" >
                        <Select
                            isDisabled={selectedSubject.sn ? false : true}
                            value={{ value: selectedSection, label: selectedSection.section }}
                            onChange={(selectedOption) => selectSectionHandler(selectedOption.value)}
                            options={subjectWiseSections?.map((item) => ({ value: item, label: item.section }))}
                            styles={setDropDownStyle()}
                        />
                    </div>

                </div>
            </div>





        </>
    );
}

export default SubjectAndSectionDropdown;






