import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import { checkExistingItem } from '../common-helpers/commonServices';



const NewsTagsModal = forwardRef((props, ref) => {
    const [modalBox, setModalBox] = useState(false);

    let initialData = {
        sn: 0,
        sort: 0,
        subCategory: '',
    }

    const [newsSubCategoryData, setNewsSubCategoryData] = useState(initialData);
    console.log(newsSubCategoryData)
    //check for existing items       
    const [itemAlreadyExist, setItemAlreadyExists] = useState(false);
    const [currentTitle, setCurrentTitle] = useState('');//for updating 
    useEffect(() => {
        if (newsSubCategoryData.subCategory && currentTitle.toLowerCase().trim() != newsSubCategoryData.subCategory.toLowerCase().trim()) { // current title is for updating case
            console.log('enter')
            let timer = setTimeout(() => {
                checkExistingItem('in_newssubcategory', 'subcategory', newsSubCategoryData.subCategory).then(response => { //tablename and columnNane value
                    setItemAlreadyExists(response)
                })

            }, 500);
            return () => clearTimeout(timer);
        }

    }, [newsSubCategoryData.subCategory]);
    useImperativeHandle(ref, () => ({
        openModal(item = {}) {
            console.log(item)
            if (item.sn > 0) {
                setNewsSubCategoryData({ sn: item.sn, subCategory: item.subCategory, sort: item.sort })
                setCurrentTitle(item.subCategory)
            }
            setModalBox(true);

        }
    }));

    const hanldeSaveNewsSubCAtegory = (value) => {
        if (newsSubCategoryData.subCategory.trim().length === 0) {
            toast.error("Please Enter Blog Sub-Category ")
        } else if (itemAlreadyExist && currentTitle.toLowerCase().trim() != newsSubCategoryData.subCategory.toLowerCase().trim()) {
            toast.error("Item Already Exists")
            return;
        }
        axios.post(BACKEND_SERVER_URL + `${!newsSubCategoryData.sn ? '/saveNewsSubCategory' : `/updateNewsSubCategory`}`, newsSubCategoryData).then((response) => {
            if (response.data.statusCode === 201) {
                toast.success(response.data.message)
                props.setAcademicYearLoading(!props.loadAcademicYear);
                setNewsSubCategoryData(initialData)
                setModalBox(false);
            }
        }).catch(error => {
            console.log(error)
            toast.error(error.response.data.message)
        })
    }
    useEffect(() => {
        if (!modalBox) {
            setNewsSubCategoryData(initialData); // Reset form data when modal is closed
            setItemAlreadyExists(false)
            setCurrentTitle('')
        }
    }, [modalBox]);

    return (
        <>
            <Modal onHide={setModalBox} show={modalBox} size="md" backdrop="static"
                keyboard={false} >
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel"> {newsSubCategoryData.sn ? "Update News Sub Category" : "Add News Sub-Category"}</h5>
                        <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                            <label htmlFor="inputAcademicYear" className="form-label text-primary">News Sub-Category<span className="required">*</span></label>
                            <input autoFocus className='form-control' type="text" name="" id="inputAcademicYear" value={newsSubCategoryData.subCategory} onChange={(e) => setNewsSubCategoryData({ ...newsSubCategoryData, subCategory: e.target.value })} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="sort" className="form-label text-primary">Priority<span className="required">*</span></label>
                            <input className='form-control' type="number" name="" id="sort" value={newsSubCategoryData.sort} onChange={(e) => setNewsSubCategoryData({ ...newsSubCategoryData, sort: e.target.value })} />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger btn-xs" onClick={() => setModalBox(false)}>Close</button>
                        <button type="button" className="btn btn-primary btn-xs " onClick={hanldeSaveNewsSubCAtegory}>{newsSubCategoryData.sn ? "Update" : "Add "}</button>
                    </div>
                </div>
            </Modal>

        </>
    )
})
export default NewsTagsModal;