import axios from "axios";
import { BACKEND_SERVER_URL } from "../../../http/http-config";

export function createQuestion(question) {
    return axios.post(BACKEND_SERVER_URL + '/createQuestion', question);
}
export function updateQuestion(question, questionId) {
    return axios.post(BACKEND_SERVER_URL + '/updateQuestion/' + questionId, question);
}
export async function deleteQuestion(questionId) {
    return axios.post(BACKEND_SERVER_URL + '/deleteQuestion/' + questionId);
}

export async function getTestQuestions(packageId, testSeriesSn) {  
    return (await axios.get(BACKEND_SERVER_URL + `/getTestQuestions/${testSeriesSn}/${packageId}`)).data.questions
}
export function setQuestionBoxStyling(questionBoxData, selectedQuestionNumber) {
    if (questionBoxData.isQuestionExisted) {
        return {
            backgroundColor: 'white',
            color: '#303972',
            fontSize: '0.8rem',
            fontWeight: '520',
            width: '1.9rem', height: '1.9rem',
            border: `solid green ${selectedQuestionNumber === questionBoxData.questionNumber ? '.2rem' : '.1rem'}`,
            padding: '.0925rem',
            paddingTop: `${selectedQuestionNumber === questionBoxData.questionNumber ? '.1rem' : '.2rem'}`,
            boxShadow: selectedQuestionNumber === questionBoxData.questionNumber ? '0 0 0.5rem 0.1rem rgba(0, 0, 0, 0.3)' : 'none'
        }
    } else {
        return {
            backgroundColor: 'white',
            color: '#303972',
            fontSize: '0.8rem',
            fontWeight: '520',
            width: '1.9rem', height: '1.9rem',
            border: `solid #ff5722 ${selectedQuestionNumber === questionBoxData.questionNumber ? '.2rem' : '.1rem'}`,
            padding: '.0925rem',
            paddingTop: `${selectedQuestionNumber === questionBoxData.questionNumber ? '.1rem' : '.2rem'}`,
            boxShadow: selectedQuestionNumber === questionBoxData.questionNumber ? '0 0 0.5rem 0.1rem rgba(0, 0, 0, 0.3)' : 'none'
           
        }
    }
}
export function setQuizQuestionBoxStyling(questionBoxData, selectedQuestionNumber) {    
        return {
            backgroundColor: 'white',
            color: '#303972',
            fontSize: '0.9rem',
            fontWeight: '520',
            width: '1.9rem', height: '1.9rem',
            border: `solid green ${selectedQuestionNumber === questionBoxData.questionNumber ? '.2rem' : '.1rem'}`,
            padding: '.0625rem ',
            boxShadow: selectedQuestionNumber === questionBoxData.questionNumber ? '0 0 0.5rem 0.1rem rgba(0, 0, 0, 0.3)' : 'none'

        }
    
}





