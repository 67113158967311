import React, { Fragment, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'
import CourseSampleFile from '../../../download_files/course_sample_file.docx'
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import { CKEditor } from '@ckeditor/ckeditor5-react';

import Input from '../Common-Components/Input';
import FileInput from '../Common-Components/FileInput';
import DropdownInput from '../Common-Components/DropdownInput'
import BatchInput from '../Course/BatchInput';
import { fetchCourseData, formateCourseData, generateUniqueID, getExamTypes, getFaculties, getTypes, validateCourseData } from '../Course/CourseHelper';
import DateInput from '../Common-Components/DateInput';
import '../Course/Course.css'
import CKEditorInput from '../Common-Components/CKEditorInput';
import { fetchTestData, formateTestData, validateTestData } from '../TestManagement/TestHelper';
import { setDropDownStyle } from '../CommonHelper';

let initialFormData = {
    question: '',
    questionNumber: 0,
    option1: '',
    option2: '',
    option3: '',
    option4: '',
    correctAnswer: 'Select Correct Answer',
    description: '',
    difficultyLevel: 0,
    // examType: 'Select Exam Type',
    // examTypeId: 0,
    tags: '',
    videoURL: '',
    quizId: '',
    // testId: 0,
    packageId: '',   
    testSeriesSn: 0
}
const QuestionModal = forwardRef((props, ref) => {
    const [modalBox, setModalBox] = useState(false);

    const [updatingQuestionId, setUpdatingQuestionId] = useState(0);
    // const [examTypesArray, setExamTypesArray] = useState([]);

    const [formData, setFormData] = useState(initialFormData);
    const [updatingDataReady, setUpdatingDataReady] = useState(false);
    //console.log(updatingCourseId)
    useImperativeHandle(ref, () => ({
        openModal(testSeriesSn, packageId, questionNumber, questionData = {}) {
            console.log(questionData)
            setModalBox(true);
            handleChange('questionNumber', questionNumber)
            // getExamTypes().then((res) => {
            //     setExamTypesArray(res);
            // })
            if (questionData.sn) {
                setUpdatingQuestionId(questionData.sn)
                setFormData(questionData)
                handleChange('testSeriesSn', testSeriesSn)
                setUpdatingDataReady(true)
            } else {
                let quizId = generateUniqueID();
                // setTestId(courseId);
                handleChange('quizId', quizId)//property name and value
                handleChange('packageId', packageId)
                handleChange('testSeriesSn', testSeriesSn)
                // handleChange('courseId', courseId)
            }
        }
    }));

    const handleChange = (name, value) => {
        console.log(name, value)
        setFormData((previousData) => ({
            ...previousData,
            [name]: value
        }));
    };
    console.log(formData)
    const handleSubmitTest = () => {
        // let errorMessgae = validateTestData(formData)
        // console.log(errorMessgae)
        // if (errorMessgae) {
        //     toast.error(errorMessgae)
        //     return;
        // }
        axios.post(BACKEND_SERVER_URL + (updatingQuestionId ? `/UpdateQuestion` : '/createQuestion'), formData).then((response) => {
            if (response.data.statusCode == 200) {
                setFormData(initialFormData)
                toast.success(response.data.message)
                props.setLoadQuestions(!props.loadQuestions)
                setModalBox(false);
            }
        }).catch((error) => {
            toast.error(error.response.data.message)
            console.log(error)
        })

    }
    useEffect(() => {
        if (!modalBox) {
            setUpdatingQuestionId(0);
            setFormData(initialFormData); // Reset form data when modal is closed
        }
    }, [modalBox]);

    return (
        <>
    <Modal className="" show={modalBox} onHide={setModalBox} dialogClassName="custom-modal-width" backdrop="static"
                keyboard={false} >
                <Modal.Header>
                    <Modal.Title>{updatingQuestionId ? 'Update Question' : 'Create Question'}</Modal.Title>
                    <Button variant="" className="btn-close" onClick={() => setModalBox(false)}></Button>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-around ">
                                <div className="col-5 d-flex">
                                    <Input inputType="text" handleChangeCallBack={handleChange} labelName="Quiz ID" inputValue={formData?.quizId} inputName='quizId' divSizes={[4, 8]} />
                                    {/* <div className="col-sm-4 pt-2">
                                        <div className="mb-3" >
                                            <label htmlFor='news' className="form-label text-primary">Exam Type<span className="required">*</span></label>
                                        </div>
                                    </div>
                                    <div className="col-sm-8">
                                        <div className="mb-3">
                                            <Select
                                                value={{ value: formData.examType, label: formData.examType }}
                                                onChange={(selectedOption) => setFormData({ ...formData, examTypeId: selectedOption.value.Sn, examType: selectedOption.value.exambody })}
                                                options={examTypesArray?.map((item) => ({ value: item, label: item.exambody }))}
                                                styles={setDropDownStyle()}
                                            />
                                        </div>
                                    </div> */}

                                </div>
                                <div className="col-5 d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="Difficulty Level" inputType="number" inputValue={formData?.difficultyLevel} inputName='difficultyLevel' divSizes={[4, 8]} />
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-around ">
                                <div className="col-5 d-flex">
                                    <Input inputType="text" handleChangeCallBack={handleChange} labelName="Tags" inputValue={formData?.tags} inputName='tags' divSizes={[4, 8]} />
                                </div>
                                <div className="col-5 d-flex">
                                    <DropdownInput handleChangeCallBack={handleChange} labelName="Correct Answer" selectedValue={formData?.correctAnswer} inputName='correctAnswer' dropdownValuesArray={['A', 'B', 'C', 'D']} />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <CKEditorInput handleChangeCallBack={handleChange} labelName="Question Content" inputValue={formData?.question} inputName='question' />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <CKEditorInput handleChangeCallBack={handleChange} labelName="Option (A)" inputValue={formData?.option1} inputName='option1' />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <CKEditorInput handleChangeCallBack={handleChange} labelName="Option (B)" inputValue={formData?.option2} inputName='option2' />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <CKEditorInput handleChangeCallBack={handleChange} labelName="Option (C)" inputValue={formData?.option3} inputName='option3' />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <CKEditorInput handleChangeCallBack={handleChange} labelName="Option (D)" inputValue={formData?.option4} inputName='option4' />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <CKEditorInput handleChangeCallBack={handleChange} labelName="Description" inputValue={formData?.description} inputName='description' />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="Video URL" inputType="text" inputValue={formData?.videoURL} inputName='videoURL' divSizes={[2, 10]} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger light" className='btn-sm' onClick={() => setModalBox(false)}>Close</Button>
                    <Button variant="primary " className='btn-sm ' onClick={handleSubmitTest}>{updatingQuestionId ? 'Update Question' : 'Create Question'}</Button>
                </Modal.Footer>
            </Modal>
            

        </>
    )
})
export default QuestionModal;