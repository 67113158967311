import React, { Fragment, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify'
import { BACKEND_SERVER_URL } from '../../../../http/http-config';
import Select from 'react-select';

import DropdownInput from '../../Common-Components/DropdownInput'
import CKEditorInput from '../../Common-Components/CKEditorInput';
import { setDropDownStyle } from '../../CommonHelper';

let initialFormData = {
    question: '',
    questionNumber: 0,
    option1: '',
    option2: '',
    option3: '',
    option4: '',
    correctAnswer: 'Select Correct Answer',
    description: '',
    topicId: '',
    sectionId: ''
}
const TopicQuestionModal = forwardRef((props, ref) => {
    const [modalBox, setModalBox] = useState(false);

    const [updatingQuestionId, setUpdatingQuestionId] = useState(0);
    // const [examTypesArray, setExamTypesArray] = useState([]);

    const [formData, setFormData] = useState(initialFormData);
    const [updatingDataReady, setUpdatingDataReady] = useState(false);
    //console.log(updatingCourseId)
    useImperativeHandle(ref, () => ({
        openModal(topicId, sectionId, questionNumber, questionData = {}) {
           // console.log(questionData)
            setModalBox(true);
            handleChange('questionNumber', questionNumber)
            handleChange('topicId', topicId)
            handleChange('sectionId', sectionId)
            if (questionData.sn) {
                setUpdatingQuestionId(questionData.sn)
                setFormData(questionData)
                setUpdatingDataReady(true)
            }
        }
    }));

    const handleChange = (name, value) => {
        console.log(name, value)
        setFormData((previousData) => ({
            ...previousData,
            [name]: value
        }));
    };
    console.log(formData)
    const handleSubmitTest = () => {
        // let errorMessgae = validateTestData(formData)
        // console.log(errorMessgae)
        // if (errorMessgae) {
        //     toast.error(errorMessgae)
        //     return;
        // }
        axios.post(BACKEND_SERVER_URL + (updatingQuestionId ? `/updateTopicQuestion` : '/createTopicQuestion'), formData).then((response) => {
            if (response.data.statusCode == 200) {
                setFormData(initialFormData)
                toast.success(response.data.message)
                props.setLoadQuestions(!props.loadQuestions)
                setModalBox(false);
            }
        }).catch((error) => {
            toast.error(error.response.data.message)
            console.log(error)
        })

    }
    useEffect(() => {
        if (!modalBox) {
            setUpdatingQuestionId(0);
            setFormData(initialFormData); // Reset form data when modal is closed
        }
    }, [modalBox]);

    return (
        <>
            {((updatingQuestionId && updatingDataReady && modalBox) || (!updatingQuestionId && modalBox)) && (<Modal className="" show={modalBox} onHide={setModalBox} dialogClassName="custom-modal-width" backdrop="static"
                keyboard={false} >
                <Modal.Header>
                    <Modal.Title>{updatingQuestionId ? 'Update Question' : 'Create Question'}</Modal.Title>
                    <Button variant="" className="btn-close" onClick={() => setModalBox(false)}></Button>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-around ">
                                <div className="col-5 d-flex">

                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <CKEditorInput handleChangeCallBack={handleChange} labelName="Question Content" inputValue={formData?.question} inputName='question' />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <CKEditorInput handleChangeCallBack={handleChange} labelName="Option (A)" inputValue={formData?.option1} inputName='option1' />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <CKEditorInput handleChangeCallBack={handleChange} labelName="Option (B)" inputValue={formData?.option2} inputName='option2' />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <CKEditorInput handleChangeCallBack={handleChange} labelName="Option (C)" inputValue={formData?.option3} inputName='option3' />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <CKEditorInput handleChangeCallBack={handleChange} labelName="Option (D)" inputValue={formData?.option4} inputName='option4' />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <CKEditorInput handleChangeCallBack={handleChange} labelName="Description" inputValue={formData?.description} inputName='description' />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <div className="col-sm-2 pt-2">
                                        <div className="mb-3" >
                                            <label htmlFor='CorrectAnswer' className="form-label text-primary">Correct Answer<span className="required">*</span></label>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="mb-3 " >
                                            <Select
                                                value={{ value: formData.correctAnswer, label: formData.correctAnswer }}
                                                onChange={(selectedOption) => handleChange('correctAnswer', selectedOption.value)}
                                                options={['A', 'B', 'C', 'D']?.map((item) => ({ value: item, label: item }))}
                                                styles={setDropDownStyle()}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger light" className='btn-sm' onClick={() => setModalBox(false)}>Close</Button>
                    <Button variant="primary " className='btn-sm ' onClick={handleSubmitTest}>{updatingQuestionId ? 'Update Question' : 'Create Question'}</Button>
                </Modal.Footer>
            </Modal>)
            }

        </>
    )
})
export default TopicQuestionModal;