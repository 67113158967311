import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import AddComprehension from './Comprehension';
import { MdDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import swal from 'sweetalert';
import axios from 'axios';
import { toast } from 'react-toastify';
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import parse from 'html-react-parser';

const Comprehension = () => {
    const childRef = useRef();
    const [comprehensions, setComprehensions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [checked, setChecked] = useState(comprehensions);
    const [unchecked, setUnChecked] = useState(true);
    const [loadCourses, setCourseLoading] = useState(false);
    const [totalActiveCourses, setTotalActiveCourses] = useState(0);
    const [totalInactiveCourses, setTotalInactiveCourses] = useState(0);
    const [totalArchivedCourses, setTotalArchivedCourses] = useState(0);

    // search functionality    
    const [searchTerm, setSearchTerm] = useState('');
    useEffect(() => {
        const filteredCourses = comprehensions?.filter(
            item => item?.comprehensionName?.toLowerCase().includes(searchTerm?.toLowerCase())
        );
        setChecked(filteredCourses)
    }, [searchTerm])
    const handleSearch = (e) => {
        setSearchTerm(e.target.value)
    }

    //set Table data
    useEffect(() => {
        axios.get(BACKEND_SERVER_URL + '/getComprehensions').then((res) => {
            let comprehensions = res.data.comprehensions;
            if (comprehensions) {
                setTotalActiveCourses(comprehensions.filter(comprehension => comprehension.status == 1 && comprehension.hide == 0).length)
                setTotalInactiveCourses(comprehensions.filter(comprehension => comprehension.status == 0 && comprehension.hide == 0).length)
                setTotalArchivedCourses(comprehensions.filter(comprehension => comprehension.hide == 1).length)
                comprehensions = comprehensions.filter(comprehension => comprehension.hide == 0)
                setComprehensions(comprehensions);
                setChecked(comprehensions);
            }
        })
    }, [loadCourses])

    const handleChecked = (id) => {
        let temp = checked.map((data) => {
            if (id === data.id) {
                return { ...data, inputchecked: !data.inputchecked };
            }
            return data;
        });
        setChecked(temp);
    };
    const handleCheckedAll = (value) => {
        let temp = checked.map((data) => {
            return { ...data, inputchecked: value };
        });
        setChecked(temp);
        setUnChecked(!unchecked);
    };

    const pageSize = 15;
    const lastIndex = currentPage * pageSize;
    const firstIndex = lastIndex - pageSize;
    const records = checked.slice(firstIndex, lastIndex);

    const npage = Math.ceil(checked.length / pageSize)
    const number = [...Array(npage + 1).keys()].slice(1)
    function prePage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }
    function changeCPage(id) {
        setCurrentPage(id);
    }
    function nextPage() {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }
    //handle delete comprehension
    const handleDeleteCourse = (courseId) => {
        swal({
            title: "Are you sure?",
            text:
                "Once deleted, you will not be able to recover this comprehension",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                axios.delete(BACKEND_SERVER_URL + '/deleteComprehension/' + courseId).then((res) => {
                    if (res.data.statusCode == 200) {
                        swal("Poof! comprehension has been deleted!", {
                            icon: "success",
                        });
                        setCourseLoading(!loadCourses);
                    }
                })
            }
        })
    }

    const getSn = (index) => {
        return comprehensions.length - index - ((currentPage - 1) * pageSize);
    }


    //common bulk handler
    const commonHandler = (action) => {
        let ids = records.filter(record => record.inputchecked).map(record => record.id);
        if (ids.length <= 0) {
            toast.error('Please select atleast one comprehensions');
            return;
        }
        const postData = { ids: ids };
        switch (action) {
            case "deleteBulk":
                swal({
                    title: "Are you sure?",
                    text:
                        "Once deleted, you will not be able to recover these comprehensions",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                }).then((yes) => {
                    if (yes) {
                        axios.post(BACKEND_SERVER_URL + '/deleteComprehensions', postData).then((response) => {
                            if (response) {
                                toast.success(response.data.message);
                                setCourseLoading(!loadCourses)
                                setUnChecked(!unchecked)
                            }
                        }).catch((error) => {
                            toast.error(error.message);
                        });

                    }
                })
                break;
            case "activateBulk":
                swal({
                    title: "Are you sure?",
                    text:
                        "Do you really want to activate these comprehensions",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                }).then((yes) => {
                    if (yes) {
                        axios.post(BACKEND_SERVER_URL + '/activateComprehensions', postData).then((response) => {
                            if (response) {
                                toast.success(response.data.message);
                                setCourseLoading(!loadCourses)
                                setUnChecked(!unchecked)
                            }
                        }).catch((error) => {
                            toast.error(error.message);
                        });

                    }
                })
                break;
            case "InActivateBulk":
                swal({
                    title: "Are you sure?",
                    text:
                        "Do you really want to inactivate these comprehensions",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                }).then((yes) => {
                    if (yes) {
                        axios.post(BACKEND_SERVER_URL + '/inactivateComprehensions', postData).then((response) => {
                            if (response) {
                                toast.success(response.data.message);
                                setCourseLoading(!loadCourses)
                                setUnChecked(!unchecked)
                            }
                        }).catch((error) => {
                            toast.error(error.message);
                        });

                    }
                })
                break;
        }
    }
    const handleTabClick = (action) => {
        if (action === "activeTab") {
            let active = comprehensions.filter(course => course.status == 1 && course.hide == 0)
            setChecked(active)

        } else if (action === "inActiveTab") {
            let inactive = comprehensions.filter(course => course.status == 0 && course.hide == 0);
            setChecked(inactive)

        } else {
            setChecked(comprehensions)
        }
    }
    return (
        <>

            <div className="row">
                <div className="col-xl-12">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className=" page-title p-2 mb-2">{/*  flex-wrap  */}
                                <div className='d-flex justify-content-between'>
                                    <Link to='/add-comprehension'>
                                        <button type="button" className="btn btn-dark btn-sm" >
                                            + Add Comprehension
                                        </button>
                                    </Link>
                                    {/*    <button type="button" className="btn btn-dark ms-3"
                                        onClick={() => childRef.current.openModal("upload course file")}
                                    >
                                        + Import Courses
                                    </button> */}
                                </div>
                                <div className="input-group search-area mb-md-0 mb-3">

                                    <input type="text" className="form-control" onChange={handleSearch} value={searchTerm} placeholder="Search here..." />
                                    <span className="input-group-text"><Link to={"#"}>
                                        <svg width="15" height="15" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z" fill="#01A3FF" />
                                        </svg>
                                    </Link></span>
                                </div>

                            </div>

                            <div className='page-title pt-1 mb-1 pb-2'>
                                <div className="container-fluid p-1 pb-0">
                                    <div className="row">
                                        <div className="col-12 col-sm-6 d-sm-flex ">
                                            <div className="col-12 col-sm-6 mb-3 mb-sm-0 ">
                                                <button
                                                    onClick={() => handleTabClick('allTests')}
                                                    type="button"
                                                    className="btn btn-primary btn-sm btn-block m-auto ps-0 pe-3 w-75"
                                                >
                                                    All Comprehensions: {comprehensions.length}
                                                </button>
                                            </div>
                                            <div className="col-12 col-sm-6  ">
                                                <button
                                                    onClick={() => handleTabClick('activeTab')}
                                                    type="button"
                                                    className="btn btn-success btn-sm btn-block m-auto ps-0 pe-3 w-75"
                                                >
                                                    Active: {totalActiveCourses}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 d-sm-flex mt-3 mt-sm-0 ">
                                            <div className="col-12 col-sm-6 mb-3 mb-sm-0 ">
                                                <button
                                                    onClick={() => handleTabClick('inActiveTab')}
                                                    type="button"
                                                    className="btn btn-warning btn-block btn-sm  m-auto ps-0 w-75"
                                                >
                                                    Inactive: {totalInactiveCourses}
                                                </button>
                                            </div>
                                            <div className="col-12 col-sm-6 ">
                                                <button
                                                    type="button"
                                                    className="btn btn-danger btn-block btn-sm  m-auto ps-0 w-75"
                                                >
                                                    Archive: {totalArchivedCourses}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 wow fadeInUp" data-wow-delay="1.5s">
                            <div className="table-responsive full-data">
                                <div id="example-student_wrapper" className="dataTables_wrapper no-footer">

                                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                                        <div>
                                            <Button variant="danger ms-3" size='xs' onClick={(e) => commonHandler("deleteBulk")}>Delete</Button>
                                            <Button variant="success ms-3 " size='xs' onClick={(e) => commonHandler("activateBulk")}>Active</Button>
                                            <Button variant="warning ms-3" size='xs' onClick={(e) => commonHandler("InActivateBulk")}>Inactive</Button>
                                        </div>
                                        <div className='dataTables_info text-dark'>
                                            Records: {records.length}
                                        </div>
                                        <div className="dataTables_paginate paging_simple_numbers justify-content-center  mb-0 pt-2"
                                            id="example-student_wrapper">
                                            <Link
                                                className="paginate_button previous disabled"
                                                to="#"
                                                onClick={prePage}
                                            >
                                                <i className="fa-solid fa-angle-left" />
                                            </Link>
                                            <span>
                                                {number.map((n, i) => (
                                                    <Link className={`paginate_button ${currentPage === n ? 'current' : ''} rounded-circle  `} key={i}
                                                        onClick={() => changeCPage(n)}
                                                    >
                                                        <span style={{ fontSize: "14px", display: "flex", justifyContent: "center", alignItems: "center" }} >{n}</span>

                                                    </Link>
                                                ))}
                                            </span>
                                            <Link
                                                className="paginate_button next"
                                                to="#"
                                                onClick={nextPage}
                                            >
                                                <i className="fa-solid fa-angle-right" />
                                            </Link>
                                        </div>
                                    </div>

                                    <table className="table-responsive-lg table display dataTablesCard student-tab 
                                    dataTable no-footer mt-0  " id="example-student">
                                        <thead style={{ backgroundColor: "#cccccc" }} className='header-color'>

                                            <tr className='text-center  border border-left'>
                                                <th className='text-dark p-2'>SN</th>
                                                <th className='text-dark p-2'>
                                                    <input type="checkbox" className="form-check-input" id="checkAll" checked={!unchecked}
                                                        onClick={() => handleCheckedAll(unchecked)}
                                                    />
                                                </th>
                                                <th className=' text-dark text-start p-2'>STATUS</th>
                                                <th className='text-dark text-start p-2'>COMPREHENSION NAME </th>
                                                <th className='text-dark text-start p-2'>BEFORE Que.</th>
                                                <th className=' text-dark p-2 text-start'>ASSOCIATED TEST</th>
                                                <th className=' text-dark text-start p-2'>T. QUESTIONS</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {records.length > 0 ? records.map((item, ind) => (
                                                <tr key={ind} className={`${ind % 2 == 1 ? 'custom' : 'row-height'}`}>
                                                    <td className='text-center p-1' style={{ border: "1px solid #cccccc", width: "65px" }}><h6 className="text-primary mb-0 "> {getSn(ind)}</h6></td>
                                                    <td className='text-center p-1' style={{ border: "1px solid #cccccc", width: "65px" }}>
                                                        <div className="checkbox me-0 align-self-center">
                                                            <div className="custom-control custom-checkbox ">
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    id={`stud-${item.id}`}
                                                                    checked={item.inputchecked}
                                                                    onChange={() => handleChecked(item.id)}
                                                                />
                                                                <label className="custom-control-label" htmlFor={`stud-${item.id}`}></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='text-start p-1' style={{ border: "1px solid #cccccc", width: "80px" }}>
                                                        {item.status == 1 && <h6 className="text-success mb-0">Active </h6>}
                                                        {item.status == 0 && <h6 className="text-danger mb-0">Inactive </h6>}
                                                    </td>

                                                    <td className='p-1' style={{ border: "1px solid #cccccc" }}>
                                                        <div className="trans-list ">
                                                            {/* <img src={item.image} alt="" className="avatar avatar-sm me-3" /> */}
                                                            <h6 style={{ fontSize: '13px', fontWeight: 'bold', color: '#303972' }} className='mb-0 '>
                                                                {parse(item.comprehensionName)}{' '}</h6>
                                                            <Link to={`/edit-comprehension/${item.id}`}>
                                                                <CiEdit cursor='pointer' title='Edit Course' size={20} className='ms-3' />
                                                            </Link>
                                                            <MdDeleteOutline color='red' title='Delete Course' cursor='pointer' size={20} className='ms-2' onClick={() => { handleDeleteCourse(item.id) }}
                                                            />

                                                        </div>
                                                    </td>
                                                    <td className='p-1 text-center' style={{ border: "1px solid #cccccc", width: "65px" }}>
                                                        <h6 className="mb-0"> {item.beforeQuestion}</h6>

                                                    </td>
                                                    <td className='p-1 text-start' style={{ border: "1px solid #cccccc" }}>
                                                        <h6 className="mb-0"> {item.testName}</h6>

                                                    </td>
                                                    <td className='p-1 text-center' style={{ border: "1px solid #cccccc", width: "65px" }}>
                                                        <h6 className="mb-0">{item.questionsCount}</h6>
                                                    </td >
                                                </tr>
                                            )) :
                                                <tr>
                                                    <td colSpan="9" className="text-center">
                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                                                            <Spinner animation="grow" variant="info" />
                                                        </div>
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Comprehension;