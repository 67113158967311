import axios from 'axios';
import { v4 as uuid } from 'uuid'
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import { toast } from 'react-toastify';
export function generateUniqueID() {
    const timestamp = String(Date.now()); // Get current timestamp
    const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomId = '';

    for (let i = 0; i < 8; i++) { // Adjusted to fit 20 characters
        randomId += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }

    const uniqueId = timestamp + randomId;
    return uniqueId.substring(0, 20);
}

/* export async function getTypes() {
    return (await axios.get(BACKEND_SERVER_URL + '/getTypes')).data.types
} */

export async function getTypes() {
    try {
        const response = await axios.get(BACKEND_SERVER_URL + '/getTypes');
        return response.data.types;
    } catch (error) {
        console.error('Error fetching types:', error);
        toast.error(error.response.data.message)
        return [];
    }
}


export async function getExamTypes() {
    try {
        const response = await axios.get(BACKEND_SERVER_URL + '/getExamTypes');
       
        return response.data.types;
    } catch (error) {
        console.error('Error fetching exam types:', error);
        toast.error(error.response.data.message)
        return [];
    }
}

export async function getFaculties() {
    try {
        const response = await axios.get(BACKEND_SERVER_URL + '/getCourseFaculties');
        return response.data.faculties;
    } catch (error) {
        console.error('Error fetching faculties:', error);
        toast.error(error.response.data.message)
        return [];
    }
}

export async function fetchCourseData(CourseID) {
    try {
        const response = await axios.get(BACKEND_SERVER_URL + '/getCourse/' + CourseID);
        return response.data.course;
    } catch (error) {
        console.error('Error fetching course data:', error);
        toast.error(error.response.data.message)
        return {};
    }
}

export async function fetchLessionsForCurrrentCourse(CourseID) {
    try {
        let response = await axios.get(BACKEND_SERVER_URL + '/getLessions/' + CourseID)
        return response.data.lessions;
    } catch (error) {
        toast.error(error.response.data.message)
        return [];
    }
}



export async function formateCourseData(data) {
    return ({
        examType: data.ExamBody,
        examTypeId: data.ExamBodyId,
        sNo: data.OrderNo,
        title: data.CourseTitle,
        urlTitle: data.TitleHeading,
        price: data.Price,
        seatBookingPrice: data.SeatBookingPrice,
        discount: data.Discount,
        appDiscount: data.AppDiscount,
        type: data.Type,
        typeId: data.TypeId,
        topicCount: data.TopicCount,
        duration: data.Duration,
        faculty: data.Faculty,
        facultyId: data.FacultyId,
        subscriptionCount: data.Subscriber,
        currentBatchID: data.BatchId,
        description: data.Description,
        imageURL: data.Image,
        pdfURL: data.PDFUrl,
        syllabusPDFURL: data.SyllabusPdf,
        topics: data.Topic,
        tags: data.Tag,
        demoVideoLink: data.MetaTitle,
        metaTitle: data.RootType,
        metaDescription: data.MetaDescription,
        courseId: data.CourseID,
        startDate: data.StartDate,
        admissionStartDate: data.AdmissionStart,
    })
}

export function validateCourseData(formData) {
    if (!formData.examTypeId) {
        return "Exam Body is Required";
    } else if (!formData.title) {
        return "Course Title is Required";
    } else if (!formData.typeId) {
        return "Type is Required";
    } else if (!formData.description) {
        return "Description is Required";
    } else if (!formData.courseId) {
        return "Course ID is Required";
    } else if (!formData.urlTitle) {
        return "URL Title is Required";
    } else if (!formData.currentBatchID) {
        return "Please create atleast one batch for this course";
    } else {
        return null; // Return null if all validations pass
    }
}


