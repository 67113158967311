import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { Badge, Button, Spinner } from 'react-bootstrap';
import { TiTick } from "react-icons/ti";
import { BiEdit } from "react-icons/bi";
import { MdDeleteOutline } from "react-icons/md";
import { CiEdit, CiLock, CiUnlock } from "react-icons/ci";
import swal from 'sweetalert';
import Pagination from '@mui/material/Pagination';
import axios from 'axios';
import { toast } from 'react-toastify';
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import StudentModal from './StudentModal'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { IoSearchSharp } from "react-icons/io5";
import '../common-css/Table.css'
import { showTableResponse } from '../common-helpers/commonServices';
import { exportToExcel } from '../CommonHelper';
import { FaFileExport } from "react-icons/fa";

const StudentManagement = () => {
  const childRef = useRef();
  const [students, setStudents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [checked, setChecked] = useState(students);
  const [unchecked, setUnChecked] = useState(true);
  const [loadStudents, setStudentLoading] = useState(false);
  const [totalActiveStudents, setTotalActiveBatches] = useState(0);
  const [totalInactiveStudents, setTotalInactiveBatches] = useState(0);
  const [totalArchivedStudents, setTotalArchivedBatches] = useState(0);
  const [fileterDate, setFileterDate] = useState({ startDate: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"), endDate: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'") });

  // search functionality    
  const [searchTerm, setSearchTerm] = useState('');
  useEffect(() => {
    const filteredStudents = students?.filter(
      item =>
        item?.firstName?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        item?.email?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        item?.contactNumber?.includes(searchTerm)
    );
    setChecked(filteredStudents)
  }, [searchTerm])
  const handleSearch = (e) => {
    setSearchTerm(e.target.value)
  }

  //set Table data
  useEffect(() => {
    axios.get(BACKEND_SERVER_URL + '/getStudents').then((res) => {
      let students = res.data.students
      let dates = students.map(student => new Date(student.regdDate))
      const oldestDate = new Date(Math.min(...dates));
      setFileterDate({ ...fileterDate, startDate: format(oldestDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'") })

      if (res.data.students) {
        let students = res.data.students;  //recieving batch here
        setTotalActiveBatches(students.filter(student => student.status == 'ACTIVE').length)
        /* setTotalInactiveBatches(students.filter(student => student.status == 0 && student.hide == 0).length)
         setTotalArchivedBatches(students.filter(student => student.hide == 1).length)
         students = students.filter(student => student.hide == 0) */
        setStudents(students);
        setChecked(students);
      }
    }).catch((error) => {
      console.log(error)
      toast.error(error.response.data.message);
    })
  }, [loadStudents])

  const handleChecked = (sn) => {
    let temp = checked.map((data) => {
      if (sn === data.sn) {
        return { ...data, inputchecked: !data.inputchecked };
      }
      return data;
    });
    setChecked(temp);
  };
  const handleCheckedAll = (value) => {
    console.log(value)
    let temp = checked.map((data) => {
      return { ...data, inputchecked: value };
    });
    setChecked(temp);
    setUnChecked(!unchecked);
  };

  const pageSize = 100;
  const lastIndex = currentPage * pageSize;
  const firstIndex = lastIndex - pageSize;
  const records = checked.slice(firstIndex, lastIndex);

  const npage = Math.ceil(checked.length / pageSize)
  const number = [...Array(npage + 1).keys()].slice(1)

  //handle delete student
  const handleDeleteStudent = (studentId) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this student",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axios.delete(BACKEND_SERVER_URL + '/deleteStudent/' + studentId).then((res) => {
          if (res) {
            swal("Poof! Your student has been deleted!", {
              icon: "success",
            });
            setStudentLoading(!loadStudents);
          }
        }).catch((error) => {
          console.log(error)
          toast.error(error.response.data.message);
        })
      }
    })
  }



  //common bulk handler
  const commonHandler = (action) => {
    console.log(records)
    let ids = records.filter(record => record.inputchecked).map(record => record.sn);
    if (ids.length <= 0) {
      toast.error('Please select atleast one student');
      return;
    }
    const postData = { ids: ids };
    switch (action) {
      case "deleteBulk":
        swal({
          title: "Are you sure?",
          text:
            "Once deleted, you will not be able to recover these students",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            axios.post(BACKEND_SERVER_URL + '/deleteStudents', postData).then((response) => {
              if (response) {
                toast.success(response.data.message);
                setStudentLoading(!loadStudents)
                setUnChecked(!unchecked)
              }
            }).catch((error) => {
              console.log(error)
              toast.error(error.response.data.message);
            })

          }
        })
        break;
      case "activateBulk":
        swal({
          title: "Are you sure?",
          text:
            "Do you really want to activate these students",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            axios.post(BACKEND_SERVER_URL + '/activateStudents', postData).then((response) => {
              if (response) {
                toast.success(response.data.message);
                setStudentLoading(!loadStudents)
                setUnChecked(!unchecked)
              }
            }).catch((error) => {
              console.log(error)
              toast.error(error.response.data.message);
            })

          }
        })
        break;
      case "InActivateBulk":
        swal({
          title: "Are you sure?",
          text:
            "Do you really want to inactivate these students",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            axios.post(BACKEND_SERVER_URL + '/inactivateStudents', postData).then((response) => {
              if (response) {
                toast.success(response.data.message);
                setStudentLoading(!loadStudents)
                setUnChecked(!unchecked)
              }
            }).catch((error) => {
              console.log(error)
              toast.error(error.response.data.message);
            })

          }
        })
        break;
    }
  }

  const handleTabClick = (action) => {
    if (action === "activeTab") {
      let active = students?.filter(student => student.status == 'ACTIVE' /* && student.hide == 0 */)
      setChecked(active)

    } else if (action === 'inActiveTab') {
      let inactive = students?.filter(student => student.status == 'Disactive');
      setChecked(inactive)
    } else {
      setChecked(students)
    }
  }

  console.log(fileterDate)
  const handleSearchDateWise = () => {
    let filteredStudent = students.filter(student => student.regdDate >= fileterDate.startDate && student.regdDate <= fileterDate.endDate)
    console.log(filteredStudent)
    setChecked(filteredStudent);
  }
  const handlePageChange = (e, p) => {
    setCurrentPage(parseInt(p));
  }

  const resetLoginDevice = async (email, password, sn) => {
    console.log(email, password, sn)

    try {
      let response = await axios.put(BACKEND_SERVER_URL + '/resetLoginDevice', { email, password, sn })
      if (response.data.statusCode == 200) {
        toast.success(response.data.message)
      }
    } catch (error) {
      toast.error(error.response.data.message)
    }
  }


  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="row">
            <div className="col-xl-12">
              <div className=" page-title  p-2 mb-2">{/*  flex-wrap  */}
                <div className='d-flex justify-content-between'>

                  <button type="button" className="btn btn-dark btn-sm" onClick={() => childRef.current.openModal()}
                  >
                    + Add Student
                  </button>
                  <button type="button" className="ms-3 btn btn-success btn-sm" onClick={() => exportToExcel(checked)}
                  >
                    <FaFileExport />  Export
                  </button>



                  {/*  <button type="button" className="btn btn-primary ms-3"
                    onClick={() => childRef.current.openModal("upload course file")}
                  >
                    + Import Courses
                  </button> */}
                </div>


                <div className="input-group search-area mb-md-0 mb-3">
                  <input type="text" className="form-control" onChange={handleSearch} value={searchTerm} placeholder="Search by Name, Email, and Mobile..." />
                  <span className="input-group-text"><Link to={"#"}>
                    <svg width="15" height="15" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z" fill="#01A3FF" />
                    </svg>
                  </Link></span>
                </div>

              </div>

              <div className='page-title pt-1 mb-1 pb-2' style={{ zIndex: '0' }}>
                <div className="container-fluid p-1 pb-0">
                  <div className="row">
                    <div className="col-12 col-sm-6 d-sm-flex ">
                      <div className="col-12 col-sm-6 mb-3 mb-sm-0 ">
                        <button
                          onClick={() => handleTabClick('allTests')}
                          type="button"
                          className="btn btn-primary btn-sm btn-block m-auto ps-0 pe-3 w-75"
                        >
                          All Students: {students.length}
                        </button>
                      </div>
                      <div className="col-12 col-sm-6  ">
                        <button
                          onClick={() => handleTabClick('activeTab')}
                          type="button"
                          className="btn btn-success btn-sm btn-block m-auto ps-0 pe-3 w-75"
                        >
                          Active: {totalActiveStudents}
                        </button>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 d-sm-flex mt-3 mt-sm-0 ">
                      <div className="col-12 col-sm-6 mb-3 mb-sm-0 ">
                        <button
                          onClick={() => handleTabClick('inActiveTab')}
                          type="button"
                          className="btn btn-warning btn-block btn-sm  m-auto ps-0 w-75"
                        >
                          Inactive: {totalInactiveStudents}
                        </button>
                      </div>
                      {/*  <div className="col-12 col-sm-6 ">
                        <button
                          type="button"
                          className="btn btn-danger btn-block btn-sm  m-auto ps-0 w-75"
                        >
                          Archive: {totalArchivedStudents}
                        </button>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>


            </div>
            <div className="col-xl-12 wow fadeInUp" data-wow-delay="1.5s">
              <div className="dataTables_wrapper no-footer">
                <div className="col-xl-8 d-sm-flex text-center justify-content-around align-items-center">
                  <div className="m-2 d-flex " /* style={{ width: '30%' }} */ >
                    <label className="form-label  text-dark mt-2">Start Date<span className="required">*</span></label>
                    <DatePicker
                      className="form-control full-width full-width-container .react-datepicker-wrapper"
                      selected={new Date(fileterDate.startDate)}
                      // showTimeSelect
                      // timeFormat="HH:mm"
                      onChange={(date) => setFileterDate({ ...fileterDate, startDate: format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'") })}
                      //timeIntervals={15}
                      yearDropdownItemNumber={20}
                      dateFormat="dd/MM/yyyy"
                      //  timeCaption="Time"
                      showYearDropdown
                      scrollableYearDropdown
                      todayButton="Today"

                    />
                  </div>
                  <div className="m-2 d-flex" /* style={{ width: '30%' }} */ >
                    <label className="form-label text-dark mt-2">End Date<span className="required">*</span></label>
                    <DatePicker
                      className="form-control full-width full-width-container .react-datepicker-wrapper"
                      selected={new Date(fileterDate.endDate)}
                      //showTimeSelect
                      //  timeFormat="HH:mm"
                      onChange={(date) => setFileterDate({ ...fileterDate, endDate: format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'") })}
                      //timeIntervals={15}
                      yearDropdownItemNumber={20}
                      dateFormat="dd/MM/yyyy"
                      // timeCaption="Time"
                      showYearDropdown
                      scrollableYearDropdown
                      todayButton="Today"

                    />
                  </div>
                  <div className="m-2">
                    <button type="button" className="btn btn-dark btn-sm" onClick={handleSearchDateWise}
                    >
                      <IoSearchSharp size={18} color='white' /> Search
                    </button>
                  </div>


                </div>
                <div className=" col-12 d-sm-flex text-center justify-content-between align-items-center">
                  <div>
                    <Button variant="danger ms-3" size='xs' onClick={(e) => commonHandler("deleteBulk")}>Delete</Button>
                    <Button variant="success ms-3" size='xs' onClick={(e) => commonHandler("activateBulk")}>Active</Button>
                    <Button variant="warning ms-3" size='xs' onClick={(e) => commonHandler("InActivateBulk")}>Inactive</Button>
                  </div>

                  <div className='dataTables_info text-dark'>
                    Records: {checked.length}
                  </div>
                  {checked.length > pageSize ?
                    <div className=" col-7 dataTables_paginate paging_simple_numbers justify-content-end  mb-0 pt-2"
                      id="example-student_wrapper">
                      {/*  <div className="col-4 d-flex justify-content-end">
                          <label htmlFor="jumpPage" className='me-2 mt-2'>  Jump to Page</label>
                          <input className='form-control p-1' type="number" name="" id="jumpPage" style={{ width: "30%" }} min="1" max={number.length} value={currentPage} onChange={(e) => setCurrentPage(parseInt(e.target.value))} />
                        </div> */}
                      <Pagination count={number.length} variant="outlined" color='primary' size="small" page={currentPage} siblingCount={1} boundaryCount={2} onChange={handlePageChange} />
                    </div> : null}
                </div>
              </div>
              <div className="table-responsive full-data" style={{ paddingBottom: "1px", transform: 'rotateX(180deg)' }}>
                <div id="example-student_wrapper" className="dataTables_wrapper no-footer">

                  <table className="table-responsive-lg table display dataTablesCard student-tab 
                                    dataTable no-footer mt-0  mb-0" id="example-student" style={{ transform: "rotateX(180deg)" }}>
                    <thead style={{ backgroundColor: "#cccccc" }} className='header-color'>
                      <tr className='text-center  text-dark  border border-left'>
                        <th className='p-2 custom-table-header '>SN</th>
                        <th className='p-2 custom-table-header '>
                          <input type="checkbox" className="form-check-input" id="checkAll" checked={!unchecked}
                            onClick={() => handleCheckedAll(unchecked)}
                          />
                        </th>
                        <th className='p-2 text-start custom-table-header '>STATUS</th>
                        <th className=' text-start p-2 custom-table-header  '>STUDENT NAME</th>
                        <th className=' p-2 text-start   custom-table-header '>CONTACT</th>
                        <th className=' p-2  text-start custom-table-header '>EMAIL</th>
                        <th className=' p-2  text-start custom-table-header '>EXAM</th>
                        <th className='  p-2   custom-table-header '>REGISTRATION DATE</th>
                        <th className='  p-2   custom-table-header '>DEVICE ID</th>

                      </tr>
                    </thead>
                    <tbody>
                      {records.length > 0 ? records.map((item, ind) => (
                        <tr key={ind} className={`${ind % 2 == 1 ? 'custom text-center' : 'row-height text-center'}`}>
                          <td className='text-center p-1 column-width ' style={{ border: "1px solid #cccccc" }}><h6 className="sn-font-weight mb-0" style={{ fontWeight: "bold" }}> {item.sn}</h6></td>
                          <td className='text-center p-1 column-width ' style={{ border: "1px solid #cccccc" }}>
                            <div className="checkbox me-0 align-self-center">
                              <div className="custom-control custom-checkbox ">
                                <input type="checkbox"
                                  className="form-check-input"
                                  id={`stud-${item.sn}`}
                                  checked={item.inputchecked}
                                  onChange={() => handleChecked(item.sn)}
                                />
                                <label className="custom-control-label" htmlFor={`stud-${item.sn}`}></label>
                              </div>
                            </div>
                          </td>
                          <td className='text-start p-1 active-col-width ' style={{ border: "1px solid #cccccc" }}>
                            {item.status == 'ACTIVE' && <h6 className="text-success mb-0 ">Active </h6>}
                            {item.status == 'Disactive' && <h6 className="text-danger mb-0 ">Inactive </h6>}
                          </td>
                          {/*  <td className='p-1' style={{ border: "1px solid #cccccc", width: "50px" }}>
                            <div className="">
                              <img src={BACKEND_SERVER_URL + '/getStudentImage/' + item?.photo} alt="" className="avatar avatar-sm m-auto" />
                            </div>
                          </td> */}
                          <td className='p-1' style={{ border: "1px solid #cccccc" }}>
                            <div className="trans-list sweetalert">
                              <h4 className='mb-0 '>{item.firstName}{' '}{item.lastName}
                                <CiEdit cursor='pointer' title='Edit Student' size={18} className='ms-3' onClick={() => childRef.current.openModal(item)} />
                                <MdDeleteOutline color='red' title='Delete Student' cursor='pointer' size={18} className='ms-2' onClick={() => { handleDeleteStudent(item.sn) }}
                                />
                              </h4>
                            </div>
                          </td>


                          <td className='p-1 text-start ' style={{ border: "1px solid #cccccc" }}><h6 className="mb-0">{item.contactNumber}</h6></td>
                          <td className='p-1 text-start text-primary' style={{ border: "1px solid #cccccc" }}><h6 className="mb-0">{item.email}</h6></td>
                          <td className='p-1 text-start text-primary' style={{ border: "1px solid #cccccc" }}><h6 className="mb-0">{item.examType}</h6></td>
                          {/*    <td className='p-1 ps-3 pe-3 text-start' style={{ border: "1px solid #cccccc" }}><h6 className="mb-0" >{item.password}</h6></td> */}

                          <td className='text-center p-1' style={{ border: "1px solid #cccccc", width: "85px" }} >
                            <h6 className="date mb-0">{format(item.regdDate, "dd/MM/yyyy")}</h6>
                          </td>
                          <td className='text-center p-1' style={{ border: "1px solid #cccccc", width: "85px" }} >
                            <h6 className="date mb-0">
                              <Badge onClick={() => resetLoginDevice(item.email, item.password, item.sn)} bg="" className='badge-secondary  badge-sm ms-2 me-2 cursor-pointer pe-3 ps-3' >Reset</Badge>
                            </h6>
                          </td>
                        </tr>
                      )) :
                        <tr>
                          <td colSpan="9" className="text-center">
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                              {
                                showTableResponse(students, checked)
                              }
                            </div>
                          </td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
      <StudentModal ref={childRef} loadStudents={loadStudents} setStudentLoading={setStudentLoading} />
    </>
  )
}

export default StudentManagement;
