import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

import Select from "react-select";
import { BACKEND_SERVER_URL } from "../../../http/http-config";
import { getExamTypes } from "../Course/CourseHelper";
/* const batchData = [
    { value: "ocean", label: "Ocean", color: "#00B8D9", isFixed: true },
    { value: "blue", label: "Blue", color: "#0052CC", isDisabled: true },
    { value: "purple", label: "Purple", color: "#5243AA" },
    { value: "red", label: "Red", color: "#FF5630", isFixed: true },
    { value: "orange", label: "Orange", color: "#FF8B00" },
    { value: "yellow", label: "Yellow", color: "#FFC400" },
    { value: "green", label: "Green", color: "#36B37E" },
    { value: "forest", label: "Forest", color: "#00875A" },
    { value: "slate", label: "Slate", color: "#253858" },
    { value: "silver", label: "Silver", color: "#666666" },
]; */

const CustomClearText = () => "clear all";
const ClearIndicator = (props) => {
    const {
        children = <CustomClearText />,
        getStyles,
        innerProps: { ref, ...restInnerProps },
    } = props;
    return (
        <div
            {...restInnerProps}
            ref={ref}
            style={getStyles("clearIndicator", props)}
        >
            <div style={{ padding: "0px 5px" }}>{children}</div>
        </div>
    );
};

const ClearIndicatorStyles = (base, state) => ({
    ...base,
    cursor: "pointer",
    color: state.isFocused ? "blue" : "black",
});

export default function CustomClearIndicator({ setExamBodiesToBeReturn, previouslyMappedExamIds }) {
    const [examBodies, setExamBodies] = useState();
    const [selectedBatches, setSelectedBatches] = useState([]);
    const [defaultValue, setDefaultValue] = useState();
    //console.log('previouslyMappedExamIds', previouslyMappedExamIds)


    const batchesToShow = examBodies?.map((exam) => ({
        id: exam.Sn,
        value: exam.exambody,
        label: exam.exambody,

    }));
    console.log(batchesToShow)


    useEffect(() => {
        setExamBodiesToBeReturn(selectedBatches);
    }, [selectedBatches])

    useEffect(() => {
        getExamTypes().then((res) => {
            console.log(res)
            setExamBodies(res.filter((exambody) => exambody.status == 1 && exambody.hide == 0));
            if (previouslyMappedExamIds != null) {
                let previouslyMappedExams = res?.filter((exam) => previouslyMappedExamIds.includes(exam.Sn))
                console.log(previouslyMappedExams)
                previouslyMappedExams = previouslyMappedExams?.map((exam) => ({
                    id: exam.Sn,
                    value: exam.exambody,
                    label: exam.exambody
                }));
                setDefaultValue(previouslyMappedExams);
                setSelectedBatches(previouslyMappedExams);
            } else {
                setDefaultValue([]);
            }
        })
    }, [])
    const handleChange = (selectedOptions) => {
        setSelectedBatches(selectedOptions);
    };

    return (
        <>
            {batchesToShow && (
                <>
                    <Select
                        closeMenuOnSelect={false}
                        components={{ ClearIndicator }}
                        styles={{ clearIndicator: ClearIndicatorStyles }}
                        defaultValue={defaultValue}
                        isMulti
                        //value={selectedBatches}
                        options={batchesToShow}
                        onChange={handleChange}
                        isSearchable={true}
                    />

                </>
            )}
        </>
    );

}
