import axios from "axios"
import { BACKEND_SERVER_URL } from "../../../../http/http-config"


export async function getTopic(topicId) {
    return (await axios.get(BACKEND_SERVER_URL + `/getTopic/${topicId}`)).data.topic
}
export async function getTopicQuestions(testId) {
    return (await axios.get(BACKEND_SERVER_URL + '/getTopicQuestions/' + testId)).data.questions
}

export function validateTopicData(formData) {
    const requiredFields = ['title', 'courseId', 'subjectId'];

    for (const field of requiredFields) {
        if (!formData[field]) {
            return `${field.substring(0, 1).toUpperCase() + field.substring(1)} is Required`;
        }
    }

    return null; // Return null if all validations pass
}
