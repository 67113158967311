import React from 'react';

const StaffDetails = () => {
  return (
    <div>
      <h1>Work in progress</h1>
    </div>
  );
}

export default StaffDetails;
