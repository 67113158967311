import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Badge, Button, Modal, Spinner } from 'react-bootstrap';
import QueryReplyModal from './QueryReplyModal';
import { MdDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import parse from 'html-react-parser';
import swal from 'sweetalert';
import axios from 'axios';
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import { Pagination } from '@mui/material';
import { bulkOperations, deleteItem, handleChecked, handleCheckedAll, handleSearch, showTableResponse } from '../common-helpers/commonServices';
import ProdutsDetailsTabsAtTop from '../Common-Components/ProdutsDetailsTabsAtTop';
import SearchBox from '../Common-Components/SearchBox';
import { toast } from 'react-toastify';
import './websiteQuery.css'
import { TiTickOutline } from 'react-icons/ti';
import { FaWhatsappSquare } from 'react-icons/fa';
import { CgMail } from "react-icons/cg";

const WebsiteQuery = () => {
  const childRef = useRef();
  const [queries, setQueries] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [checked, setChecked] = useState(queries);
  const [unchecked, setUnChecked] = useState(true);
  const [modalBox, setModalBox] = useState(false)
  const [loadQueries, setQueryLoading] = useState(false);



  useEffect(() => {
    axios.get(BACKEND_SERVER_URL + '/getWebsiteQueries').then((res) => {
      let websiteQueries = res.data.websiteQueries;  //recieving batch here
      console.log(websiteQueries)
      if (websiteQueries) {
        setQueries(websiteQueries);
        setChecked(websiteQueries);
      }
    }).catch((error) => {
      console.log(error)
      toast.error(error.response.data.message);
    })
  }, [loadQueries])

  const pageSize = 50;
  const lastIndex = currentPage * pageSize;
  const firstIndex = lastIndex - pageSize;
  const records = checked?.slice(firstIndex, lastIndex);
  const npage = Math.ceil(checked.length / pageSize)
  const number = [...Array(npage + 1).keys()].slice(1)



  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="row">
            <div className="col-xl-12">
              <div className=" page-title p-2 mb-2">{/*  flex-wrap  */}
                <div className="row w-100 d-flex justify-content-between">
                  <div className=" ms-3 col-sm-6 input-group search-area mb-md-0 mb-3">
                    <SearchBox setChecked={setChecked} dataArray={queries} searchingProperty={'name'} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12 wow fadeInUp" data-wow-delay="1.5s">
              <div className="d-sm-flex text-center justify-content-between align-items-center">
                <div className='dataTables_info text-dark'>
                  Records: {checked.length}
                </div>
                {checked.length > pageSize ? <div className="dataTables_paginate paging_simple_numbers justify-content-center  mb-0 pt-2"
                  id="example-student_wrapper">
                  <Pagination count={number.length} variant="outlined" color='primary' size="small" page={currentPage} siblingCount={1} boundaryCount={2} onChange={(e, p) => setCurrentPage(parseInt(p))} />
                </div> : null}
              </div>
              <div className="table-responsive full-data" style={{ paddingBottom: "1px", transform: 'rotateX(180deg)' }}>
                <div id="example-student_wrapper" className="dataTables_wrapper no-footer">
                  <table className="table-responsive-lg table display dataTablesCard student-tab 
                                    dataTable no-footer mt-0 mb-0" style={{ transform: 'rotateX(180deg)' }} id="example-student" >
                    <thead style={{ backgroundColor: "#cccccc" }}>
                      <tr className='text-center text-dark border border-left'>
                        <th className=' custom-table-header  p-2'>SN</th>
                        <th className=' text-start custom-table-header  p-2' >NAME</th>
                        <th className=' text-start custom-table-header  p-2' > QUERY DETAILS</th>
                        {/* <th className='custom-table-header  p-2' > OTHER REPLY OPTIONS</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {records.length > 0 ? records.map((item, ind) => (
                        <tr key={ind} className={`${ind % 2 == 1 ? 'custom row-height' : 'row-height'}`} >
                          <td className='text-center p-1 column-width' style={{ border: "1px solid #cccccc", width: "65px" }} ><h6 className="sn-font-weight mb-0"> {item.sn}</h6></td>
                          <td className='p-1' style={{ border: "1px solid #cccccc", minWidth: "200px", maxWidth: "250px" }}>
                            <div className="">
                              <h6 className='mb-0 ' style={{ fontSize: '13px', whiteSpace: 'pre-wrap', fontWeight: 'bold', whiteSpace: "pre-wrap" }}>{item.name}{' '}
                                <CiEdit cursor='pointer' title='Reply' size={18} className='ms-3'
                                  onClick={() => childRef.current.openModal(item)} />
                                <MdDeleteOutline color='red' title='Delete Query' cursor='pointer' size={18} className='ms-2' onClick={() => { deleteItem(item.sn, 'Query', '/deleteQuery/', setQueryLoading, loadQueries) }} />
                              </h6>
                              <p className='p-0 m-0'>
                                <span className='text-primary' style={{ fontSize: '13px', fontWeight: '510' }}>Enquiry Date : </span>
                                <span style={{ fontSize: '13px', whiteSpace: 'pre-wrap' }}>{new Date(item?.enquiryDate)?.toLocaleDateString('en-GB')}</span>
                              </p>
                              <p className='p-0 m-0 '>
                                <span className='text-primary' style={{ fontSize: '13px', fontWeight: '510' }}>Reply Date : </span>
                                <span style={{ fontSize: '13px', whiteSpace: 'pre-wrap' }}>{new Date(item?.responseDate)?.toLocaleDateString('en-GB')}</span>
                              </p>
                              {/* <p className='p-0 m-0 text-primary '>
                               (<span>{new Date(item?.enquiryDate)?.toLocaleDateString('en-GB')} , </span>
                                <span>{new Date(item?.responseDate)?.toLocaleDateString('en-GB')} </span>   )                           
                              </p> */}
                              {/* <h6 className='d-flex justify-content-end '>
                                <MdDeleteOutline color='red' title='Delete Query' cursor='pointer' size={18} className='ms-2' onClick={() => { deleteItem(item.sn, 'Query', '/deleteQuery/', setQueryLoading, loadQueries) }} />
                                <Badge bg="" className='badge-success light badge-sm ms-2 cursor-pointer pe-3 ps-3' onClick={() => childRef.current.openModal(item)} >Reply</Badge>
                              </h6> */}
                            </div>
                          </td>
                          <td className='p-1' style={{ border: "1px solid #cccccc", minWidth: '300px' }}>
                            <div>
                              <h6 className='mb-0'>
                                <span style={{ fontSize: '13px', fontWeight: 'bold' }}>SUBJECT : </span>
                                <span style={{ fontSize: '13px', fontWeight: 'bold', whiteSpace: 'pre-wrap' }}>{item?.subject?.toUpperCase()}</span>
                              </h6>
                              <p className='p-0 m-0 text-primary '>
                                <span style={{ fontSize: '13px', fontWeight: 'bold' }}>MESSAGE : </span>
                                <span style={{ whiteSpace: 'pre-wrap' }}>{item.message ? parse(item.message) : null}</span>
                              </p>
                              <p className='p-0 m-0 text-primary'>
                                <span style={{ fontSize: '13px', fontWeight: 'bold', color: "green" }}>RESPONSE : </span>
                                <span style={{ whiteSpace: 'pre-wrap' }}>{item?.response}</span>
                              </p>
                            </div>
                          </td>
                          {/* <td className='p-1' style={{ border: "1px solid #cccccc", maxWidth: '200px' }}>
                            <h6 className='mb-0 d-flex justify-content-evenly'>                              
                                <a href={`https://wa.me/91${item.mobileNo}?text=Hello%20from%20INSIGHT%20Delhi!`} target='_blank' rel="noopener noreferrer" >
                                <Badge bg="" className='badge-primary light badge-sm cursor-pointer' ><FaWhatsappSquare size={28} className='' color='#075E54' /></Badge>
                                </a>
                              <a href={`https://mail.google.com/mail/?view=cm&fs=1&to=${item.emailId}&su=Query%20Reply%20From%20INSIGHT%20DELHI`} target='_blank' rel="noopener noreferrer">
                                <Badge bg="" className='badge-primary light badge-sm  cursor-pointer' ><CgMail size={28} className='' color='rgb(193 80 44)' /></Badge>
                                </a>                              
                            </h6>
                          </td> */}
                          {/* <td className='text-center  p-1' style={{ border: "1px solid #cccccc", width: "50px" }}><h6 className="mb-0">{new Date(item?.enquiryDate)?.toLocaleDateString('en-GB')}</h6></td>
                          <td className='text-center  p-1' style={{ border: "1px solid #cccccc", width: "50px" }}><h6 className="mb-0">{new Date(item?.responseDate)?.toLocaleDateString('en-GB')}</h6></td> */}
                        </tr>
                      )) :
                        <tr>
                          <td colSpan="9" className="text-center">
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                              {
                                showTableResponse(queries, checked)
                              }
                            </div>
                          </td>
                        </tr>}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <QueryReplyModal ref={childRef} loadQueries={loadQueries} setQueryLoading={setQueryLoading} />
    </>
  )
}

export default WebsiteQuery;



