import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Button, Spinner, Badge } from 'react-bootstrap';
import TestDummyReportModal from './TestDummyReportModal';
import { MdDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import axios from 'axios';
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import { Pagination } from '@mui/material';

import { showTableResponse } from '../common-helpers/commonServices';
import SearchBox from '../Common-Components/SearchBox';

import { getCourses, getSubjects, getTestSeries, getTestSeriesTests, getTopics, setDropDownStyle } from '../CommonHelper';
import Select from 'react-select';
// import { setDropDownStyle } from '../CommonHelper';

import { toast } from 'react-toastify';



const TopicReports = () => {
    const childRef = useRef();
    const navigate = useNavigate();
    const [reports, setReports] = useState([]);
    const [subjectWisetopics, setSubjectWiseTopics] = useState([]);
    const [testSeries, setTestSeries] = useState([]);

    const [testSeriesWiseTests, setTestSeriesWiseTests] = useState([]);
    const [selectedTestSeries, setSelectedTestSeries] = useState({ title: "All Test Series" });
    const [selectedTest, setSelectedTest] = useState({ title: "All Tests" });



    const [currentPage, setCurrentPage] = useState(1);
    const [checked, setChecked] = useState(reports);
    const [unchecked, setUnChecked] = useState(true);
    const [loadReports, setReportsLoading] = useState(false);

    //set Table data
    useEffect(() => {
        axios.get(BACKEND_SERVER_URL + '/getTestReports').then((res) => {
            let reports = res.data.reports;  //recieving batch here    
            console.log('reports', reports)
            setReports(reports);
            setChecked(reports);
        }).catch((error) => {
            console.log(error)
            toast.error(error.response.data.message)
        })
        getTestSeries().then((res) => {
            console.log(res)
            res.unshift({ title: "All Test Series" })
            setTestSeries(res)
        })

    }, [loadReports])

    const pageSize = 20;
    const lastIndex = currentPage * pageSize;
    const firstIndex = lastIndex - pageSize;
    const records = checked?.slice(firstIndex, lastIndex);
    const npage = Math.ceil(checked.length / pageSize)
    const number = [...Array(npage + 1).keys()].slice(1)


    const selectCourseHandler = (testSeriesOBJ) => {
        console.log(testSeriesOBJ)
        if (testSeriesOBJ.courseId !== selectedTestSeries.courseId) {
            setSelectedTest({ title: "All Tests" })
        }

        if (testSeriesOBJ.title == 'All Test Series') {
            setSelectedTestSeries(testSeriesOBJ);
            setTestSeriesWiseTests([{ title: "All Tests" }]);
            setChecked(reports)
            return;
        }
        setSelectedTestSeries(testSeriesOBJ);

        getTestSeriesTests(testSeriesOBJ.courseId).then((res) => {
            res = res.filter((obj) => obj.type == 'MCQ')
            res.unshift({ title: "All Tests" })
            setTestSeriesWiseTests(res)
        })
        setChecked(reports?.filter(obj => obj.courseId == testSeriesOBJ.courseId))
    }

    const selectTestHandler = (selectedTest) => {
        if (selectedTest.title == 'All Tests') {
            setSelectedTest(selectedTest);
            return;
        }
        setSelectedTest(selectedTest);
        let selectedReports = reports?.filter(report => report.packageId == selectedTest.packageId)
        setChecked(selectedReports)

    }
    console.log(reports)



    console.log('subjectWisetopics', subjectWisetopics)

    return (
        <>
            <div className="row">
                <div className="col-xl-12">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className=" page-title p-2 mb-2">{/*  flex-wrap  */}
                                <div className=' col-8  d-flex justify-content-between'>
                                    <button type="button" className="btn btn-dark btn-sm me-2"
                                        onClick={() => childRef.current.openModal()}
                                    >
                                        + Add Report
                                    </button>
                                    <div className="col-4">
                                        <div className="basic-dropdown">
                                            <Select
                                                value={{ value: selectedTestSeries, label: selectedTestSeries.title }}
                                                onChange={(selectedOption) => selectCourseHandler(selectedOption.value)}
                                                options={testSeries?.map((testseries) => ({ value: testseries, label: testseries.title }))}
                                                styles={setDropDownStyle()}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="basic-dropdown ms-1">
                                            <Select
                                                isDisabled={!selectedTestSeries.courseId}
                                                value={{ value: selectedTest, label: selectedTest.title }}
                                                onChange={(selectedOption) => selectTestHandler(selectedOption.value)}
                                                options={testSeriesWiseTests?.map((test) => ({ value: test, label: test.title }))}
                                                styles={setDropDownStyle()}
                                            />
                                        </div>
                                    </div>

                                    {/*    <button type="button" className="btn btn-dark btn-xs ms-2 " onClick={handleShowAllCourses}> show All</button> */}
                                </div>
                                <div className="input-group search-area mb-md-0 mb-3">
                                    <SearchBox setChecked={setChecked} dataArray={reports} searchingProperty={'firstName'} />
                                </div>
                            </div>
                            {/* <ProdutsDetailsTabsAtTop setChecked={setChecked} totalItems={topics} totalActiveItems={totalActiveTopics} totalInactiveItems={totalInactiveTopics} totalArchivedItems={totalArchivedTopics} /> */}
                        </div>
                        <div className="col-xl-12 wow fadeInUp" data-wow-delay="1.5s">
                            <div className="d-sm-flex text-center justify-content-between align-items-center">
                                {/* <div>
                  <Button variant="danger ms-3" size='xs' onClick={(e) => bulkOperations("delete", records, '/hideTopics', 'Topics', setTopicsLoading, loadTopics)}>Delete</Button>
                  <Button variant="success ms-3 " size='xs' onClick={(e) => bulkOperations("activate", records, '/activateTopics', 'Topics', setTopicsLoading, loadTopics)}>Active</Button>
                  <Button variant="warning ms-3" size='xs' onClick={(e) => bulkOperations("InActivate", records, '/inActivateTopics', 'Topics', setTopicsLoading, loadTopics)}>Inactive</Button>
                </div> */}

                                <div className='dataTables_info text-dark'>
                                    Records: {checked.length}
                                </div>
                                {checked.length > pageSize ? <div className="dataTables_paginate paging_simple_numbers justify-content-center  mb-0 pt-2"
                                    id="example-student_wrapper">
                                    <Pagination count={number.length} variant="outlined" color='primary' size="small" page={currentPage} siblingCount={1} boundaryCount={2} onChange={(e, p) => setCurrentPage(parseInt(p))} />
                                </div> : null}
                            </div>
                            <div className="table-responsive full-data" style={{ paddingBottom: "1px", transform: 'rotateX(180deg)' }}>
                                <div id="example-student_wrapper" className="dataTables_wrapper no-footer">
                                    <table className="table-responsive-lg table display dataTablesCard student-tab 
                                    dataTable no-footer mt-0 mb-0" style={{ transform: 'rotateX(180deg)' }} id="example-student" >
                                        <thead style={{ backgroundColor: "#cccccc" }}>
                                            <tr className='text-center text-dark border border-left'>
                                                <th className=' custom-table-header  p-2'>SN</th>
                                                <th className='p-2 text-start custom-table-header '>STATUS</th>
                                                <th className=' text-start custom-table-header  p-2' >STUDENT NAME</th>
                                                <th className=' text-start custom-table-header  p-2' >TEST NAME</th>
                                                <th className='custom-table-header  p-2' >TOTAL Qs.</th>
                                                <th className='custom-table-header  p-2' >ATTEMPTED Qs..</th>
                                                <th className='custom-table-header  p-2' >NET SCORE</th>
                                                <th className='custom-table-header  p-2' >ATTEMPT COUNT</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {records.length > 0 ? records.map((item, ind) => (
                                                <tr key={ind} className={`${ind % 2 == 1 ? 'custom row-height' : 'row-height'}`} >
                                                    <td className='text-center p-1 column-width ' style={{ border: "1px solid #cccccc", width: "65px" }} ><h6 className="sn-font-weight mb-0" > {item.sn}</h6></td>
                                                    <td className='text-start p-1 active-col-width' style={{ border: "1px solid #cccccc", width: "80px" }}>
                                                        {item.status === 'Complete' ? <h6 className="text-success mb-0 "> Complete </h6> : <h6 className="text-danger mb-0"> Incomplete </h6>}

                                                    </td>
                                                    <td className='p-1' style={{ border: "1px solid #cccccc" }}>
                                                        <div className="d-flex justify-content-between">
                                                            {item.firstName ? (
                                                                <h6 className='mb-0' style={{ fontSize: '13px', fontWeight: 'bold' }}>{item.firstName}</h6>
                                                            ) : (
                                                                <h6 className='mb-0 text-secondary' style={{ fontSize: '13px', fontWeight: 'bold' }}>Dummy Report</h6>
                                                            )}
                                                        </div>

                                                    </td>
                                                    <td className='p-1' style={{ border: "1px solid #cccccc" }}>
                                                       
                                                        <h6 className='mb-0 ' style={{ fontSize: '13px', fontWeight: 'bold' }}>{item.testName}</h6>
                                                        

                                                    </td>
                                                    <td className='p-1 text-center' style={{ border: "1px solid #cccccc" }}>
                                                        <h6 className="mb-0 ">
                                                            {item.totalQuestions}
                                                        </h6>
                                                    </td>
                                                    <td className='p-1 text-center' style={{ border: "1px solid #cccccc" }}>
                                                        <h6 className="mb-0 ">
                                                            {item.attemptedQuestions}
                                                        </h6>
                                                    </td>
                                                    <td className='p-1 text-center' style={{ border: "1px solid #cccccc" }}>
                                                        <h6 className="mb-0 ">
                                                            {item?.netScore?.toFixed(2)}
                                                        </h6>
                                                    </td>
                                                    <td className='p-1 text-center' style={{ border: "1px solid #cccccc", width: "80px" }}>
                                                        <h6 className="mb-0 ">
                                                            {item.attemptCount}
                                                        </h6>
                                                    </td>

                                                </tr>
                                            )) :
                                                <tr>
                                                    <td colSpan="9" className="text-center">
                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                                                            {
                                                                showTableResponse(reports, checked)
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TestDummyReportModal ref={childRef} loadTopics={loadReports} setReportsLoading={setReportsLoading} />
        </>
    )
}

export default TopicReports;

