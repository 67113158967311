import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import { checkExistingItem } from '../common-helpers/commonServices';
import Input from '../Common-Components/Input';
import DropdownInput from '../Common-Components/DropdownInput';
import Select from 'react-select';
import { setDropDownStyle } from '../CommonHelper';
import FileInput from '../Common-Components/FileInput';


const TestimonialsModal = forwardRef((props, ref) => {
    const [modalBox, setModalBox] = useState(false);
    let initialData = {
        sn: 0,
        Name: '',
        Image: '',
        Message: '',
        Rank: 1,
        Position: '',
        Year: 'Select Year'

    }

    const [testimonialsData, setTestimonialsData] = useState(initialData);
    // console.clear()
    console.log(testimonialsData)

    //check for existing items       
    // const [itemAlreadyExist, setItemAlreadyExists] = useState(false);
    // const [currentTitle, setCurrentTitle] = useState('');//for updating 
    // console.log(itemAlreadyExist)
    // useEffect(() => {
    //     if (testimonialsData.Name && currentTitle.toLowerCase().trim() != testimonialsData.Name.toLowerCase().trim()) {
    //         let timer = setTimeout(async () => {
    //             let response = await checkExistingItem('in_faculty', 'faculty', testimonialsData.Name) //tablename and columnNane value
    //             setItemAlreadyExists(response)
    //         }, 500);
    //         return () => clearTimeout(timer);
    //     }
    // }, [testimonialsData.Name]);

    useImperativeHandle(ref, () => ({
        openModal(item = {}) {
            console.log(item)
            if (item.Sn > 0) {
                setTestimonialsData({ sn: item.Sn, Name: item.ToperName, Year: item.Year, Position: item.Position, Rank: item.Rank, Image: item.Image, Message: item.Message})
                // setCurrentTitle(item.facultyName)
            }
            setModalBox(true);
        }
    }));

    const handleSaveCourseType = () => {
        if (testimonialsData.Name.trim().length === 0) {
            toast.error("Please Enter Name");
            return;
        } if (isNaN(Number(testimonialsData.Year))) {
            toast.error("Please Select Any Year");
            return;
        } if (testimonialsData.Message.trim().length === 0) {
            toast.error("Please Enter Message");
            return;
        } if (testimonialsData.Position.trim().length === 0) {
            toast.error("Please Enter Position");
            return;
        } if (Number(testimonialsData.Rank < 1)) {
            toast.error("Please Enter Rank");
            return;
        } if (testimonialsData.Image.trim().length == 0) {
            toast.error("Please Upload Image File");
            return;
        }
        axios.post(BACKEND_SERVER_URL + `${!testimonialsData.sn ? '/saveTestimonials' : `/updateTestimonials`}`, testimonialsData).then((response) => {
            if (response.data.statusCode === 201) {
                toast.success(response.data.message)
                props.setAcademicYearLoading(!props.loadAcademicYear);
                setTestimonialsData(initialData)
                setModalBox(false);
            }
        }).catch(error => {
            console.log(error)
            toast.error(error.response.data.message)
        })
    }
    useEffect(() => {
        if (!modalBox) {
            setTestimonialsData(initialData); // Reset form data when modal is closed         
        }
    }, [modalBox]);


    const handleChange = (name, value) => {
        setTestimonialsData((testimonialsData) => (
            { ...testimonialsData, [name]: value }
        ));
    };


    return (
        <>
            <Modal onHide={setModalBox} show={modalBox} size="lg" backdrop="static"
                keyboard={false} >
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel"> {testimonialsData.sn ? "Update Testimonials" : "Add Testimonials"}</h5>
                        <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
                    </div>
                    <div className="modal-body ">
                        <div className="col-12 d-flex justify-content-around">
                            <div className="col-5 d-flex">
                                <Input isMandatory={true} handleChangeCallBack={handleChange} labelName="Name" inputType="text" inputValue={testimonialsData?.Name} inputName='Name' divSizes={[3, 9]} />
                            </div>
                            <div className="col-5 d-flex">
                                <Input isMandatory={true} handleChangeCallBack={handleChange} labelName="Position" inputType="text" inputValue={testimonialsData?.Position} inputName='Position' divSizes={[3, 9]} />
                            </div>
                        </div>
                        <div className="col-12 d-flex justify-content-around  ">
                            <div className="col-5 d-flex">
                                <div className="col-sm-3 pt-2">
                                    <div className="mb-3" >
                                        <label htmlFor='Year' className="form-label text-primary">Year<span className="required">*</span></label>
                                    </div>
                                </div>
                                <div className="col-sm-9">
                                    <div className="mb-3 " >
                                        <Select
                                            value={{ value: testimonialsData?.Year, label: testimonialsData?.Year }}
                                            onChange={(selectedOption) => setTestimonialsData((preData) => (

                                                {
                                                    ...preData
                                                    , Year: selectedOption.value
                                                }
                                            ))}
                                            options={['2024', '2023', '2022', '2021', '2020', '2019', '2018', '2017', '2016', '2015', '2014', '2013', '2012', '2011', '2010'].map((item) => ({ value: item, label: item }))}
                                            styles={setDropDownStyle()}
                                        />
                                    </div>

                                </div>
                            </div>
                            <div className="col-5 d-flex">
                                <Input isMandatory={true} handleChangeCallBack={handleChange} labelName="Rank" inputType="number" inputValue={testimonialsData?.Rank} inputName='Rank' divSizes={[3, 9]} />
                            </div>
                        </div>
                        <div className="col-11 d-flex align-items-center">
                            <label htmlFor='Message' className="form-label text-primary col-2">Message<span className="required">*</span></label>
                            <textarea id='Message' className='form-control ' value={testimonialsData.Message}
                                onChange={(e) => setTestimonialsData((preData) => (
                                    {
                                        ...preData
                                        , Message: e.target.value
                                    }
                                ))} />
                        </div>
                        <div className="col-11 d-flex mt-2 ">
                            <FileInput isMandatory={true} handleChangeCallBack={handleChange} labelName="Image" inputType="text" uploadedFileURL={testimonialsData?.Image} inputName='Image' accept="image/jpeg, image/png, image/gif" />
                        </div>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger btn-xs" onClick={() => setModalBox(false)}>Close</button>
                        <button type="button" className="btn btn-primary btn-xs " onClick={handleSaveCourseType}>{testimonialsData.sn ? "Update" : "Add "}</button>
                    </div>
                </div>
            </Modal>

        </>
    )
})
export default TestimonialsModal;