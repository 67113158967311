import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BACKEND_SERVER_URL } from '../../../../http/http-config';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Alert } from 'react-bootstrap';
import { Typography } from '@mui/material';
import { format } from 'date-fns';
import defaultDP from '../../../new images/defaultstudntIcon.png'

const PendingMains = () => {
    //mains states
    const navigate = useNavigate()
    const [mainsStudentsResponse, setMainsStudentsResponse] = useState([]);
    const [hover, setHover] = useState(null);

    useEffect(() => {
        axios.get(BACKEND_SERVER_URL + '/getPendingMainsAnswers').then((res) => {
            console.log(res)
            setMainsStudentsResponse(res.data.answers)
        }).catch(error => {
            console.log(error)
            toast.error(error.response.data.meassage)
        })

    }, [])




    return (
        <div>
            <div className="col-xl-12">
                {/* <div className=""
                    style={{
                        backgroundColor: "lightgrey",
                        marginBottom: "0.5rem",

                    }}
                >
                    <h3 className='text-center p-2' style={{ letterSpacing: "2px" }}>PENDING MAINS</h3>
                </div> */}

                {mainsStudentsResponse?.map((item, index) => (
                    <div className="card h-auto mb-2">
                        <div className="card-body p-1 pb-1">
                            <Alert variant="white" className="solid alert-square mb-0">
                                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }} className='text-primary'>
                                    <div style={{ flex: 0.5 }}>
                                        <Typography style={{ color: '#303972', fontWeight: "bold" }}><img src={item?.profileImage || defaultDP} alt="" className="avatar avatar-md m-auto" /></Typography>
                                    </div>
                                    <div style={{ flex: 2.0 }}>
                                        <Typography style={{ color: '#303972', fontWeight: "bold" }}>{item.lastName ? `${item.firstName} ${item.lastName}` : item.firstName} <span className='text-primary ms-2' style={{ color: '#303972', fontWeight: "250" }}></span></Typography>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <Typography style={{ color: '#303972', fontWeight: "bold" }}>{format(new Date(item.uploadDate), "MMMM d, yyyy")}</Typography>
                                    </div>
                                    <div style={{ flex: 4 }}>
                                        <Typography
                                            onMouseEnter={() => setHover(index)}
                                            onMouseLeave={() => setHover(null)}
                                            className='hover-test-title'
                                            style={{ color: hover == index ? '#CA762B' : '#303972', fontWeight: "bold", cursor: "pointer" }}
                                            onClick={() => navigate('/moderate-solution', { state: { packageId: item.packageId, courseId: item.courseId, gotoPendingMains: true } })}
                                        >{item.testTitle}</Typography>
                                    </div>

                                </div>
                            </Alert>
                        </div>
                    </div>))
                }
            </div>

        </div>
    );
}

export default PendingMains;
